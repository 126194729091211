import React, { useState } from "react";
import { Box, Grid, IconButton, SvgIcon, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom"
import WebURL from "../../urls";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import jwtDecode from "jwt-decode";
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as MenuOrgan } from '@/assets/icons/MenuOrgan.svg';
import { ReactComponent as MenuAtt } from '@/assets/icons/MenuAtt.svg';
import { ReactComponent as MenuDashboard } from '@/assets/icons/MenuDashboard.svg';
import { ReactComponent as MenuDevice } from '@/assets/icons/MenuDevice.svg';
import { ReactComponent as MenuSetting } from '@/assets/icons/MenuSetting.svg';
import { ReactComponent as MenuOpen } from '@/assets/icons/MenuOpen.svg';
import { ReactComponent as MenuClose } from '@/assets/icons/MenuClose.svg';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';
import { usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#fff',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: "5px 0",
  },
  minHeight: "40px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: "0px 0px 0px 16px",
}));

type MenuProps = {
  key: string;
  title: string;
  path: string;
  childrens?: any;
  icon?: any;
}

type TokenProps = {
  roleName: string;
}


const SideBar: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.app.user);
  const company = useSelector((state: any) => state.app.company);
  const location = useLocation();
  const navigate = useNavigate();
  const [hideMenu, setHideMenu] = React.useState(false);

  let role = ''
  const jwtToken = localStorage.getItem("accessToken");
  if (jwtToken) {
    const decodedToken = jwtDecode<TokenProps>(jwtToken);
    role = decodedToken.roleName
  }

  const menus: MenuProps[] = [
    { key: 'dashboard', title: t("common.Dashboard"), path: WebURL.DASHBOARD, icon: MenuDashboard },
    { key: 'customerManagement', title: t("common.Customer Management"), path: '', icon: MenuOrgan, childrens:[
        {key: 'customerList', title: t("common.Customer List"), path: WebURL.CUSTOMER_LIST},
      ]
    },
    // {
    //   key: 'organizationManagenment', title: t("common.Organization Management"), path: '', icon: MenuOrgan, childrens: [
    //     { key: 'organProfile', title: t("common.Organization Profile"), path: WebURL.AUTH_USER_COMPANY_VIEW },
    //     { key: 'person', title: t("common.Person"), path: WebURL.HR_EMPLOYEE },
    //     { key: 'department', title: t("common.Department"), path: WebURL.HR_DEPARTMENT },
    //     { key: 'site', title: t("common.Site"), path: "/location/site" },
    //     { key: 'zone', title: t("common.Zone"), path: "/location/zone" },
    //     { key: 'resignation', title: t("hr.resignation.Resignation"), path: WebURL.HR_RESIGNATION },
    //   ]
    // },
    {
      key: 'device', title: t("common.Device Management"), path: '', icon: MenuDevice, childrens: [
        { key: 'device', title: t("common.Device Management"), path: "/dms/device" },
      ]
    },
    {
      key: 'setting', title: t("common.Setting"), path: '', icon: MenuSetting, childrens: [
        { key: 'credentialsManagement', title: t("common.Credentials Management"), path: "/settings/credentials_management" },
      ]
    },
  ]

  type SmallMenuItemProps = {
    menuItemProps: MenuProps;
  }

  const SmallMenuItem: React.FC<SmallMenuItemProps> = (props) => {
    const menuItem = props.menuItemProps
    let selectChild = false;
    if (menuItem.childrens) {
      menuItem.childrens.map((item: any, index: any) => {
        if (location.pathname.startsWith(item.path)) {
          selectChild = true
        }
      })
    } else {
      if (location.pathname.startsWith(menuItem.path)) {
        selectChild = true
      }
    }

    const popupState = usePopupState({ variant: 'popover', popupId: 'sideMenu' })
    return (
      <>
        <Box
          sx={{ padding: "5px 15px" }}
          {...bindHover(popupState)}
        >
          <SvgIcon
              sx={{ fontSize: "1.6rem", fill: selectChild ? "#059ad9" : "#304669", padding: "5px" }}
              component={menuItem.icon}
              inheritViewBox />
        </Box>
        <HoverMenu
          {...bindMenu(popupState)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {
            menuItem.childrens ?
              menuItem.childrens.map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{
                      margin: "0px 5px",
                      borderRadius: '5px',
                      background: location.pathname.startsWith(item.path) ? "#e5f2fa" : "#fff",
                      color: location.pathname.startsWith(item.path) ? "#059ad9" : "#304669",
                    }}
                    key={index}
                    onClick={() => { navigate(item.path) }
                    }>
                    {item.title}
                  </MenuItem>
                )
              })
              :
              <MenuItem
                sx={{
                  margin: "0px 5px",
                  borderRadius: '5px',
                  background: location.pathname.startsWith(menuItem.path) ? "#e5f2fa" : "#fff",
                  color: location.pathname.startsWith(menuItem.path) ? "#059ad9" : "#304669",
                }}
                onClick={() => { navigate(menuItem.path) }
                }>
                {menuItem.title}
              </MenuItem>
          }
        </HoverMenu>
      </>
    )
  };

  return (
    <Box style={{
      margin: '5px',
      height: 'calc(100% - 10px)',
      display: 'flex',
      borderRadius: '4px',
      background: '#fff',
    }}>
      <Grid style={{
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        minWidth: hideMenu ? "70px" : "290px",
        maxWidth: hideMenu ? "70px" : "290px",
      }}>
        {
          hideMenu ?
            <>
              <Box sx={{ marginTop: "70px", "padding": "10px" }}>
                <AvatarIcon name={user?.firstName ? user.firstName : "None"} diameter={50} profilePhoto={user?.profilePhoto ? user.profilePhoto : user.imagePreSignedURL} />
              </Box>
              <Box sx={{ marginTop: "70px" }}>
                {
                  menus.map((item: any, index) => {
                    return <SmallMenuItem key={index} menuItemProps={item} ></SmallMenuItem>
                  })
                }
              </Box>
            </>
            :
            <>
              <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '200px' }}>
                <Box sx={{ "paddingTop": "30px", "paddingBottom": "10px" }}>
                  <AvatarIcon name={user?.firstName ? user.firstName : "None"} diameter={70} profilePhoto={user?.imagePreSignedURL ? user.imagePreSignedURL : "None"} />
                </Box>
              </Grid >
              <Box sx={{
                paddingLeft: '18px',
              }}>
                <Typography variant={"grey"} sx={{ display: "flex", height: '30px' }} >
                  <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>
                  {t("common.Name")}: {user?.firstName ? user.firstName : "None"}
                  </Typography>
                </Typography>
                <Typography variant={"grey"} sx={{ display: "flex", height: '30px' }} >
                  <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>
                  {t("common.Email")}: {user?.email ? user.email : "None"}
                  </Typography>
                </Typography>
                <Typography variant={"grey"} sx={{ display: "flex", height: '30px' }} >
                  <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>
                  {t("common.Organization")}: {company?.name ? company.name : "None"}
                  </Typography>
                </Typography>
                <Typography variant={"grey"} sx={{ display: "flex", height: '30px' }} >
                  {t("common.User Role")}: {role}
                </Typography>
              </Box>
              <Box>
                {
                  menus.map((item, index) => {
                    if (item.childrens) {
                      return (
                        <Accordion key={index} sx={{ border: '0px', }} >
                          <AccordionSummary>
                            <SvgIcon sx={{ fontSize: "1.2rem", fill: "#304669", marginRight: "8px" }} component={item.icon} inheritViewBox />
                            <Typography>{item.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {
                              item.childrens.map((item: any, index: any) => {
                                return (
                                  <Typography
                                    key={index}
                                    sx={{
                                      padding: "8px 0px 8px 20px",
                                      marginRight: "10px",
                                      cursor: 'pointer',
                                      background: location.pathname.startsWith(item.path) ? "#e5f2fa" : "#fff",
                                      borderRadius: '5px',
                                    }}
                                    onClick={() => navigate(item.path)}
                                    color={location.pathname.startsWith(item.path) ? "#059ad9" : "#aeafb0"}
                                  >
                                    {item.title}
                                  </Typography>
                                )
                              })
                            }
                          </AccordionDetails>
                        </Accordion>
                      )
                    } else {
                      return (
                        <Box key={index} sx={{ display: 'inline-flex', padding: "8px 16px", cursor: 'pointer' }}>
                          <SvgIcon
                            sx={{
                              fontSize: "1.2rem", fill: location.pathname.startsWith(item.path) ? "#059ad9" : "#304669",
                              marginRight: "8px"
                            }}
                            component={item.icon}
                            inheritViewBox
                          />
                          <Typography
                            color={location.pathname.startsWith(item.path) ? "#059ad9" : "inherit"}
                            onClick={() => navigate(item.path)}
                            key={index}>{item.title}</Typography>
                        </Box>

                      )
                    }

                  })
                }
              </Box>
            </>
        }

      </Grid>

      <IconButton
        style={{
          borderRadius: '8px',
          padding: "0px",
          height: '50px',
          top: '50%',
          left: hideMenu ? '75px' : "295px",
          position: 'absolute'
        }}
        onClick={() => setHideMenu(!hideMenu)}
        disableRipple>
        {
          hideMenu
            ? <MenuOpen style={{ fill: '#0084d0' }} />
            : <MenuClose style={{ fill: '#0084d0' }} />
        }
      </IconButton>

    </Box>
  )
};
export default SideBar
