import React from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import CustomTooltip from "./CustomToolTip"
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';


export type BreadcrumbsProps = {
  record: Record<string, any>;
  helpInfo?: any;
}

export const CustomBreadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Breadcrumbs sx={{ height: "32px", padding: '8px 0px' }}>
        {props.record.map((value: any, index: number) => {
          const last = index === props.record.length - 1;
          return last ? (
            <Box key={index}>
              <Typography sx={{ display: 'inline-block' }} color="text.primary" key={value.label}>
                {value.label}
              </Typography>
              <Box sx={{ display: 'inline-block' }}>
                {value.helpInfo && <CustomTooltip helpInfo={value?.helpInfo} anchor={'right'} />}
              </Box>
            </Box>
          ) : (
            <Link key={index} underline="hover" color="inherit" to={value.path} component={RouterLink as any} onClick={value.onClick}>
              <Typography id="primaryTitle" color="text.primary">
                {value.label}
              </Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  )
}

export default CustomBreadcrumbs
