import React from "react";
import {IconButton, ButtonProps, Tooltip} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type CustomRowButtonProps = {
  insideIcon?: SvgIconComponent;
  buttonProps?: ButtonProps
}

function titleUpper(str: any) {  
  if (typeof(str) === 'undefined') return ''
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L: any) => L.toUpperCase());  
}  

const CustomRowButton: React.FC<CustomRowButtonProps> = (props) => {
  const {t} = useTranslation();
  const translatedTitle=props.buttonProps?.key === undefined ? "" : t("common."+props.buttonProps?.key)
  return (
    <Tooltip title={translatedTitle}>
      <IconButton
        {...props.buttonProps}
      >
        {props.insideIcon && <props.insideIcon color={"action"} />}
      </IconButton>
    </Tooltip>
  )
}
export default CustomRowButton