import ZoneService from "@/services/location/ZoneService"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


const initialState = {
  zones: [],
}

export const getOrganZones = createAsyncThunk('zone/byOrgan', async () => {
  const {data} = await ZoneService.list({current: 1, pageSize: 50});
  return data;
})

const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganZones.fulfilled, (state, action) => {
      return {
        zones: action.payload.data
      }
    })
  }
});

export default zoneSlice.reducer
