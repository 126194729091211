import React, {useState} from "react";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import DeviceConnGuide from "@/pages/dms/Device/components/DeviceAdd/DeviceConnGuide";
import DeviceRegister from "@/pages/dms/Device/components/DeviceAdd/DeviceRegister";
import {useTranslation} from "react-i18next";
import WebURL from "@/urls";


const DeviceAdd: React.FC = () => {
  const {t} = useTranslation()
  const [step, setStep] = useState<string>('guide')
  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.DMS_DEVICE, label: t("dms.Device") },
        { label: t("dms.Add Device") },
      ]} />
      {step === 'guide' ?
        <DeviceConnGuide setStep={setStep}/>
        :
        <DeviceRegister setStep={setStep}/>
      }
    </>
  )
}

export default DeviceAdd;
