import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import { KeyboardArrowDown } from "@mui/icons-material";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {useNavigate} from 'react-router-dom';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import { useTranslation } from "react-i18next";


type MenuItemProps = {
  hidePopup?: () => void;
}

const CreateCompanyItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const createOrganization = () => {
    navigate("/user/organ-add");
  }
  return (
    <MenuItem onClick={async () => {
      await props.hidePopup?.()
      setOpen(true)
      createOrganization()
    }}>
      <ListItemText>{t("common.Create organization")}</ListItemText>
    </MenuItem>
  )
}

const SwitchCompanyItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const SwitchOrganization = () => {
    navigate("/user/organ-switch");
  }
  return (
    <MenuItem onClick={async () => {
      await props.hidePopup?.()
      setOpen(true)
      SwitchOrganization()
    }}>
      <ListItemText>{t("common.Switch organization")}</ListItemText>
    </MenuItem>
  )
}


const CompanyProfile: React.FC = () => {
  const { t } = useTranslation();
  const company = useSelector((state: any) => state.app.company);
  const popupState = usePopupState({variant: "popover", popupId: "companyProfile"});
  return (
    <>
      <Tooltip title={t("common.Open Setting")}>
        <MenuItem style={{display: "flex", alignItems: "center", padding: "0 16px"}} {...bindTrigger(popupState)}>
          <DensityMediumIcon
            fontSize='small' sx={{color: "#304669"}}
          />
        </MenuItem>
      </Tooltip>
      <Menu
        sx={{mt: '45px'}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted={true}
        {...bindMenu(popupState)}
      >
        <CreateCompanyItem hidePopup={async () => popupState.close()} />
        <SwitchCompanyItem hidePopup={async () => popupState.close()} />
      </Menu>
    </>
  )
}
export default CompanyProfile
