import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UseFormReturn } from "react-hook-form";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import { useEffect } from "react";
import { getOrganDepts } from "@/store/slices/Department";
import { useDispatch } from "react-redux";


interface FormProps {
  code: string,
  firstName: string,
  lastName: string,
  departmentIdOrCode?: string,
  dept: {
    id: string,
    name: string
  }
}

interface EmployeeFormProps {
  employeeForm: UseFormReturn<FormProps>,
  isEditAction?: boolean,
  isViewAction?: boolean,
}

const EmployeeForm = (props: EmployeeFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const depts: {id: string, name: string}[] = useSelector((state: any) => state.dept.depts);

  useEffect(() => {
    dispatch<any>(getOrganDepts());
  }, [])

  return (
    <>
      <Paper sx={{margin: '20px 0', padding: '20px'}}>
        <Grid container>
          <Grid lg={6} item sx={{ paddingRight: '10px'}}>
            <ControlledTextFiled
              label={t("hr.employee.Person ID")}
              name="code"
              fullWidth
              required
              disabled={props.isEditAction||props?.isViewAction}
              hookForm={props.employeeForm}
              placeholder={t("hr.employee.Person ID")}
            />
          </Grid>
          <Grid lg={6} item>
            <ControlAutoComplete
              label= {t("common.Department")}
              name={"dept"}
              required
              disabled={props?.isViewAction}
              form={props.employeeForm}
              options={depts}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.id === value.id;
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} sx={{ paddingRight: '10px'}}>
            <ControlledTextFiled
              hookForm={props.employeeForm}
              label={t("common.First Name")}
              name="firstName"
              fullWidth
              required
              disabled={props?.isViewAction}
              placeholder={t("common.First Name")}
            />
          </Grid>
          <Grid lg={6} item>
            <ControlledTextFiled
              hookForm={props.employeeForm}
              label={t("common.Last Name")}
              name="lastName"
              fullWidth
              required
              disabled={props?.isViewAction}
              placeholder={t("common.Last Name")}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default EmployeeForm