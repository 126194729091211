import {useTranslation} from "react-i18next";

const timeZoneData = [
  {
    value: '-1200',
    name: '(GMT-12:00) International Date Line West',
  },
  {
    value: '-1100',
    name: '(UTC-11)Coordinated Universal Time-11',
  },
  {
    value: '-1000',
    name: '(UTC-10)Hawaii',
  },
  {
    value: '-0900',
    name: '(UTC-9)Alaska',
  },
  {
    value: '-0800',
    name: '(UTC-8)Pacific time (America and Canada) Baja California',
  },
  {
    value: '-0700',
    name: '(UTC-7)La Paz, The mountain time (America and Canada), Arizona',
  },
  {
    value: '-0600',
    name: '(UTC-6)Saskatchewan, Central time, Central America',
  },
  {
    value: '-0500',
    name: '(UTC-5)Bogota, Lima, Quito, Rio Branco, Eastern time, Indiana(East)',
  },
  {
    value: '-0430',
    name: '(UTC-4:30)Caracas',
  },
  {
    value: '-0400',
    name: '(UTC-4)Atlantic time, Cuiaba, Georgetown, La Paz, Santiago',
  },
  {
    value: '-0330',
    name: '(UTC-3:30)Newfoundland',
  },
  {
    value: '-0300',
    name: '(UTC-3)Brasilia, Buenos Aires, Greenland, Cayenne',
  },
  {
    value: '-0200',
    name: '(UTC-2)The International Date Line West-02',
  },
  {
    value: '-0100',
    name: '(UTC-1)Cape Verde Islands, Azores',
  },
  {
    value: '-0000',
    name: '(UTC)Dublin, Edinburgh, Lisbon, London, The International Date Line West',
  },
  {
    value: '%2B0100',
    name: '(UTC+1)Amsterdam, Brussels, Sarajevo',
  },
  {
    value: '%2B0200',
    name: '(UTC+2)Beirut, Damascus, Eastern Europe, Cairo,Athens, Jerusalem',
  },
  {
    value: '%2B0300',
    name: '(UTC+3)Baghdad, Kuwait, Moscow, St Petersburg,Nairobi',
  },
  {
    value: '%2B0330',
    name: '(UTC+3:30)Teheran or Tehran',
  },
  {
    value: '%2B0400',
    name: '(UTC+4)Abu Dhabi, Yerevan, Baku, Port Louis, Samarra',
  },
  {
    value: '%2B0430',
    name: '(UTC+4:30)Kabul',
  },
  {
    value: '%2B0500',
    name: '(UTC+5)Ashgabat, Islamabad, Karachi',
  },
  {
    value: '%2B0530',
    name: '(UTC+5:30)Chennai, Calcutta Mumbai, New Delhi',
  },
  {
    value: '%2B0545',
    name: '(UTC+5:45)Kathmandu',
  },
  {
    value: '%2B0600',
    name: '(UTC+6)Astana, Dhaka, Novosibirsk',
  },
  {
    value: '%2B0630',
    name: '(UTC+6:30)Yangon',
  },
  {
    value: '%2B0700',
    name: '(UTC+7)Bangkok, Hanoi, Jakarta',
  },
  {
    value: '%2B0800',
    name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi,Kuala Lumpur, Singapore',
  },
  {
    value: '%2B0900',
    name: '(UTC+9)Osaka, Tokyo, Seoul, Yakutsk',
  },
  {
    value: '%2B0930',
    name: '(UTC+9:30)Adelaide, Darwin',
  },
  {
    value: '%2B1000',
    name: '(UTC+10)Brisbane, Vladivostok, Guam, Canberra',
  },
  {
    value: '%2B1100',
    name: '(UTC+11)Solomon Islands, New Caledonia',
  },
  {
    value: '%2B1200',
    name: '(UTC+12)Anadyr, Oakland, Wellington, Fiji',
  },
  {
    value: '%2B1300',
    name: "(UTC+13)Nuku'alofa, The Samoa Islands",
  },
  {
    value: '%2B1400',
    name: '(UTC+14)Christmas Island',
  },
];

export const timeZoneList = timeZoneData;
export const timezones = () => {
  const {t} = useTranslation()
  return [
    {value: '-12:00', name: 'UTC-12:00'},
    {value: '-11:00', name: 'UTC-11:00'},
    {value: '-10:00', name: 'UTC-10:00'},
    {value: '-09:30', name: 'UTC-09:30'},
    {value: '-08:00', name: 'UTC-08:00'},
    {value: '-07:00', name: 'UTC-07:00'},
    {value: '-06:00', name: 'UTC-06:00'},
    {value: '-05:00', name: 'UTC-05:00'},
    {value: '-04:00', name: 'UTC-04:00'},
    {value: '-03:30', name: 'UTC-03:30'},
    {value: '-03:00', name: 'UTC-03:00'},
    {value: '-02:00', name: 'UTC-02:00'},
    {value: '-01:00', name: 'UTC-01:00'},
    {value: '+00:00', name: 'UTC+00:00'},
    {value: '+01:00', name: 'UTC+01:00'},
    {value: '+02:00', name: 'UTC+02:00'},
    {value: '+03:00', name: 'UTC+03:00'},
    {value: '+03:30', name: 'UTC+03:30'},
    {value: '+04:00', name: 'UTC+04:00'},
    {value: '+04:30', name: 'UTC+04:30'},
    {value: '+05:00', name: 'UTC+05:00'},
    {value: '+05:30', name: 'UTC+05:30'},
    {value: '+05:45', name: 'UTC+05:45'},
    {value: '+06:00', name: 'UTC+06:00'},
    {value: '+06:30', name: 'UTC+06:30'},
    {value: '+07:00', name: 'UTC+07:00'},
    {value: '+08:00', name: 'UTC+08:00'},
    {value: '+08:45', name: 'UTC+08:45'},
    {value: '+09:00', name: 'UTC+09:00'},
    {value: '+09:30', name: 'UTC+09:30'},
    {value: '+10:00', name: 'UTC+10:00'},
    {value: '+10:30', name: 'UTC+10:30'},
    {value: '+11:00', name: 'UTC+11:00'},
    {value: '+12:00', name: 'UTC+12:00'},
    {value: '+12:45', name: 'UTC+12:45'},
    {value: '+13:00', name: 'UTC+13:00'},
    {value: '+14:00', name: 'UTC+14:00'},
  ]
}


export const ngteco_timezones = () => {
  const {t} = useTranslation()
  return [
    {value: '-10:00', name: t("location.zone.Hawaii"), group: 'USA', code: 'us'},
    {value: '-09:00', name: t("location.zone.Alaska"), group: 'USA', code: 'us'},
    {value: '-08:00', name: t("location.zone.Pacific time"), group: 'USA', code: 'us'},
    {value: '-07:00', name: t("location.zone.La Paz"), group: 'USA', code: 'us'},
    {value: '-06:00', name: t("location.zone.Saskatchewan"), group: 'USA', code: 'us'},
    {value: '-05:00', name: t("location.zone.Bogota"), group: 'USA', code: 'us'},
    {value: '-04:00', name: t("location.zone.Puerto Rico"), group: 'USA', code: 'us'},
    {value: '+08:00', name: t("location.zone.Beijing"), group: 'China', code: 'cn'},
    // {value: '+08:00', name: 'Beijing, Chongqing, Hong Kong, Urumqi,Kuala Lumpur, Singapore', group: 'China', code: 'cn'},
    {value: '+09:00', name: t("location.zone.Japan"), group: 'Japan', code: 'jp'},
  ]
}
