import React, { useState, useRef } from "react";
import { GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { Box, Grid, Stack } from "@mui/material";
import CustomDataGrid, {DataGridRef} from "../../../components/data-grid/CustomDataGrid";
import { ATTEndpoints } from "../../../services/att/endpoints";
import { Add, DeleteForever, RemoveRedEyeOutlined } from "@mui/icons-material";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import { useNavigate } from "react-router-dom";
import WebURL from "../../../urls";
import Dialog from "../../../components/dialog";
import GenericService from "../../../services/GenericService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { DeviceListButtonDrawer } from "./components/drawers/DeviceListDrawer";
import SnackbarUtil from "@/utils/SnackbarUtil";


const ScheduleDataGrid: React.FC = () => {
  const { t } = useTranslation();
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const navigate = useNavigate();
  const dataGridRef = useRef<DataGridRef>()
  const columns: GridColDef[] = [
    { field: 'employee_name', headerName: t("common.Name"), flex: 1},
    { field: 'employee_code', headerName: t("hr.employee.Person ID"), flex: 0.8},
    { field: 'department_name', headerName: t("hr.department.Department"), flex: 0.8},
    { field: 'timesheet_name', headerName: t("att.schedule.Timesheet"), flex: 0.8},
    { field: 'assigned_start', headerName: t("att.schedule.Start Date"), type: "date" , flex: 0.8},
    { field: 'assigned_end', headerName: t("att.schedule.End Date"), type: "date" , flex: 0.8},
    {
      field: 'actions',
      headerName: t("common.action"),
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 1,
      renderCell: (cell) => {
        return (
          <Stack spacing={1} direction={"row"}>
            <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
              key: "view",
              onClick: () => {
                navigate(WebURL.ATT_SCHEDULE_VIEW,  {state: {id: cell.row.id}});
              }
            }} />
            <CustomRowButton insideIcon={DeleteForever} buttonProps={{
              key: "delete",
              onClick: async () => {
                Dialog.confirm({
                  title: t("common.Delete Confirm"),
                  onConfirm: () => {
                    GenericService.destroy(ATTEndpoints.ATT_SCHEDULE_URI, `${cell.id}`).then(
                      (result) => {
                        SnackbarUtil.success(t("common.success"))
                        dataGridRef.current?.refresh();
                      });
                  }
                });
              }
            }} />
          </Stack>
        )
      }
    },
  ]
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <CustomDataGrid
        ref={dataGridRef}
        uri={ATTEndpoints.ATT_SCHEDULE_URI}
        columns={columns}
        checkboxSelection={true}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        toolbar={{
          title: t("att.schedule.Shift schedule"),
          searchTips: t("common.Search by Person ID, Person Name, Timesheet Name"),
          search: true,
          breadcrumbs: [
            { breadcrumbName: t("att.schedule.Shift schedule"), path: WebURL.ATT_SCHEDULE }
          ],
          actions: [{
            key: 'add', icon: Add, helpTxt: t("common.add"), callback: async () => {
              navigate(WebURL.ATT_SCHEDULE_NEW);
            }
          }],
          extraComponentOnLeft:
            <DeviceListButtonDrawer IDs={selectionModel} />
        }}
      />
    </Box>
  )
}
export default ScheduleDataGrid
