import { createTheme } from "@mui/material";

import 'typeface-roboto';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    grey: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    grey?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    grey: true;
  }
}


const mainColor = "#0084d0";
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: mainColor,
      light: mainColor,
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    grey: {
      color: '#aeafb0',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '60px',
          background: '#f7f6fd',
          color: '#304669',
          boxShadow: '0px 0px 0px 0px'
        }
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          '&:hover': {
            backgroundColor: "white",
          },
          '&.Mui-focused': {
            backgroundColor: "white",
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 7,
          height: 36,
          "&.Mui-checked": {
            "& $thumb": {
              "backgroundColor": "#fff"
            },
            "& + $track": {
              "opacity": "1 !important"
            }
          },
        },
        thumb: {
          width: 20,
          height: 20,
          backgroundColor: '#fff',
          boxShadow: '0 0 12px 0 rgba(0,0,0,0.08), 0 0 8px 0 rgba(0,0,0,0.12), 0 0 4px 0 rgba(0,0,0,0.38)'
        },
        switchBase: {
          color: 'rgba(0,0,0,0.38)',
          paddingTop: 8,
          paddingBottom: 8
        },
        track: {
          borderRadius: 20,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: '4px',
          marginRight: '4px',
        }
      }
    }
  },
});

export default theme;
