import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Grid, Paper, Box, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { object, TypeOf, z } from "zod";
import { Controller, useForm } from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { SaveButton, SavingButton } from "@/components/data-grid/CustomButton";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import TimecardService from "@/services/att/services/TimecardService";
import SnackbarUtil from "@/utils/SnackbarUtil";

const AutoShare: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const today = new Date();
  today.setHours(0,0,0,0)
  const [enabled, setEnabled] = React.useState(false);
  const [unit, setUnit] = React.useState('3');
  const [isLoading, setLoading] = useState<boolean>(false);

  const exportSchema = object({
    actionDate:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid date format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),
    actionTime:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),    
    share_list: z.string().min(1, t("common.This field is required!"))
  })
  type ExportType = TypeOf<typeof exportSchema>;

  const shareSettingForm = useForm<ExportType>({
    resolver: zodResolver(exportSchema),
    defaultValues: {
      actionDate: today,
      actionTime: today,
    }
  });

  const scrollTo = (name: string) => {
    const anchorElement = document.getElementsByName(name)[0]
    if (anchorElement) {
        anchorElement.scrollIntoView({behavior: "smooth"})
    }
  }

  useEffect(() => {
    TimecardService.query_setting().then((res) => {
      if (Object.keys(res.data).length !== 0){
        setEnabled(true)
        setUnit(res.data.unit)
        shareSettingForm.setValue('actionDate', new Date(res.data.date))
        shareSettingForm.setValue('actionTime', new Date(res.data.date))
        shareSettingForm.setValue('share_list', res.data.share.join(";"))
        // setDate(new Date(res.data.date))
      }
    });
  }, []);

  const onSubmit = async (formData: any) => {
    const {share_list, actionDate, actionTime} = formData
    let isInvalidEmail = false;
    const share_email = share_list.trim()
                            .replace(/[\r\n\s]/g, "")
                            .split(';')
                            .filter((item: string)=>{
                              const email_pattern = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
                              if (item !== "" && email_pattern.test(item)) {
                                return true;
                              }
                              isInvalidEmail = true
                              return false;
                            });
    if (isInvalidEmail) {
      shareSettingForm.setError("share_list", { type: "custom", message: "invalid email format" }, { shouldFocus: true })
      return
    }
    const data = {
      "unit": unit,
      "date": actionDate.toLocaleDateString() + " " + actionTime.toLocaleTimeString(),
      "share": share_email
    }
    setLoading(true)
    TimecardService.export_setting(data).then((result) => {
      if (result.status < 400) {
        SnackbarUtil.success(t("common.success"))
        navigate(-1)
      } else {
        console.log("Failure===, ", result)
        SnackbarUtil.error(t("common.failure"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Grid>
      <Paper elevation={0} sx={{marginTop: '20px'}}>
        <Grid container alignItems="center" sx={{padding: '10px'}}>
          <Typography variant="h5">{t("export.Automatic send")}</Typography>
          <Switch checked={enabled} onChange={ (event)=>setEnabled(event.target.checked)} />
        </Grid>
        
        {enabled && 
          <Grid>
            <Grid sx={{ borderBottom: "0.5px solid #d2d5d3", width:"98%", margin:'auto' }}></Grid>
            <Grid sx={{ padding: "8px 10px 0 10px" }}>
              <Typography>{t("export.Send frequency")}</Typography>
              <Grid sx={{ border: '1px solid #AEAFB0', borderRadius: '4px', padding: '5px', width: "37vw"}}>
                <RadioGroup row value={unit} onChange={(event)=>setUnit(event.target.value)}>
                  <FormControlLabel value="0" control={<Radio />} label="Daily" />
                  <FormControlLabel value="1" control={<Radio />} label="Weekly" />
                  <FormControlLabel value="2" control={<Radio />} label="Biweekly" />
                  <FormControlLabel value="3" control={<Radio />} label="Monthly"/>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid sx={{padding: "8px 10px 0 10px"}}>
              <Typography>{t("export.First run at")}</Typography>
              <Grid container>
                <Grid xs={6} item>
                  <Controller
                    name="actionDate"
                    control={shareSettingForm.control}
                    render={({ field, fieldState }) =>
                      <DatePicker
                        value={field.value || null}
                        inputFormat={"dd-MM-yyyy"}
                        onChange={(newValue) => field.onChange(newValue)}
                        renderInput={(params) => 
                          <TextField
                            sx={{ width: '37vw' }}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ""}
                          />}
                      />
                    }
                  />                  
                </Grid>
                <Grid xs={6} item>
                  <Controller
                    name="actionTime"
                    control={shareSettingForm.control}
                    render={({ field, fieldState }) =>
                      <TimePicker
                        value={field.value || null}
                        onChange={(newValue) => field.onChange(newValue)}
                        renderInput={(params) =>
                          <TextField
                            sx={{ width: '37vw' }}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ""}
                          />}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{padding: "8px 10px 0 10px"}}>
              <ControlledTextFiled
                hookForm={shareSettingForm}
                label={t("export.Auto email list")}
                name="share_list"
                sx={{width: '37vw', marginTop: '24px'}}
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>  

            <Grid sx={{display: 'flex', justifyContent: 'end', margin: '0 40px 80px 0', padding: "10px"}}>
              <SavingButton
                disableElevation
                loading={isLoading}
                type="submit"
                onClick={shareSettingForm.handleSubmit(onSubmit)}
                children= {t("common.save")}
                />
            </Grid>
          </Grid>}
      </Paper>
    </Grid>
  );
}

export default AutoShare;

