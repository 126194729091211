import React from "react";
import {IconButton, styled} from "@mui/material";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

type ToolTipProps = {
  anchor?: 'top' | 'left' | 'bottom' | 'right';
  helpInfo?: any;
}

const IconButtonCustom = styled(IconButton)`
  &:hover {
    color: #0084d0;
  }
`;

const StyleedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "650px",
    backgroundColor:"white",
    border: "solid #0084d0 1px",
    color: "gray",
    fontSize: 16,
    lineHeight: 1.5,
    whiteSpace: "pre-wrap",
    maxHeight: "480px",
    "& .MuiTooltip-arrow": {
      "&::before": {
        border: "solid #0084d0 1px",
        backgroundColor:"white",
      },},
  },
});

const CustomTooltip: React.FC<ToolTipProps> = (props) => {
  return (
    <StyleedTooltip title={props?.helpInfo} placement={props?.anchor} arrow>
      <IconButtonCustom sx={{ marginBottom: "4px", padding: "2px", "&:hover": { backgroundColor: "transparent" }} }>
        <HelpOutlineOutlinedIcon sx={{fontSize: "18px"}} />
      </IconButtonCustom>
    </StyleedTooltip>
  )
}

export default CustomTooltip;
