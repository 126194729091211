import { Fragment, useRef, useState } from "react";
import {useTranslation} from "react-i18next";
import { Box, Grid, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Alert, DialogTitle, IconButton, Typography  } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoAction from "@/components/actions/Info";


const dialogStyle = {
  '& .MuiPaper-root': { width: '60vw', top: '-5vh' },
  '.MuiDialog-paper': { maxWidth: '100vw' },
};


const ExtraToolBar = () => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <InfoAction setInfo={setInfo} />
      </Grid>
      <InfoDialog info={info} setInfo={setInfo} />
    </Fragment>
  )
}

const InfoDialog = ({info, setInfo}: any) => {
  const {t} = useTranslation()
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={dialogStyle}
    >
      <DialogTitle>
        {t("common.Usage Tips")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ lineHeight: '25px', color: 'gray' }}>
        <Box sx={{ marginBottom: '15px' }}>
          <Typography>{t("hr.resignation.Resignation tip1")}</Typography>
        </Box>
        <Box sx={{ marginBottom: '15px' }}>
          <Typography>{t("hr.resignation.Resignation tip2")}</Typography>
        </Box>
        <Box sx={{ marginBottom: '15px' }}>
          <strong>{t("hr.resignation.Resignation tip3")}</strong>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ExtraToolBar;