import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import DeviceService from "../../../services/dms/DeviceService";
import { useTranslation } from "react-i18next";

type deviceQtyStatus = {
  first_title: string,
  title: string;
  category: string;
}

const DeviceQtyStatus: React.FC<deviceQtyStatus> = (props) => {
  const { t } = useTranslation();
  const cardStyle = {
    backgroundColor: "#0084d0",
    maxHeight: "190px"
  }
  const frontCardStyle = {
    backgroundColor: "#ffffff",
  }
  const [summary, setSummary] = React.useState(0);
  useEffect(() => {
    DeviceService.GetWhiteDeviceQty().then(res => {
      setSummary(res.data.data.totalCount);
    })
  }, [])
  return (
    <Card sx={frontCardStyle}>
      <CardHeader
        title={
          <Typography variant={"h5"} color={"#565454"}>
            {props.first_title}
          </Typography>
        }
        sx={{
          borderBottom: "2px solid #ffffff"
        }}
      />
      <CardContent>
          <Card sx={cardStyle} >
            <CardHeader
              title={
                <Typography variant={"h6"} color={"#ffffff"}>
                  {props.title}
                </Typography>
              }
              sx={{
                borderBottom: "1px solid #ffffff",
                width:"80%",
                marginLeft: "4%"
              }}
            />
            <CardContent>
                <Typography variant={"h1"} color={"#ffffff"} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  {summary}
                </Typography>
            </CardContent>
          </Card>
        </CardContent>
    </Card>
  )
}
export default DeviceQtyStatus
