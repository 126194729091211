import React, { useState } from "react";
import { Grid, Paper, Box, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {Controller, useForm} from "react-hook-form";
import { SaveButton } from "@/components/data-grid/CustomButton";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import { useTranslation } from "react-i18next";
import { object, TypeOf, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import TimecardService from "@/services/att/services/TimecardService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import moment from "moment";

const ManualShare: React.FC = () => {
  const { t } = useTranslation();
  const today = new Date();
  today.setHours(0,0,0,0)
  const exportSchema = object({
    share: z.string().min(1, t("common.This field is required!")),
    start_date:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid date format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),
    end_date:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),
  })
  type ExportType = TypeOf<typeof exportSchema>;

  const manualShareSettingForm = useForm<ExportType>({
    resolver: zodResolver(exportSchema),
    defaultValues: {
      start_date: today,
      end_date: today,
    }
  });

  const export_meta = {
    fields: {
      employee_code: {
        alias: "Person ID"
      },
      first_name: {
        alias: "First Name"
      },
      last_name: {
        alias: "Last Name"
      },
      att_date: {
        alias: "Date"
      },
      timesheet_name: {
        alias: "Timesheet"
      },
      clock_in: {
        alias: "Clock In"
      },
      clock_out: {
        alias: "Clock Out"
      },
      period_hrs: {
        alias: "Work Time(h)"
      },
      total: {
        alias: "Total Time(h)"
      },
      pairing: {
        alias: "Statistic Rule Mode"
      }
    },
    title: t("common.Timecard Report"),
    file_type: "csv",
    share: []
  }


  const onSubmit = async (formData: any) => {
    const { share } = formData;
    let isInvalidEmail = false;
    const share_email = share.trim()
                            .replace(/[\r\n\s]/g, "")
                            .split(';')
                            .filter((item: string)=>{
                              const email_pattern = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
                              if (item !== "" && email_pattern.test(item)) {
                                return true;
                              }
                              isInvalidEmail = true
                              return false;
                            });
    if (isInvalidEmail) {
      manualShareSettingForm.setError("share", { type: "custom", message: "invalid email format" }, { shouldFocus: true })
      return
    }
    const data = {...export_meta, share: share_email};
    const params = `date_range=${moment(manualShareSettingForm.getValues("start_date")).format('yyyy-MM-DD')}&date_range=${moment(manualShareSettingForm.getValues("end_date")).format('yyyy-MM-DD')}`;
    const res = await TimecardService.export_data(params, data).then((result)=>{
      SnackbarUtil.success(t("common.success"));
    });
  };

  return (
    <>
      <Grid component={'form'}>
        <Grid padding={"8px 0"}>
          <Typography>{t("export.Select date period")}</Typography>
        </Grid>
        <Paper elevation={0} sx={{padding: '8px', lineHeight: '40px'}}>
          <Grid container>
            <Grid lg={6} md={6} sm={6} item>
              <Box>
                <Typography>{t("common.From")}</Typography>
              </Box>
              <Controller
                name="start_date"
                control={manualShareSettingForm.control}
                render={({ field, fieldState }) =>
                  <DatePicker
                    value={field.value || null}
                    inputFormat={"dd-MM-yyyy"}
                    onChange={(newValue: any) => {
                      if (newValue > manualShareSettingForm.getValues("end_date")) {
                        SnackbarUtil.error(t("export.Start date cannot greater than end date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                        return;
                      }
                      console.log("Test value--->", newValue)
                      field.onChange(newValue)
                    }}
                    renderInput={(params) =>
                      <TextField
                        sx={{ width: '37vw' }}
                        {...params}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ""}
                      />}
                  />
                }
              />
            </Grid>
            <Grid lg={6} md={6} sm={6} item>
              <Box>
                <Typography>{t("common.To")}</Typography>
              </Box>
              <Controller
                name="end_date"
                control={manualShareSettingForm.control}
                render={({ field, fieldState }) =>
                  <DatePicker
                    value={field.value || null}
                    inputFormat={"dd-MM-yyyy"}
                    onChange={(newValue: any) => {
                      if (newValue < manualShareSettingForm.getValues("start_date")) {
                        SnackbarUtil.error(t("export.End date cannot less than start date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                        return;
                      }
                      field.onChange(newValue)
                    }}
                    renderInput={(params) =>
                      <TextField
                        sx={{ width: '37vw' }}
                        {...params}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ""}
                      />}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid>
            <Box padding={"8px 0"}>
              <Typography>{t("export.Email List")}</Typography>
            </Box>
            <ControlledTextFiled
              hookForm={manualShareSettingForm}
              sx={{width: '37vw'}}
              name="share"
              multiline
              minRows={5}
              maxRows={5}
              type="text"
            />
            <Grid sx={{display: 'flex', justifyContent: 'end', margin: '0 40px 0 0'}}>
              <SaveButton
                disableElevation
                type="submit"
                children= {t("common.confirm")}
                onClick={manualShareSettingForm.handleSubmit(onSubmit)}
                />
            </Grid>
          </Grid>

        </Paper>
      </Grid>

    </>
  );
}

export default ManualShare;

