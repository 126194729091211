import React, {useEffect} from "react";
import {Box, Grid, TextField, Paper, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {useLocation} from "react-router-dom";
import ScheduleService from "@/services/att/services/ScheduleService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z, object, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import WebURL from "@/urls";


const scheduleSchema = object({
  // assigned_start: z.date(),
  // assigned_end: z.date(),
  employee_name: z.string(),
  timesheet_name: z.string(),
})
type scheduleType = TypeOf<typeof scheduleSchema>;


const ViewSchedule: React.FC = () => {
  const location = useLocation()
  const state = location.state as { id: string }
  const {t} = useTranslation();

  const [start_date, setStartDate] = React.useState<Date | null>(null);
  const [end_date, setEndDate] = React.useState<Date | null>(null);

  const scheduleForm = useForm<scheduleType>({
    resolver: zodResolver(scheduleSchema),
  });

  useEffect(() => {
    ScheduleService.retrieve(state.id || "").then((res) => {
      setStartDate(res.data.assigned_start);
      setEndDate(res.data.assigned_end);
      // scheduleForm.setValue('assigned_end', res.data.assigned_end);
      // scheduleForm.setValue('assigned_start', res.data.assigned_start);
      scheduleForm.setValue('employee_name', res.data.employee_name);
      scheduleForm.setValue('timesheet_name', res.data.timesheet_name);
    })
  }, [])

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.ATT_SCHEDULE, label: t("att.schedule.Shift schedule") },
        { label: t("att.schedule.View shift schedule") },
      ]} />
      <Typography padding='20px 0 10px 0'> {t("att.schedule.Basic Settings")} </Typography>
      <Paper style={{ minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', padding: '0 15px 15px 15px' }}>
      <Grid container={true}>
        <Grid container={true} component={"form"} columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12}/>
          <Grid item xs={6}>
            <Typography>
              {t("common.Select person")}
            </Typography>
            <TextField
              hiddenLabel
              fullWidth
              sx={{ padding: '10px 10px 10px 0' }}
              InputProps={{
                readOnly: true,
              }}
              disabled
              {...scheduleForm.register("employee_name")}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {t("common.Select timesheet")}
            </Typography>
            <TextField
              hiddenLabel
              fullWidth
              sx={{ padding: '10px 10px 10px 0' }}
              InputProps={{
                readOnly: true,
              }}
              disabled
              {...scheduleForm.register("timesheet_name")}
            />
          </Grid>
          <Grid item xs={6} >
            <Typography>
              {t("common.Start Date")}
            </Typography>
            <DatePicker
              label=""
              value={start_date}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              disabled
              disableMaskedInput
              inputFormat="dd MMM yyyy"
              renderInput={(params) => <TextField fullWidth {...params} />}
              // {...scheduleForm.register("assigned_start")}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {t("common.End Date")}
            </Typography>
            <DatePicker
              label=""
              value={end_date}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              disabled
              disableMaskedInput
              inputFormat="dd MMM yyyy"
              renderInput={(params) => <TextField fullWidth {...params} />}
              // {...scheduleForm.register("assigned_end")}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} />
        </Grid>
      </Grid>
      </Paper>
    </>
  )
}
export default ViewSchedule
