import request from "../../../utils/Request";
import {ATTEndpoints} from "../endpoints";

const addTimesheet = async (data: any) => {
  const response = await request.authorizedAxios().post(ATTEndpoints.ATT_TIMESHEET_URI, data);
  return response.data;
};

const updateTimesheet = async (id: string, data: any) => {
  const response = await request.authorizedAxios().put(`${ATTEndpoints.ATT_TIMESHEET_URI}${id}/`, data);
  return response.data;
};

const TimesheetService = {
  addTimesheet,
  updateTimesheet
}
export default TimesheetService
