import { IconButton, Tooltip } from "@mui/material"
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useTranslation } from "react-i18next";

const InfoAction = ({setInfo}: any) => {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.Usage Tips")}>
      <IconButton sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }} onClick={()=>{
        setInfo(true)
      }}>
        <PriorityHighIcon />
      </IconButton>
    </Tooltip>
  )
}

export default InfoAction
