import React, {useState, useRef, useEffect, MutableRefObject} from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IconButton, FormControl, MenuItem, Grid, Input, CircularProgress } from '@mui/material';
import {Box, Stack, Tooltip, Collapse } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as PasswordIcon } from '@/assets/images/credential/PinIcon.svg';
import { ReactComponent as CardIcon } from '@/assets/images/credential/CardIcon.svg';
import { ReactComponent as FingerprintIcon } from '@/assets/images/credential/FingerPrintIcon.svg';
import { ReactComponent as VisibleFaceIcon } from '@/assets/images/credential/VisibleLightFaceIcon.svg';
import Dialog from "@/components/dialog"
import AvatarIcon from "@/components/avatar/AvatarIcon";
import CustomDataGrid, { DataGridRef } from '@/components/data-grid/CustomDataGrid';
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {DMSEndpoints} from "@/services/dms/endpoints";
import DeviceService from "@/services/dms/DeviceService";
import CredentialService from "@/services/cms/CredentialService"
import WebURL from "@/urls";
import DeviceInfoHeader from "./DeviceInfoHeader"
import ExtraToolBar from './HelpInformation'
import SnackbarUtil from '@/utils/SnackbarUtil';


type hoverType = "PINdisplay" | "CARDdisplay" | "FPdisplay" | "VLFdisplay"

// Cred name and type numeric
enum registrationType {
  "Common", "FingerPrint", "Face", "Voiceprint", "Iris", "Retina", "Palmprint", "Fingervein", "Palm", "Visible Light Face"}

enum registrationType {
  "Password"=102,
  "Card"="card"
}


const FingerSelect: React.FC<{cRef: MutableRefObject<any>}> = (props) => {
  const { t } = useTranslation();
  const fingerOptions = [
    {value: '4', option: t('dms.Left Thumb')},
    {value: '3', option: t('dms.Left First Finger')},
    {value: '2', option: t('dms.Left Middle Finger')},
    {value: '1', option: t('dms.Left Ring Finger')},
    {value: '0', option: t('dms.Left Little Finger')},
    {value: '5', option: t('dms.Right Thumb')},
    {value: '6', option: t('dms.Right First Finger')},
    {value: '7', option: t('dms.Right Middle Finger')},
    {value: '8', option: t('dms.Right Ring Finger')},
    {value: '9', option: t('dms.Right Little Finger')},
  ];
  const [selected, setSelected] = useState('4')
  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value)
    props.cRef.current = (event.target.value)
  }
  return (
    <FormControl fullWidth>
      <Select
        value={selected}
        onChange={handleChange}
      >
        {fingerOptions.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>
            {option.option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const RegistrationRender = (refresh: () => void) => (params: GridRenderCellParams) => {
  const { t } = useTranslation();
  const cRef = useRef('');
  const row = params.row;
  const [hoverDisplay, setHoverDisplay] = React.useState({
    PINdisplay: false,
    CARDdisplay: false,
    FPdisplay: false,
    VLFdisplay : false,
  });

  const handleMouseHover = (event: React.MouseEvent<HTMLElement>, type: hoverType) => {
    if (event)
      setHoverDisplay({...hoverDisplay, [type]: true});
  };

  const handleMouseOut = (event: React.MouseEvent<HTMLElement>, type: hoverType) => {
    if (event)
      setHoverDisplay({...hoverDisplay, [type]: false});
  };

  const { PINdisplay, CARDdisplay, FPdisplay, VLFdisplay } = hoverDisplay;

  const credRegistration = (type: number|string) => {
    cRef.current='4' //default finger is left thumb
    const cred_name = typeof(type)==="number" ? registrationType[type] : type
    Dialog.confirm({
      width: "500px",
      title: t("dms.Remote registration title", {type: cred_name}),
      helpInfo: t("dms.Require complete on device") ,
      description:
        type==registrationType.FingerPrint ? t(`dms.Which finger you want to choose?`)
          : t("dms.Are you sure to register type {} remotely?", {type: cred_name}),
      content: type==registrationType.FingerPrint ? <FingerSelect cRef={cRef}/>: "",
      onConfirm: () => {
        DeviceService.remoteRegistration(row.deviceId,
          {
            "enrollType": type,
            "pin": row.personCode,
            "fid": cRef.current || "1",
          }).then(
            (result) => {
              console.log('DeviceService.remoteRegistration: ', result);
              if (result.status < 400){
                SnackbarUtil.success(t("dms.Command has been sent successfully", {msg: result.data?.data?.msg}), {'autoHideDuration': 5000,})
                setTimeout(function(){
                  refresh()}, 8000);
                   //waiting registration, then query template count again
                }
            })
      }
    })
  }

  const pwdRegistration = () => {
    Dialog.confirm({
      title: t("dms.Are you sure to register a new password?"),
      description: t("dms.Please set a password"),
      content:
        <Grid>
          <Input type={"password"} onChange={event => cRef.current = event.target.value}/>
          <p style={{fontSize: '0.75rem', color: '#f29548'}}>{t("dms.Set password tip")}</p>
        </Grid>,
      onConfirm: () => {
        if (!cRef.current){ //interface not allow empty pwd
          SnackbarUtil.error(t("dms.Please input a valid password"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
          return
        }
        const condition = {
          "personIdOrCode": row.personId,
          "passcode": cRef.current,
          "deviceId": row.deviceId
        }
        CredentialService.setPersonPassword(condition).then((result)=>{
          if (result.status < 400)
            SnackbarUtil.success(result.data.message );
            refresh();
        })
      }
    })
  }

  const isAllowRegistration = (type: number|string) => {
    if (Number(params.row.status) === 0){ //offline
      SnackbarUtil.error(t("dms.Offline device not allow registration"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return
    }else{
      if (type === registrationType.Password)
        return pwdRegistration()
      else
        return credRegistration(type)
      }
  }

  return (
    <Stack direction={"row"} spacing={1} >
      <Tooltip title={t('dms.Register Password')} placement='top'>
        <IconButton
          name="PIN"
          disableRipple
          onMouseEnter={(e)=>handleMouseHover(e, 'PINdisplay')}
          onMouseLeave={(e)=>handleMouseOut(e, 'PINdisplay')}
          onClick={() => isAllowRegistration(registrationType.Password)}
        >
          <PasswordIcon/>
        </IconButton>
      </Tooltip>
      <Collapse in={PINdisplay} orientation="horizontal">
        <Grid sx={{ fontSize: "16px", paddingTop:"16px"}}>
          {params.row.credentialCount.passcode}
        </Grid >
      </Collapse>

      <Tooltip title={t('dms.Register Card')} placement='top'>
        <IconButton
          name="Card"
          disableRipple
          onMouseEnter={(e)=>handleMouseHover(e, 'CARDdisplay')}
          onMouseLeave={(e)=>handleMouseOut(e, 'CARDdisplay')}
          onClick={() => isAllowRegistration(registrationType.Card)}
        >
          <CardIcon/>
        </IconButton>
      </Tooltip>
      <Collapse in={CARDdisplay} orientation="horizontal">
        <Grid sx={{ fontSize: "16px", paddingTop:"16px"}}>
          {params.row.credentialCount.card}
        </Grid >
      </Collapse>

      <Tooltip title={t('dms.Register Fingerprint')} placement='top'>
        <IconButton
          name="FP"
          disableRipple
          onMouseEnter={(e)=>handleMouseHover(e, 'FPdisplay')}
          onMouseLeave={(e)=>handleMouseOut(e, 'FPdisplay')}
          onClick={() => isAllowRegistration(registrationType.FingerPrint)}
        >
          <FingerprintIcon/>
        </IconButton>
      </Tooltip>
      <Collapse in={FPdisplay} orientation="horizontal">
        <Grid sx={{ fontSize: "16px", paddingTop:"16px"}}>
          {params.row.credentialCount.fingerPrint}
        </Grid >
      </Collapse>

      <Tooltip title={t('dms.Register Visible Light Face')} placement='top'>
        <IconButton
          name="VLF"
          disableRipple
          onMouseEnter={(e)=>handleMouseHover(e, 'VLFdisplay')}
          onMouseLeave={(e)=>handleMouseOut(e, 'VLFdisplay')}
          onClick={() => isAllowRegistration(registrationType["Visible Light Face"])}
        >
          <VisibleFaceIcon/>
        </IconButton>
      </Tooltip>
      <Collapse in={VLFdisplay} orientation="horizontal">
        <Grid sx={{ fontSize: "16px", paddingTop:"16px"}}>
          {params.row.credentialCount.visibleLightFace}
        </Grid >
      </Collapse>
    </Stack>
  )
}

const RegistrationAction = (refresh: () => void) => (cell: GridRenderCellParams) => {
  const { t } = useTranslation();
  return (
    <Stack direction={"row"}>
      <CustomRowButton insideIcon={DeleteOutlineIcon} buttonProps={{
        key: "delete",
        onClick: async () => {
          Dialog.confirm({
            title: t("common.delete"),
            content: t("dms.Do you want to delete this person from device?"),
            onConfirm: () => {
              DeviceService.deleteDevicePerson(`${cell.row.deviceId}`, {"data": {"personId": `${cell.id}`}})
              .then((result) => {
                  console.log('DeviceService.deleteDevicePerson: ', result);
                  if (result.status < 400){
                    SnackbarUtil.success(t('dms.Delete person successfully'));
                    refresh();}
                  });
            }});}
          }}/>
        </Stack>
  )
}

const PersonInDevice: React.FC = () => {
  const location = useLocation();
  const state = location.state as { id: string };
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = React.useState(false);

  const processRowUpdate = async (newRow: GridRowModel, oldRow:GridRowModel ) => {
    if (newRow.role === oldRow.role)
      return oldRow
    try {
      setLoading(true)
      await CredentialService.updateUserRole(newRow.personId, newRow)
      SnackbarUtil.success(t("dms.Role Updated Successfully"));
      return newRow
    } catch (e) {
      return oldRow
    }finally {
      setLoading(false)
    }
  };

  const sumCredentialCount = (credentialCount?: {}) => {
    if (typeof(credentialCount) === 'undefined')
      return 0
    const countList: any = Object.values(credentialCount)
    if (countList.length > 0)
      return countList.reduce((x:number, y:number) => x+y)
    return 0
  }

  const columns: GridColDef[] = [
    {field: 'personCode', headerName: t("hr.employee.Person ID"), type: "string", minWidth: 180, flex: 0.2},
    {field: 'full_name', headerName: t("hr.employee.Person Name"), minWidth: 300, flex: 0.3, renderCell: (e: GridRenderCellParams) =>(
      <Grid style={{display: "flex", alignItems: "center"}}>
        <AvatarIcon name={e.row?.firstName|| '-'} />
        <Box style={{padding: '0 8px'}}>{e.row.firstName} {e.row.lastName}</Box>
      </Grid>
    )},
    {field: 'role', headerName: t("dms.Role in device"), minWidth: 180, flex: 0.2, editable:true, type: 'singleSelect',
      valueOptions: ['ADMINSTRATOR', 'COMMONUSER'],
      valueFormatter: (params) => {
        if (params.value == 'ADMINSTRATOR') // CMS admin word misspelling
          return t('dms.Device role Administrator');
        return t('dms.Device role CommonUser');
      },
    },
    {field: 'deviceId', type: 'string', flex: 0.1},
    {field: 'registration', headerName: t("dms.Remote registration"), headerAlign: 'center', minWidth: 260, flex: 0.3,
      renderCell: RegistrationRender(() => setRefresh(!refresh))},
    {field: 'actions', headerName: t("common.Action"), headerAlign: 'center', align: 'center', flex: 0.2,
      renderCell: RegistrationAction(() => setRefresh(!refresh))}
  ]

  useEffect(() => {
    dataGridRef.current?.refresh();
  }, [refresh])

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.DMS_DEVICE, label: t("common.Device") },
        { label: t("common.Person in this device") },
      ]} />
      <DeviceInfoHeader/>
      <CircularProgress sx={{ position: "absolute", left: "50%", top: "50%", display: loading?"block":"none", zIndex: 1}}/>
      <CustomDataGrid
        uri={`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${state.id||""}/person_list/`}
        getRowId={(row) => row.personId}
        checkboxSelection={false}
        ref={dataGridRef}
        columns={columns}
        columnVisibilityModel={{
          deviceId: false
        }}
        isCellEditable={(params) => sumCredentialCount(params.row.credentialCount) > 0} // 该人员有credential才能设置管理员
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        toolbar={{
          title: " ",
          searchTips: t("common.Search by Person ID"),
          breadcrumbs: [
            {breadcrumbName: t("dms.Person and registration title"), path: ""}
          ],
          extraComponentOnLeft: <ExtraToolBar/>,
          filter: false
        }}
      />
    </>
  )
}
export default PersonInDevice;
