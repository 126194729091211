import * as React from "react";
import {Autocomplete, AutocompleteProps, AutocompleteValue, TextField} from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form"
import {AutocompleteRenderOptionState} from "@mui/material/Autocomplete/Autocomplete";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";

//type ControlAutoCompleteType1 = AutocompleteProps<any, any, any, any> & Omit<AutocompleteProps<any, any, any, any>, 'lable'> & {label: string}


type ControlAutoCompleteType = {
  label: string;
  name: string;
  form: UseFormReturn<any>;
  options: any[];
  required?: boolean;
  disabled?: boolean;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  onChange?: (value: AutocompleteValue<any, any, any, any>) => void;
  groupBy?: (option: any) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
}

const ControlAutoComplete: React.FC<ControlAutoCompleteType> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.form.control}
      render={({field: {onChange, value}, fieldState}) => {
        return (
          <Autocomplete
            fullWidth
            disabled={props?.disabled}
            options={props.options}
            getOptionLabel={option => option.name || ""}
            groupBy={props.groupBy}
            renderOption={props.renderOption}
            renderInput={(params) => (
              <>
                <NGInputLabel label={props.label} required={props.required}/>
                <TextField
                  {...params}
                  // label={props.label}
                  required={props.required}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || ""}
                />
              </>
            )}
            onChange={(event, value) => {
              onChange(value);
              props.onChange?.(value);
            }}
            value={value}
            isOptionEqualToValue={props.isOptionEqualToValue}
          />
        )
      }}
    />
  )
}

export default ControlAutoComplete
