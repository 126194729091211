import request from "../../../utils/Request";
import {ATTEndpoints} from "../endpoints";
import qs from 'qs';


const list = async (params?: Record<string, any>) => {
  const response = await request.authorizedAxios().get(ATTEndpoints.ATT_TIMECARD_URI, {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response.data;
}

const export_data = async (params: string, data: any) => {
  const url = `${ATTEndpoints.ATT_TIMECARD_URI}export/?${params}`;
  const response = await request.authorizedAxios().post(url, data);
  return response;
}

const query_setting = async () => {
  const url = `${ATTEndpoints.ATT_TIMECARD_URI}query_setting/`;
  const response = await request.authorizedAxios().get(url);
  return response;
}

const export_setting = async (data: any) => {
  const url = `${ATTEndpoints.ATT_TIMECARD_URI}auto_export_setting/`;
  const response = await request.authorizedAxios().post(url, data);
  return response;
}

const TimecardService = {
  list,
  export_data,
  query_setting,
  export_setting,
}
export default TimecardService;