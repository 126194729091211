import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import ManualShare from "./components/ManualShare"
import AutoShare from "./components/AutoShare"
import WebURL from "@/urls";

const ReportShare: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Grid className={"toolbar-container"} >
        <CustomBreadcrumbs record={[
          { path: WebURL.ATT_TIMECARD_TIMECARD, label: t("common.Timecard Management") },
          {
            label: t("export.Report Export"),
          },
        ]} />
      </Grid>

      <Grid>
        <ManualShare/>
        <AutoShare/>
      </Grid>
    </>
  );
}

export default ReportShare;

