import DeviceService from "@/services/dms/DeviceService";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { object, TypeOf, z } from "zod";
import DeviceBinding from "./DeviceBinding";

type DeviceRegisterProps = {
  setStep: Dispatch<SetStateAction<string>>
}

const DeviceRegister: React.FC<DeviceRegisterProps> = (props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [device, setDevice] = useState<{deviceId: string, sn: string}>();
  const checkDeviceSchema = object({
    sn: z.string().min(1, t("common.This field is required!"))
  })
  type checkInput = TypeOf<typeof checkDeviceSchema>;
  const checkForm = useForm<checkInput>({
    resolver: zodResolver(checkDeviceSchema)
  });
  return (
    <>
      <Stack sx={{marginRight: "20px"}} spacing={1}>
        <Box fontSize={"20px"} sx={{marginTop: '20px'}}>
          <Typography>{t("dms.tip04")}</Typography>
        </Box>
        <Box fontSize={"18px"}>
          <Typography>{t("dms.tip05")}</Typography>
        </Box>
        <Box fontSize={"16px"} lineHeight={"30px"} color={"gray"}>
          <Stack>
            <Typography>{t("dms.tip06")}</Typography>
            <Typography>{t("dms.tip07")}</Typography>
            <Typography>{t("dms.tip08")}</Typography>
            <Typography>{t("dms.tip09")}</Typography>
          </Stack>
        </Box>
        <Box component={"form"}>
          <Grid container>
            <Grid xs={4} item>
              <Typography>{t("dms.Enter device SN")}</Typography>
              <TextField
                required
                autoFocus
                variant="outlined"
                size={"small"}
                fullWidth
                error={!!checkForm.formState.errors['sn']}
                helperText={checkForm.formState.errors['sn']?.message || ""}
                {...checkForm.register("sn")}
              />
            </Grid>
            <Grid xs={12} item style={{display: "flex", justifyContent: "flex-end", marginTop: '10vh'}}>
              <LoadingButton
                loading={loading}
                disableElevation
                sx={{width: '10vw', textTransform: "capitalize"}}
                variant="contained"
                onClick={ checkForm.handleSubmit(async () => {
                  setLoading(true)
                  const isValid = await checkForm.trigger();
                  try {
                    if (isValid) {
                      const {sn} = checkForm.getValues();
                      const res = await DeviceService.deviceVerify(sn);
                      if (res.data.deviceId) {
                        setDevice({deviceId: res.data.deviceId, sn});
                        setVisible(true);
                      }
                    }
                  } catch (e) {
                    console.log(e);
                  } finally {
                    setLoading(false);
                  }
                })
              }
              >
                {t("common.confirm")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <DeviceBinding visible={visible} device={device} onClose={() => setVisible(false)} />
    </>
  )
}

export default DeviceRegister;
