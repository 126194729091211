import React from "react";
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import { useSnackbar } from "notistack";
import { Grid, Stack, Paper } from "@mui/material";

import { zodResolver } from "@hookform/resolvers/zod";
import EmployeeService from "@/services/hr/EmployeeService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { CancelButton, SavingButton } from "@/components/data-grid/CustomButton";
import WebURL from "@/urls";
import EmployeeForm from "./EmployeeForm";

const EmployeeNew = () => {
  const { t } = useTranslation();
  const codeRegx = /^[a-zA-Z0-9]+$/
  const employeeValidate = object({
    code: z.string({ required_error: t("common.This field is required!")}).regex(codeRegx, t("common.No special character allowed!")).min(1, t("common.This field is required!"))
        .max(14, t("common.The length of field cannot exceed digits", {number: 14})),
    firstName: z.string({ required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
        .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    lastName: z.string({ required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
        .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    departmentIdOrCode: z.string().optional(),
    dept: z.object({
      id: z.string(),
      name: z.string(),
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
  })
  type employeeInput = TypeOf<typeof employeeValidate>;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = React.useState<boolean>(false)
  const title = t("common.New Person")

  const employeeForm= useForm<employeeInput>({
    resolver: zodResolver(employeeValidate)
  });

  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      const values = employeeForm.getValues()
      values.departmentIdOrCode = values.dept.id
      const result = await EmployeeService.add(values);
      if (result.status < 400) {
        enqueueSnackbar(t("common.Person added successfully."), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        navigate(WebURL.HR_EMPLOYEE);
      }
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.HR_EMPLOYEE, label: t("common.Person") },
        { label: title },
      ]} />
      <EmployeeForm employeeForm={employeeForm} />
      <Grid style={{'padding':'30px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton />
          <SavingButton loading={saving} onClick={employeeForm.handleSubmit(onSubmit)}/>
        </Stack>
      </Grid>
    </>
  )
}

export default EmployeeNew