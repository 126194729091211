/**
 * Translation Locales
 * Include Custom translation locales and Material UI default locales.
 * Require to map the custom locale code to Material UI default language code,
 * so that can use Material UI translation
 */

// Custom locales
export type LanguageCode = "zh-CN" | "en" | "es" | "ja" | "fr" | "de" | "it" | "Les"
export const Languages = [
  {code: 'zh-CN', title: '中文简体'},
  {code: 'en', title: 'Global English'},
  {code: 'es', title: 'Español'},
  {code: 'ja', title: '日本語'},
  {code: 'fr', title: 'Français'},
  {code: 'de', title: 'Deutsch'},
  {code: 'it', title: 'Italiano'},
  {code: 'Les', title: 'Español latino'},
]


// Material UI locales
export type LanguageLocale = "zhCN" | "enUS" | "esES" | "jaJP" | "caES" | "frFR" | "deDE" | "itIT"
// mapping relation
export const Locales = {
  "zh-CN": "zhCN",
  "en": "enUS",
  "ja": "jaJP",
  "es": "esES",
  "fr": "frFR",
  "de": "deDE",
  "it": "itIT",
  "Les": "caES",
}
