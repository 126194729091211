import React, { useContext, useRef, useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import CustomDataGrid, { DataGridRef } from "@/components/data-grid/CustomDataGrid";
import { BaseEndpoints } from "@/services/base/endpoints";
import { useNavigate } from "react-router-dom";
import WebURL from "@/urls";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import Util from "@/utils/Util";

const StatusField = (params: GridRenderCellParams) => {
  if (params.value === 0) {
    return (
      <span>
        Success
      </span>
    )
  } else {
    return (
      <span>
        Error
      </span>
    )
  }
}

const DatetimeField = (params: GridRenderCellParams) => {
  return (
    <span>
      {Util.datetime2string(new Date(params.value))}
    </span>
  )
}

const LongTextField = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.value}>
      <span>
      {params.value}
      </span>
    </Tooltip>

  )
}


const AdminLog: React.FC = () => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: 'id', headerName: "id", minWidth: 60},
    { field: 'user', headerName: "User", minWidth: 120 },
    { field: 'op_time', renderCell: DatetimeField, headerName: "Time", type: "date", minWidth: 180 },
    { field: 'action', headerName: t('common.Action'), minWidth: 240  },
    { field: 'targets', headerName: "targets", minWidth: 120},
    { field: 'action_status', renderCell: StatusField, headerName: "State", minWidth: 120},
    { field: 'description', renderCell: LongTextField, headerName: "description", minWidth: 320},
  ]
  return (
    <>
      <CustomBreadcrumbs record={[
        { label: 'AdminLog' },
      ]} />
      <CustomDataGrid
        uri={`/base/api/v1.0/admin_log/`}
        ref={dataGridRef}
        columns={columns}
        checkboxSelection={false}
      />
    </>
  )
}
export default AdminLog
