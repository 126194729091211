import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  Box,
  Grid,
  TextField,
  Typography,
  Input
} from "@mui/material";
import {object, TypeOf, z} from "zod";
import {useForm, Controller} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";
import { useTranslation } from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import internal from "stream";

type TimesheetProps = {}
type TimesheetSettingProps = {
  cycle: number;
  period: string;
  timesheet: TimesheetProps[];
  view_data: any;
}

type InlineDayOfWeekProps = {
  index: number;
  checked: boolean
}


const TimesheetSetting = (props: TimesheetSettingProps, ref: any) => {
  const { t, i18n} = useTranslation();
  const sheetSchema = object({
    cycle: z.number({invalid_type_error: t("common.This field is required!")})
      .lt(13, {message: t("att.timeSheet.Maximum cycle is 12")})
      .gte(1, {message: t("att.timeSheet.Minimum cycle is 1")}),
    period: z.string()
  })

  type SheetInput = TypeOf<typeof sheetSchema>;

  const Weekday = [t("att.timeSheet.Sunday"),t("att.timeSheet.Monday"),
    t("att.timeSheet.Tuesday"), t("att.timeSheet.Wednesday"), t("att.timeSheet.Thursday"),
    t("att.timeSheet.Friday"), t("att.timeSheet.Saturday")];
  const {cycle, period} = props;
  const param_id = props.view_data;
  const [timesheet, setTimesheet] = useState<any[]>(props.timesheet);
  const [expand, setExpand] = useState<boolean>(false);
  const [CycleError, setCycleError] = useState<boolean>(false);
  const [CycleErrorText, setCycleErrorText] = useState<string>("");
  const sheetForm = useForm<SheetInput>({
    defaultValues: {cycle: cycle, period: "week"},
    resolver: zodResolver(sheetSchema)
  })
  useImperativeHandle(ref, () => ({
    sheet: async () => {
      console.log("submit....?",timesheet)
      return {
        ...sheetForm.getValues(),
        sheet: timesheet.sort((a, b) => (parseInt(a.index) - parseInt(b.index)))
      }
    },
  }))
  const createTimesheet = (cycle: number, period: string) => {
    if (cycle < 1 || cycle == 0){
      setCycleError(true);
      setCycleErrorText(t("att.timeSheet.Minimum cycle is 1"))
    }
    else if (cycle > 12){
      setCycleError(true);
      setCycleErrorText(t("att.timeSheet.Maximum cycle is 12"))
    }
    else if (String (cycle).indexOf (".") > -1){
      setCycleError(true);
      setCycleErrorText(t("att.timeSheet.Cycle must be an integer"))
    }
    else{
      setCycleError(false);
      setCycleErrorText("")
    }
    const newTimesheet: any[] = [];
    if (cycle >= 1 && cycle <= 12){
      for (let i = 0; i < (7 * cycle); i++) {
      const day = i + 1;
      if (day % 7 === 6 || day % 7 === 0){
        newTimesheet.push({
          index: day,
          checked:false
        })
      }
      else{
        newTimesheet.push({
        index: day,
        checked:true
          })
      }}
    }
    const preSheetLen = timesheet.length;
    const newSheetLen = newTimesheet.length;
    if(preSheetLen > newSheetLen){
      setTimesheet(timesheet.slice(0, newSheetLen));
    }else{
      setTimesheet(timesheet.concat(newTimesheet.slice(preSheetLen, newSheetLen)))
    }
  }
  useEffect(() => {
      sheetForm.watch((data) => {
        createTimesheet(data.cycle || 0, data.period || "week");
      });
      if (param_id){
         GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${param_id}`).then((res) => {
           sheetForm.setValue("cycle", res.data?.cycle);
           setTimesheet(
            res.data?.sheet.map((item:any)=>{
              return {index:item.index, checked:item.checked}}
            )
          )
        })
       }
  }, [sheetForm.watch]);
  useEffect(() => {
    createTimesheet(cycle, period);
  }, [cycle, period]);
  const [checked, setChecked] = useState<boolean>(true);
  const handleChange = (index: any, event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    timesheet[index-1]= {index:index, checked:!timesheet[index-1]["checked"]};
  };

  const InlineDayOfWeek = (props: InlineDayOfWeekProps) =>{
    const NewLineBySunday = (props: {index:number}) => <>{props.index % 7===0 ? <br></br>: <></>}</>

    return (
      <>
        {
        !!param_id === true ?
          <>
            <FormControlLabel control={<Checkbox checked={props.checked} disabled/>} label={Weekday[props.index % 7]} />
            <NewLineBySunday index={props.index} />
          </>
        :
          <>
            <FormControlLabel control={<Checkbox defaultChecked={props.checked} onChange={(e)=>{handleChange(props.index, e)}}/>} label={Weekday[props.index % 7]}/>
            <NewLineBySunday index={props.index} />
          </>
        }
      </>
      )
  }

  return (
        <Grid container={true} columnSpacing={6} rowSpacing={2} component={"form"}>
          <Grid item={true} xs={6} sx={{display: "inline-flex", flexDirection: "column"}}>
            <NGInputLabel label={t("common.Schedule mode")}/>
            <Box sx={{marginTop: '5px'}}>
              <Select
                fullWidth={true}
                defaultValue={"week"}
                disabled={true}
                {...sheetForm.register("period")}
              >
                <MenuItem value={"week"}>{t("common.By Week Cycle")}</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item={true} xs={6}>
            <NGInputLabel label={t("common.Repeat Cycle")}/>
            <TextField
              fullWidth={true}
              sx={{marginTop: '5px'}}
              InputLabelProps={{
                shrink: true
              }}
              disabled={!!param_id}
              error={CycleError}
              helperText={CycleErrorText}
              {...sheetForm.register("cycle", {valueAsNumber: true})}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <NGInputLabel label={t("common.Schedule")}/>
              <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: '1px solid #AEAFB0', height: '100%', lineHeight: '50px', minWidth: '200px' }}>
                <FormGroup row={true} sx={{marginLeft: '15px'}}>
                  <Grid item={true} sx={{ minWidth: "550px"}}>
                  {timesheet.map((item) => {
                    return <InlineDayOfWeek checked={item.checked} index={item.index} key={item.index}/>
                  })}
                  </Grid>
                </FormGroup>
              </Grid>
          </Grid>
        </Grid>
  )
};
export default forwardRef(TimesheetSetting)
