import React, {useEffect, useRef} from "react";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Box, Grid, Stack} from "@mui/material";
import CustomDataGrid, { DataGridRef } from "../../../components/data-grid/CustomDataGrid";
import { Add, RemoveRedEyeOutlined, DeleteForever } from "@mui/icons-material";
import WebURL from "../../../urls";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import EditIcon from "@mui/icons-material/Edit";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Dialog from "@/components/dialog";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom"
import VisibilityIcon from "@mui/icons-material/Visibility";
import {AuthEndpoints} from "@/services/auth/endpoints";

const CustomerListDataGrid: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const columns: GridColDef[] = [
    {field: 'name', headerName: t("common.Client organization Name"), type: "string", minWidth: 200},
    {field: 'code', headerName: t("common.Organization Code"), type: "string", minWidth: 200},
    {field: 'device_count', headerName: t("common.Device Qty."), type: "string", minWidth: 100,},
    {field: 'email', headerName: t("common.Email"), type: "string", minWidth: 200},
    {field: 'address', headerName: t("common.Address"), type: "string", minWidth: 200},
    {
      field: 'actions', headerName: t("common.action"), headerAlign: 'center', align: 'center',  flex: 150,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <CustomRowButton insideIcon={VisibilityIcon} buttonProps={{
              key: "view",
              onClick: () => {
                navigate(WebURL.CUSTOMER_DETAIL, {state: {id: e.row.id}});
              }
            }}/>
          </Stack>
        )
      }
    }
  ]
  return (
    <CustomDataGrid
      uri={AuthEndpoints.AUTH_USER_ADMIN_COMPANY_LIST}
      columns={columns}
      ref={dataGridRef}
      checkboxSelection={false}
      toolbar={{
        title: t("common.Customer"),
        searchTips: t("common.Search by Client Organization Name"),
        breadcrumbs: [
          {breadcrumbName: t("common.Customer"), path: WebURL.CUSTOMER_LIST}
        ],
        // actions: [{
        //   key: 'add', icon: Add, helpTxt: t("common.add"), callback: async () => {
        //     navigate(WebURL.HR_EMPLOYEE_NEW);
        //   }
        // }]
      }}

    />
  )
}
export default CustomerListDataGrid
