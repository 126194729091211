import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Stack,
  Typography,
  Accordion,
  AccordionDetails, TextField, Select, MenuItem
} from "@mui/material";
import TimesheetSetting from "./TimsheetSetting";
import { object, TypeOf, z } from "zod";
import {Controller, useForm} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PatternSetting, { PatternSettingFields } from "./PatternSetting";
import BreakTimeSetting, { BreakTimeSettingFields } from "./BreakTimeSetting";
import TimesheetService from "../../../../services/att/services/TimesheetService";
import { useNavigate, useLocation } from "react-router-dom"
import WebURL from "../../../../urls";
import { useSnackbar } from "notistack";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import IconButton from '@mui/material/IconButton';
import GenericService from "@/services/GenericService";
import { ATTEndpoints } from "@/services/att/endpoints";
import { useTranslation } from "react-i18next";
import KnowAboutThisPolicy from "./KnowAboutThis"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CancelButton, SaveButton, SavingButton } from "@/components/data-grid/CustomButton";
import NGTextField from "@/components/ngteco-form/NGTextField";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {TimePicker} from "@mui/x-date-pickers";
import Util from "@/utils/Util";
import {forEach} from "lodash";
import {validateTime} from "@mui/x-date-pickers/internals/hooks/validation/useTimeValidation";

/**
 * Validators */


const NewTimesheet: React.FC = () => {
  const { t } = useTranslation();
  const timesheetSchema = object({
    name: z.string().min(1, t("common.This field is required!"))
        .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    description: z.string().max(200, t("common.The length of field cannot exceed digits", {number: 200})),
  })
  type TimesheetInput = TypeOf<typeof timesheetSchema>;
  const location = useLocation()
  const state = location.state as { id: string }
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const patternRef = useRef<{ pattern: any }>();
  const breakTimeRef = useRef<{ breakTime: any }>();
  const sheetRef = useRef<{ sheet: any }>();
  const title = state?.id ? t("common.View TimeSheet") : t("common.Add TimeSheet");
  const [timesheet] = useState<any[]>([]);
  const break_pairs = useState<any[]>([]);
  const [pattern] = useState<PatternSettingFields>({
    day_change: new Date('1990-01-01T00:00:00'), check_in: new Date('1990-01-01T09:00:00'),
    check_out: new Date('1990-01-01T18:00:00'), punch_interval: 1, max_hrs: 10, pairing: 'first_last', overtime_remind: 0,
  });
  const [breakTime] = useState<BreakTimeSettingFields>({
    break_times_flag: 100, break_switch: false,
    break_start_1: new Date('1990-01-01T12:00:00'), break_end_1: new Date('1990-01-01T12:30:00'),
      pairing_rule_1: "auto_deducted",
    break_start_2: new Date('1990-01-01T13:00:00'), break_end_2: new Date('1990-01-01T13:30:00'),
      pairing_rule_2: "auto_deducted",
    break_start_3: new Date('1990-01-01T14:00:00'), break_end_3: new Date('1990-01-01T14:30:00'),
      pairing_rule_3: "auto_deducted",
  });
  const [periodCycle] = useState<number>(1);
  const [policy, setPolicy] = useState<string>("main");
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  function handleClick() {
    setLoading(true);
  }
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<TimesheetInput>({
    resolver: zodResolver(timesheetSchema),
  });
  useEffect(() => {
    if (state?.id) {
      GenericService.retrieve(ATTEndpoints.ATT_TIMESHEET_URI, `${state.id}`).then((res) => {
        setValue("name", res.data?.name);
        setValue("description", res.data?.description);
        if (res.data?.pattern.break_pairs.length === 0){
           setLoading(false)
         }
      })
    }
  }, []);
  const scrollTo = (name: string) => {
    const anchorElement = document.getElementsByName(name)[0]
    if (anchorElement) {
        anchorElement.scrollIntoView({behavior: "smooth"})
    }
}
  const onSubmit = async (formData: any) => {
    let result;
    const sheetData = await sheetRef.current?.sheet();
    const patternData = await patternRef.current?.pattern();
    const breakTimeData = await breakTimeRef.current?.breakTime();
    patternData.check_in = patternData.check_in.toString().slice(16, 21)
    patternData.check_out = patternData.check_out.toString().slice(16, 21)
    patternData.day_change = patternData.day_change.toString().slice(16, 21)
    const break_pairs = []
    if (loading){
      breakTimeData.break_start_1 = breakTimeData.break_start_1.toString().slice(16, 21)
      breakTimeData.break_end_1 = breakTimeData.break_end_1.toString().slice(16, 21)
      breakTimeData.break_start_2 = breakTimeData.break_start_2.toString().slice(16, 21)
      breakTimeData.break_end_2 = breakTimeData.break_end_2.toString().slice(16, 21)
      breakTimeData.break_start_3 = breakTimeData.break_start_3.toString().slice(16, 21)
      breakTimeData.break_end_3 = breakTimeData.break_end_3.toString().slice(16, 21)
      breakTimeData.break_switch = loading
      break_pairs.push({"start": breakTimeData.break_start_1, "end": breakTimeData.break_end_1, "pairing_rule": breakTimeData.pairing_rule_1})
      if (breakTimeData.break_times_flag.toString().charAt(1) === "1"){
        break_pairs.push({"start": breakTimeData.break_start_2, "end": breakTimeData.break_end_2, "pairing_rule": breakTimeData.pairing_rule_2})
      }
      if (breakTimeData.break_times_flag.toString().charAt(2) === "1"){
        break_pairs.push({"start": breakTimeData.break_start_3, "end": breakTimeData.break_end_3, "pairing_rule": breakTimeData.pairing_rule_3})
      }
    }
    patternData["break_pairs"] = break_pairs
    if (sheetData && patternData) {
     const data = {
        ...formData,
        ...sheetData,
        pattern: patternData
      };
      if (state?.id) {
        result = await TimesheetService.updateTimesheet(state.id, data);
      }
      else if (sheetData.cycle < 1 || sheetData.cycle > 12 || isNaN(sheetData.cycle) ||
        String (sheetData.cycle).indexOf (".") > -1){
        scrollTo("cycle")
      }
      else {
        result = await TimesheetService.addTimesheet(data);
        if (result.status < 400) {
        enqueueSnackbar("Time sheet created successfully.", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        navigate(WebURL.ATT_TIMESHEET);
      }}
    }};
  return (
    <Box>
      {policy === "know" ?
        <Grid className={"toolbar-container"} >
          <Breadcrumbs separator="" sx={{ height: "32px", marginTop:"10px"}}>
            <IconButton sx={{ padding: "5px" }}>
              <ArrowBackIosNewIcon sx={{ display: "flex" }} onClick={() => setPolicy("main")} fontSize="small" />
            </IconButton>
            <Typography>{t("organ.know more about this?")}</Typography>
          </Breadcrumbs>
        </Grid>
        :
        <Grid className={"toolbar-container"} >
          <CustomBreadcrumbs record={[
            { path: WebURL.ATT_TIMESHEET, label: t("common.TimeSheet") },
            {
              label: title,
              helpInfo: t("att.timeSheet.Title Tip")
            },
          ]} />
        </Grid>}
      {policy === "know" ? <KnowAboutThisPolicy setPolicy={setPolicy} setPrivacyPolicy={setPrivacyPolicy} /> :
        <Grid container={true} rowSpacing={1} sx={{ margin: "0" }}>
          <Grid item xs={12} sx={{ p: "0 16px" }}>
            <Grid container={true} rowSpacing={2} columnSpacing={6} component={"form"} onSubmit={handleSubmit(onSubmit)}>
              <Grid item={true} xs={6}>
                <NGTextField
                  fullWidth={true}
                  placeholder={t("common.Timesheet Name")}
                  margin={"normal"}
                  label={t("common.Timesheet Name")}
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={!!errors['name']}
                  disabled={!!state}
                  helperText={errors['name']?.message || ""}
                  {...register("name")}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <NGTextField
                  fullWidth={true}
                  placeholder={t("common.Timesheet Description")}
                  label={t("common.Timesheet Description")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={!!state}
                  margin={"normal"}
                  error={!!errors['description']}
                  helperText={errors['description']?.message || ""}
                  {...register("description")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{marginLeft: "20px"}} item xs={12}>
          <NGInputLabel label={t("common.Schedule Rule")}/>
          </Grid>


          <Grid item xs={12}>
          <Box sx={{
              borderRadius: '5px',
              background: '#fff',
              margin: "20px",
              marginTop: "0px",
              paddingBottom: "24px"
            }}>
              <Grid sx={{padding: "16px"}}  container={true} component={"form"}>
              <TimesheetSetting ref={sheetRef} cycle={periodCycle} period={"week"} timesheet={timesheet} view_data={state?.id} />
              </Grid>

            </Box>
          </Grid>
          <Grid sx={{marginLeft: "20px"}} item xs={12}>
          <NGInputLabel label={t("common.ATT records statistics rule")}/>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{
              borderRadius: '5px',
              background: '#fff',
              margin: "20px",
              marginTop: "0px",
            }}>
              <Grid sx={{padding: "16px"}}  container={true} component={"form"}>

              <PatternSetting ref={patternRef} pattern={pattern} view_data={state?.id} />
                </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              borderRadius: '5px',
              background: '#fff',
              margin: "20px",
              marginTop: "0px",
            }}>
              <Grid sx={{padding: "16px"}}  container={true} component={"form"}>
                <Grid container={true} component={"form"}>
                  <NGInputLabel label={t("att.timeSheet.Break Time")}/>
                  <Switch
                    sx={{marginTop: "-6px"}}
                    checked={loading}
                    disabled={!!state}
                    onChange={() => setLoading(!loading)}
                    name="Break Time"
                    color="primary"
                  />
                </Grid>
                <Grid columnSpacing={12}>
                  {loading ? <BreakTimeSetting ref={breakTimeRef} break_time={breakTime} view_data={state?.id} /> : <Grid></Grid> }
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ p: "0 16px", display: 'flex', justifyContent: 'end' }}>
            <Box sx={{ display: "inline-flex" }}>
              <Link
                underline={"none"}
                onClick={() => setPolicy("know")}
                sx={{
                  cursor: "pointer",
                  padding: "0 8px",
                }}
              >
                <Typography>{t("organ.know more about this?")}</Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ p: "0 16px", marginBottom: "20px", display: 'flex', justifyContent: 'end' }}>
            {!state &&
              <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
                <CancelButton />
                <SaveButton type={'submit'} disabled={!!state} onClick={async () => {
                  const isValid = await trigger();
                  if (isValid) {
                    const data = getValues();
                    await onSubmit(data);
                  }
                  else{
                    scrollTo("name")
                  }
                }} />
              </Stack>
            }
          </Grid>
        </Grid>}
    </Box>
  )
}
export default NewTimesheet
