import * as React from 'react'
import {Alert, Box, DrawerProps, Grid, IconButton, TextField, Tooltip, Typography} from '@mui/material'
import CustomDrawer from '@/components/drawer/CustomDrawer'
import {LoadingButton} from "@mui/lab";
import {DatePicker} from "@mui/x-date-pickers";
import {useState} from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SnackbarUtil from "@/utils/SnackbarUtil";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {useTranslation} from "react-i18next";
import DeviceService from "@/services/dms/DeviceService";
import {useSnackbar} from "notistack";

type DevicePersonDrawerProps = DrawerProps & {
  IDs?: any,
}

const TimeSelectDrawer: React.FC<DevicePersonDrawerProps> = ({ IDs, ...props }) => {
  const today = new Date()
  const [start_date, setStartDate] = useState<Date>(new Date(today.getFullYear(), today.getMonth(), 1));
  const [end_date, setEndDate] = useState<Date>(new Date());
  const [visible, setVisible] = React.useState(false);
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const {enqueueSnackbar} = useSnackbar()

  const UploadTransactions = async () => {
  setLoading(true)
  await DeviceService.uploadTransactions({"start_date": new Date(start_date).toISOString().slice(0, 10),
    "end_date": new Date(end_date).toISOString().slice(0, 10)}).then((result) => {
    if (result.status === 205) {
       enqueueSnackbar(t("common.Records being pulled"), {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }})}
    else if (result.status < 400) {
      enqueueSnackbar(t("common.Transactions uploaded successfully"), {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      });
    }
    setVisible(false)
    setLoading(false)
  })
  };

  const toggleDrawer =
  (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
    if (IDs === undefined) {
      setVisible(open);
    }
    else {
      if (IDs.length === 0){
        SnackbarUtil.error(t("att.schedule.Select person first"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
        return;
      }
      setVisible(open);
    }};
  return (
   <div>
    <React.Fragment>
      <Grid onClick={toggleDrawer(true)}>
        <Tooltip title={t("dms.Upload Transactions")}>
          <IconButton sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
            <CloudUploadIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
    <SwipeableDrawer
      anchor={'right'}
      open={visible}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer( true)}
    >
      <Box sx={{ padding: '10px', height: '100%', width: '500px' }}>
        <Box sx={{ display: 'flex' }}>
          <Grid>
            <Typography sx={{fontSize: '28px'}}>{t("dms.Upload Transactions")}</Typography>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Grid>
            <Typography sx={{'p': '10px 20px'}}>{t("dms.uploadTransactionConfirm")}</Typography>
          </Grid>
        </Box>
          <Grid lg={3} item>
            <Box>
              {t("common.From")}
            </Box>
            <DatePicker
              label=""
              onChange={
              (newValue: any) => {
                if (newValue === null) {
                    SnackbarUtil.error(t("common.Please Select Start Date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                    setStartDate(new Date())
                  return;
                  }
                else{
                  setStartDate(newValue)
                }}
            }
              value={start_date}
              disableFuture
              inputFormat={"dd-MM-yyyy"}
              renderInput={(params: any) => <TextField sx={{width: '30vw'}} {...params} />}
            />
          </Grid>
          <Grid lg={3} item>
            <Box>
              {t("common.To")}
            </Box>
            <DatePicker
              label=""
              onChange={(newValue: any) => {
                if (newValue === null) {
                    SnackbarUtil.error(t("common.Please Select End Date"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
                    setEndDate(new Date())
                  return;
                  }
                else{
                setEndDate(newValue)}}}
              value={end_date}
              inputFormat={"dd-MM-yyyy"}
              disableFuture
              renderInput={(params: any) => <TextField sx={{width: '30vw'}} {...params} />}
            />
          </Grid>
        <Alert severity="info" sx={{ marginTop: '20px', maxWidth: "95%"}}>{t("dms.uploadTransactionTip")} </Alert>
        <Box sx={{ width:'95%', position: 'absolute', bottom: '30px' }}>
          <LoadingButton
            onClick={() =>{
              if (start_date.toString()=="Invalid Date" || end_date.toString()=="Invalid Date"){
                SnackbarUtil.error(t("common.Invalid Date input"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
            }
              else if (start_date > end_date){
                SnackbarUtil.error(t("common.Invalid Date time Range"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
              }
              else if (start_date > new Date() || end_date > new Date()){
                SnackbarUtil.error(t("common.Please Don't Select Future Time"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
              }
              else{
              UploadTransactions()}}}
            loading={isLoading}
            sx={{ width: "100%", padding: "10px", textTransform: "capitalize" }}
            variant="contained">
            {t("common.confirm")}
          </LoadingButton>
        </Box>
      </Box>
    </SwipeableDrawer>
    </React.Fragment>
  </div>  )
}
export default TimeSelectDrawer
