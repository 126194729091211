import React, { useEffect, useState, useRef } from "react";
import {Box, Grid, IconButton, Breadcrumbs, Typography, Badge, Paper, Checkbox, Tooltip, Drawer} from "@mui/material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { ReactComponent as DeviceIcon } from '@/assets/images/Device.svg';
import GenericService from "@/services/GenericService";
import { styled } from '@mui/material/styles';
import SnackbarUtil from "@/utils/SnackbarUtil";
import {DMSEndpoints} from "@/services/dms/endpoints";
import DeviceService from '@/services/dms/DeviceService';
import {LoadingButton} from "@mui/lab";
import { SaveButton } from "@/components/data-grid/CustomButton";
import { useTranslation } from "react-i18next";

type GridData = {
  data: any,
  total: number,
}

type ButtonDrawerProps = {
  IDs: any,
}

type InputDrawerProps = {
  open: boolean,
  onClose: () => void,
  onSave: (value?: any) => void,
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 70,
  lineHeight: '20px',
}));

const label = { inputProps: { 'aria-label': 'Checkbox' } };

export const DeviceListInputDrawer: React.FC<InputDrawerProps> =(props) =>{
  const { t } = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [selectedDevIDs, setSelectedDevIDs] = useState<string[]>([]);
  const UpdateDevIDs = (item:any) => {
    const itemIdx = selectedDevIDs.indexOf(item.id)
    if (itemIdx === -1) { //not selected yet
      setSelectedDevIDs(selectedDevIDs.concat(item.id))
    } else {
      setSelectedDevIDs(selectedDevIDs.slice(0, itemIdx).concat(selectedDevIDs.slice(itemIdx+1)))
    }
  }
  const fetchGridData = () => {
    GenericService.list(DMSEndpoints.DMS_DEVICE_URI, { current: 0, pageSize: 100 }).then((response) => {
      const onlineDevice = response.data.data.filter((item:any) => item.status === "1")
      setGridData(onlineDevice);
    }).catch((error) => {
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    })
  }
  useEffect(() => {
    fetchGridData()
  }, [props.open]);

  return (
    <Drawer
      anchor={'right'}
      open={props.open}
      onClose={props.onClose}
    >
      <Box sx={{ padding: '10px', height: '100%', width: '500px' }}>
        <Box sx={{ display: 'flex' }}>
          <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
            <Typography>{t("att.schedule.Person clock in Device")}</Typography>
          </Breadcrumbs>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                display: 'grid',
                gridTemplateColumns: { md: '1fr 1fr' },
                gap: 2,
              }}
            >
              {gridData.map((item: any) => (
                <Item
                  sx={{
                    display: 'inline-flex',
                    alignItems: "center",
                    backgroundColor: selectedDevIDs.includes(item.id) ? "#dfedf8" : "#fff"
                  }}
                  key={item.id} elevation={0}
                  onClick={() => UpdateDevIDs(item)}
                >
                  <Checkbox
                    checked={selectedDevIDs.includes(item.id)}
                    onChange={() => UpdateDevIDs(item)}  {...label}
                  />
                  <Grid item >
                    <Badge
                      color="success"
                      badgeContent=""
                      variant="dot"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      sx={{
                        '.MuiBadge-badge': {
                          backgroundColor: item.status === '1' ? "#20cf61" : "#ef5656",
                          bottom: "7px", right: "7px", border: `3px solid #fff`,
                          height: "15px",
                          width: "15px",
                          minWidth: "15px",
                          borderRadius: "15px",
                        }
                      }}
                    >
                      <DeviceIcon />
                    </Badge>
                  </Grid>
                  <Grid sx={{ paddingLeft: "15px", paddingRight: "10px"}}>
                    <Typography >{item.alias}</Typography>
                    <Typography>{item.sn}</Typography>
                  </Grid>
                </Item>
              ))}
            </Box>
            <Box sx={{ width:'95%', position: 'absolute', bottom: '30px' }}>
              <SaveButton
                children={t("common.confirm")}
                sx={{ width: "100%", padding: "10px", textTransform: "none" }}
                onClick={() => {
                  props.onSave(selectedDevIDs)
                  props.onClose()
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  )
}

export const DeviceListButtonDrawer: React.FC<ButtonDrawerProps> = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const [gridData, setGridData] = useState([]);
  const [selectedDevIDs, setSelectedDevIDs] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (props.IDs === undefined || props.IDs.length === 0){
          SnackbarUtil.error(t("att.schedule.Select person first"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
          return;
        }
        setVisible(open);
      };

  const fetchGridData = () => {
    setLoading(true);
    GenericService.list(DMSEndpoints.DMS_DEVICE_URI, { current: 1, pageSize: 100 }).then((response) => {
      const onlineDevice = response.data.data.filter((item:any) => item.status === "1")
      setGridData(onlineDevice);
    }).catch((error) => {
      console.log("error==", error)
    }).finally(() => {
      setLoading(false);
    });
  }

  const UpdateDevIDs = (item:any) => {
    const itemIdx = selectedDevIDs.indexOf(item.id)
    if (itemIdx === -1) { //not selected yet
      setSelectedDevIDs(selectedDevIDs.concat(item.id))
    } else {
      setSelectedDevIDs(selectedDevIDs.slice(0, itemIdx).concat(selectedDevIDs.slice(itemIdx+1)))
    }
  }

  const onConfirmSelected = () => {
    if (selectedDevIDs.length > 0) {
      setLoading(true)
      DeviceService.addPersonByDeviceList({
        dIDs: selectedDevIDs,
        sIDs: props.IDs
      }).then((response) => {
        console.log('batch addPerson: ', response)
        if (response.status <400 ){
          if (props.IDs.length == 1) {
            SnackbarUtil.success(t("att.schedule.Success synchronize the data of person", {count: props.IDs.length}),{ autoHideDuration: 3000,})
          } else if (props.IDs.length > 1) {
            SnackbarUtil.success(t("att.schedule.Success synchronize the data of people", {count: props.IDs.length}),{ autoHideDuration: 3000,})
          }}
      }).catch((error) => {
        // do something when status is 4xx.
        console.log('error addPerson: ',error)
      }).finally(() => {
        setLoading(false);
        setVisible(false);
        setSelectedDevIDs([]);
      });
    } else {
      SnackbarUtil.error('No device selected.', {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return
    }
  }

  useEffect(() => {
    fetchGridData()
  }, [visible]);

  return (
    <div>
      <React.Fragment>
        <Grid onClick={toggleDrawer(true)}>
          <Tooltip title={t("att.schedule.Sync Person")}>
            <IconButton sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
              <CloudSyncIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
        <SwipeableDrawer
          anchor={'right'}
          open={visible}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer( true)}
        >
          <Box sx={{ padding: '10px', height: '100%', width: '500px' }}>
            <Box sx={{ display: 'flex' }}>
              <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
                <Typography>{t("att.schedule.Person clock in Device")}</Typography>
              </Breadcrumbs>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    display: 'grid',
                    gridTemplateColumns: { md: '1fr 1fr' },
                    gap: 2,
                  }}
                >
                  {gridData.map((item: any) => (
                    <Item
                      sx={{
                        display: 'inline-flex',
                        alignItems: "center",
                        backgroundColor: selectedDevIDs.includes(item.id) ? "#dfedf8" : "#fff"
                      }}
                      key={item.id} elevation={0}
                      onClick={() => UpdateDevIDs(item)}
                    >
                      <Checkbox
                        checked={selectedDevIDs.includes(item.id)}
                        onChange={() => UpdateDevIDs(item)}  {...label}
                      />
                      <Grid item >
                        <Badge
                          color="success"
                          badgeContent=""
                          variant="dot"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          sx={{
                            '.MuiBadge-badge': {
                              backgroundColor: item.status === '1' ? "#20cf61" : "#ef5656",
                              bottom: "7px", right: "7px", border: `3px solid #fff`,
                              height: "15px",
                              width: "15px",
                              minWidth: "15px",
                              borderRadius: "15px",
                            }
                          }}
                        >
                          <DeviceIcon />
                        </Badge>
                      </Grid>
                      <Grid sx={{ paddingLeft: "15px", paddingRight: "10px"}}>
                        <Typography >{item.alias}</Typography>
                        <Typography>{item.sn}</Typography>
                      </Grid>
                    </Item>
                  ))}
                </Box>
                <Box sx={{ width:'95%', position: 'absolute', bottom: '30px' }}>
                  <LoadingButton
                    onClick={() => {
                      onConfirmSelected()
                    }}
                    loading={loading}
                    sx={{ width: "100%", padding: "10px", textTransform: "capitalize" }}
                    variant="contained">
                    {t("common.confirm")}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

