import request from "../../utils/Request";
import {AuthEndpoints} from "./endpoints";
import jwtDecode from "jwt-decode";

const companyList = async (params: Record<string, any>) => {
  const response = await request.authorizedAxios().get(AuthEndpoints.AUTH_USER_COMPANY, {params});
  return response.data;
}

const selectCompany = async (data: Record<string, any>) => {
  const response = await request.authorizedAxios().put(AuthEndpoints.AUTH_USER_SELECT_COMPANY, data);
  return response.data;
}

const selectAdminCompany = async () => {
  const response = await request.authorizedAxios().put(AuthEndpoints.AUTH_USER_SELECT_ADMIN_COMPANY);
  return response.data;
}

const CompanyQty = async () => {
  const response = await request.authorizedAxios().post(AuthEndpoints.AUTH_USER_ADMIN_COMPANY_QTY);
  return response.data;
}

const CompanyQtyRecord = async () => {
  const response = await request.authorizedAxios().post(AuthEndpoints.AUTH_USER_ADMIN_COMPANY_QTY_RECORD);
  return response.data;
}

const companyInfo = async () => {
  const jwtToken = localStorage.getItem("accessToken");
  if(jwtToken){
    const decodedToken = jwtDecode<Record<string, any>>(jwtToken);
    if (!decodedToken.companyId) {
      return
    }
  }else{
    return
  }

  const response = await request.authorizedAxios().get(AuthEndpoints.AUTH_USER_COMPANY_INFO_URI);
  return response.data;
}

const companyCreation = async (data: Record<string, any>) => {
  const response = await request.authorizedAxiosForm().post(AuthEndpoints.AUTH_USER_COMPANY_CREATION, data);
  return response.data;
}

const update = async ( data: Record<string, any>) => {
  const response = await request.authorizedAxiosForm().put(AuthEndpoints.AUTH_USER_COMPANY_UPDATE, data);
  return response.data;
}

const destroy = async (id: string) => {
  const response = await request.authorizedAxios().delete(`${AuthEndpoints.AUTH_USER_COMPANY}${id}/`);
  return response.data;
}

const CompanyService = {
  companyList,
  selectCompany,
  selectAdminCompany,
  CompanyQty,
  CompanyQtyRecord,
  companyInfo,
  companyCreation,
  update,
  destroy,
}
export default CompanyService;
