import React from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { SvgIconComponent, IosShare, Refresh } from "@mui/icons-material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate } from "react-router-dom";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import SearchIcon from '@mui/icons-material/Search';
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {useTranslation} from "react-i18next";

type ActionProps = {
  key: string;
  icon: SvgIconComponent;
  helpTxt?: string;
  callback: () => Promise<void>;
}
type Breadcrumb = {
  helpInfo?: string;
  breadcrumbName: string;
  path: string
}
type Info = {
  setDisplay?: any;
}

const placeholderStyle = {
  'input::-webkit-input-placeholder': { fontSize: "12px" }
}

export type ToolBarProps = {
  title: string;
  total?: number;

  info?: Info;

  refresh?: boolean;
  onRefresh?: () => () => void;

  search?: boolean;
  searchTips?: string;
  searchKeyword?: string;
  onsearchKeywordChange?: (keyword: string,) => void;

  filter?: boolean;
  actions?: ActionProps[];
  breadcrumbs?: Breadcrumb[];
  export?: boolean;
  exportCallback?: () => void;
  extraComponent?: any;
  extraComponentOnLeft?: any;
}

const CustomToolBar: React.FC<ToolBarProps> = (props) => {
  let showTotal = false;
  const {t} = useTranslation();
  if (props.total) {
    showTotal = true;
  }
  const navigate = useNavigate();
  return (
    <>
      <Box>
        {
          props.breadcrumbs?.map((item, index) => {
            const last = (index + 1) === props.breadcrumbs?.length;
            return (
              last && <CustomBreadcrumbs key={index} record={[{ label: item.breadcrumbName, helpInfo: item?.helpInfo }]} />
            )
          })
        }

      </Box>
      <Grid sx={{ marginBottom: "5px" }} className={"toolbar-container"}>
        {props.actions?.map((action, index) => {
          return (
            <Grid sx={{ marginRight: "8px" }} key={action.key} item={true}>
              <Tooltip title={action.helpTxt || ""}>
                <IconButton onClick={action.callback}
                  sx={{
                    borderRadius: '6px',
                    backgroundColor: "rgba(0, 0, 0, 0.04)"
                  }}>
                  <action.icon />
                </IconButton>
              </Tooltip>
            </Grid>
          )
        })}
        {props.info && <Grid sx={{ marginRight: "8px" }} item={true}>
          <Tooltip title={t("common.Usage Tips")}>
            <IconButton sx={{
              borderRadius: '6px',
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            }} onClick={() => {
              props.info?.setDisplay(true)
            }}>
              <QuestionMarkIcon />
            </IconButton>
          </Tooltip>
        </Grid>}
        {props.filter && <Grid sx={{ marginRight: "8px" }} item={true}>
          <Tooltip title={"Filters"}>
            <IconButton sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
              <FilterAltIcon />
            </IconButton>
          </Tooltip>
        </Grid>}
        {props.extraComponentOnLeft && <Grid sx={{ marginRight: "8px" }} item={true}>
          {props.extraComponentOnLeft}
        </Grid>}
        {props.refresh && <Grid sx={{ marginRight: "8px" }} item={true}>
          <Tooltip title={t("common.refresh")}>
            <IconButton onClick={props.onRefresh} sx={{
              borderRadius: '6px',
              backgroundColor: "rgba(0, 0, 0, 0.04)"
            }}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>}
        {props.export && <Grid sx={{ marginRight: "8px" }} item={true}>
          <Tooltip title={t("export.Export")}>
            <IconButton onClick={props.exportCallback} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
              <IosShare />
            </IconButton>
          </Tooltip>
        </Grid>}
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Grid
            container={true}
            columnSpacing={1}
            sx={{
              alignItems: "center"
            }}
          >
            <Box sx={{ width: "400px" }}>
              <TextField
                size="small"
                fullWidth
                onKeyPress={(e) => {
                  if (e.code === "Enter" || e.code === 'NumpadEnter') {
                    if (props.onRefresh) {
                      props.onRefresh()
                    }
                  }
                }}
                sx={placeholderStyle}
                type="search"
                placeholder={props?.searchTips}
                value={props.searchKeyword}
                onChange={(event) => {
                  if (props.onsearchKeywordChange) {
                    props.onsearchKeywordChange(event.target.value)
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {props.extraComponent}
          </Grid>
        </Box>
      </Grid>
    </>
  )
}
CustomToolBar.defaultProps = {
  search: true,
  refresh: true,
  filter: false,
}
export default CustomToolBar
