import React, {useRef, useState} from "react";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Box, Grid, IconButton, Stack, SvgIcon, Tooltip} from "@mui/material";
import CustomDataGrid, {DataGridRef} from "../../../components/data-grid/CustomDataGrid";
import {HREndpoints} from "../../../services/hr/endpoints";
import {Add, RemoveRedEyeOutlined, DeleteForever} from "@mui/icons-material";
import WebURL from "../../../urls";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import EditIcon from "@mui/icons-material/Edit";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Dialog from "@/components/dialog";
import SnackbarUtil from "@/utils/SnackbarUtil";
import EmployeeService from "@/services/hr/EmployeeService";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom"
import DeviceService from '@/services/dms/DeviceService';
import VisibilityIcon from "@mui/icons-material/Visibility";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {ReactComponent as IconViewDetails} from "@/assets/icons/IconViewDetails.svg";
import {ReactComponent as IconEditorList} from "@/assets/icons/IconEditorList.svg";
import {ReactComponent as IconDelete} from "@/assets/icons/IconDelete.svg";
import {ReactComponent as IconControlPanel} from "@/assets/icons/IconControlPanel.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {DMSEndpoints} from "@/services/dms/endpoints";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {AuthEndpoints} from "@/services/auth/endpoints";

const DetailActionButtons = (refresh: () => void) => (e: GridRenderCellParams) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const popupState = usePopupState({variant: 'popover', popupId: 'popupMenu'})
  const devicePersonPopupState = usePopupState({variant: 'popover', popupId: 'devicePerson'})
  const devicePersonPopupStateTrigger = bindTrigger(devicePersonPopupState)
  const dataGridRef = useRef<DataGridRef>();
  return (
    <Stack direction={"row"} spacing={5} sx={{marginRight: '100px'}}>
      <Tooltip title={t("common.Action")}>
        <IconButton {...bindTrigger(popupState)} >
          <SvgIcon component={IconControlPanel}/>
        </IconButton>
      </Tooltip>
      <Menu {...bindMenu(popupState)} >
        <MenuItem onClick={() => {
          popupState.close()
          navigate(WebURL.CUSTOMER_DEVICE_DETAIL, {state: {id: e.row.id}})
        }}>
          {t("common.Device detail")}
        </MenuItem>
        <MenuItem
          {...devicePersonPopupStateTrigger}
          onClick={(event) => {
            popupState.close()
            Dialog.confirm({
              width: "650px",
              title: t("common.Are you sure to unbind this device?"),
              helpInfo: t("common.Are you sure to unbind this device?"),
              onConfirm: () => {
                DeviceService.deviceUnbinding(e.row.id).then((response) => {
                  SnackbarUtil.success(t('common.This device has been successfully unbound !'))
                  dataGridRef.current?.refresh();
                });
              }
            });
            // navigate(WebURL.DMS_DEVICE_PERSONS, {state: {id: e.row.id}})
          }}
        >
          {t("common.Unbind device")}
        </MenuItem>
      </Menu>
    </Stack>
  )
}

const CustomerDetailDataGrid: React.FC = () => {
  const StatusField = (params: GridRenderCellParams) => {
    if (params.value === '1') {
      return (
        <strong style={{color: '#006644'}}>
          {t("dms.Online")}
        </strong>
      )
    } else {
      return (
        <strong style={{color: '#BF2600'}}>
          {t("dms.Offline")}
        </strong>
      )
    }
  }
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const location = useLocation();
  const state = location.state as { id: string };
  const [refresh, setRefresh] = useState<boolean>(true);
  const columns: GridColDef[] = [
    {field: 'id', headerName: t("common.Device ID"), minWidth: 100},
    {field: 'sn', headerName: t("common.Serial Number"), minWidth: 150, flex: 0.2},
    {field: 'alias', headerName: t('common.Device Alias'), minWidth: 150, flex: 0.1},
    {field: 'model', headerName: t('common.Device Model'), minWidth: 70, flex: 0.1},
    {field: 'category', headerName: t('common.Device Type'), minWidth: 70, flex: 0.1},
    {field: 'protocol_type', headerName: t('common.Protocol Type'), minWidth: 100, flex: 0.1},
    {field: 'firmware_ver', headerName: t('common.Firmware Version'), minWidth: 100, flex: 0.1},
    {field: 'status', renderCell: StatusField, headerName: t('dms.Status'), minWidth: 100, flex: 0.1},
    {
      field: 'actions',
      headerName: t("common.Action"),
      // headerAlign: "center",
      align: "right",
      renderHeader: (params) => {
        return (<></>)
      },
      hideSortIcons: true,
      minWidth: 150,
      flex: 0.1,
      renderCell: DetailActionButtons(() => {
        setRefresh(!refresh)
      })
    },
  ]
  return (
    <>
      <CustomBreadcrumbs record={[
        {path: WebURL.CUSTOMER_LIST, label: t("common.Customer List")},
        {label: t("common.Customer Detail")},
      ]}/>
      <CustomDataGrid
        uri={DMSEndpoints.DMS_COMPANY_DEVICE_URI}
        requestParams={{'companyId': state.id}}
        columns={columns}
        ref={dataGridRef}
        checkboxSelection={false}
        toolbar={{
          title: t("common.Customer Detail"),
          searchTips: t("common.SN"),
        }}
      />
    </>
  )
}
export default CustomerDetailDataGrid
