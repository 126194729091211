import React, { forwardRef, useState } from "react";
import { ReactComponent as LayoutImage } from "@/assets/images/UserLogin.svg"
import MainLayout from "@/pages/auth/Login/components/MainLayout";
import { Box, Button, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import WebURL from "@/urls";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CompanyService from "@/services/auth/CompanyService";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { styled } from '@mui/material/styles';
import CustomLink from "@/pages/auth/Login/components/CustomLink";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import LogoView from "@/pages/auth/Login/components/LogoView";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import FormControl from "@mui/material/FormControl";
import Uploady, { useBatchAddListener } from "@rpldy/uploady";
import UploadPreview, { PreviewItem } from "@rpldy/upload-preview";
import UploadButton from "@rpldy/upload-button";
import { asUploadButton } from "@rpldy/upload-button";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';


const OrganCreation: React.FC = () => {
  const native = useNavigate();
  const { t } = useTranslation();
  const creationSchema = object({
    name: z.string().min(1, t("common.This field is required!")),
    code: z.string().min(1, t("common.This field is required!")),
    country: z.string().min(1, t("common.This field is required!")),
  })
  type creationInput = TypeOf<typeof creationSchema>;

  const name_text = t("organ.name text tip");
  const code_text = t("organ.code text tip");
  const [loading, setLoading] = useState<boolean>(false);
  const organForm = useForm<creationInput>({
    resolver: zodResolver(creationSchema)
  });
  const { register, handleSubmit, formState: { errors } } = organForm
  const { enqueueSnackbar } = useSnackbar()
  const [imageFile, setImageFile] = useState<any>();
  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  const onCreation = async (data: Record<string, any>) => {
    try {
      setLoading(true);
      if (imageFile) {
        data['companyLogo'] = imageFile
      }
      const company = await CompanyService.companyCreation(data);
      enqueueSnackbar(t("organ.Organization Created successfully"), {
        variant: "success",
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      native(WebURL.AUTH_USER_COMPANY_SELECTION);
    } finally {
      setLoading(false)
    }
  }

  const MyPreview = (props: any) => {
    return (
      <Card sx={{ maxWidth: 100, maxHeight: 100 }}>
        <CardMedia
          component="img"
          height="100"
          image={props.url}
          alt="Company Logo"
        />
      </Card>
    )
  };

  const CustomUploadButton = asUploadButton(
    (props: any, ref: any) => {
      useBatchAddListener((batch) => {
        console.log(batch.items[0].file)
        if (batch.items[0].file.size > 3000000) {
          enqueueSnackbar(t("common.Photos size up to maximum 3 MB"), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
        setImageFile(batch.items[0].file)
      });
      return (
        <Button component="label" variant="outlined" sx={{textTransform: "none"}} {...props} />
      )
    }
  );

  return (
    <MainLayout image={<LayoutImage />}>
      <Grid sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #CAD0D9",
        marginTop: "6vh",
        height: "80vh",
        minHeight: "670px",
        width: '34vw',
        borderRadius: "20px",
        position: "relative",
      }}>
        <LogoView />
        <Grid component={"form"} style={{ width: '30vw' }} onSubmit={handleSubmit(onCreation)}>
          <Stack spacing={1}>
            <Typography variant="h6">{t("organ.Create organization")}</Typography>
            <Uploady>
              <Grid sx={{ display: "flex", flexDirection: "row", backgroundColor: "#fff" }}>
                {
                  imageFile ?
                    <UploadPreview
                      PreviewComponent={MyPreview}
                    />
                    :
                    <Box sx={{ width: 100, height: 100, backgroundColor: "gray" }}>
                      <Img alt="complex" src="/images/Images.png" />
                    </Box>
                }
                <Grid sx={{ padding: '8px' }}>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {t("organ.Photos size up to maximum 3MB")}
                  </Typography>
                 
                  <CustomUploadButton>{t("organ.Add photos")}</CustomUploadButton>
                </Grid>
              </Grid>
              {/* <UploadButton/> */}
            </Uploady>
            <ControlledTextFiled
              hookForm={organForm}
              name={'name'}
              tooltip={name_text}
              label={t("organ.Organization Name")}
              fullWidth
              required
            />
            <ControlledTextFiled
              hookForm={organForm}
              name={'code'}
              label={t("organ.Organization Code")}
              tooltip={code_text}
              fullWidth
              required
            />
            <FormControl>
              <NGInputLabel label={t("signUp.Choose your country")} required />
              <Select
                sx={{ marginTop: '24px', marginBottom: '8px' }}
                fullWidth
                defaultValue={"USA"}
                {...register("country")}
              >
                <MenuItem value={"USA"}>{t("common.America")}</MenuItem>
                <MenuItem value={"JPN"}>{t("common.Japan")}</MenuItem>
              </Select>
            </FormControl>
            <LoadingButton
              type={"submit"}
              fullWidth={true}
              variant={"contained"}
              loading={loading}
              sx={{ textTransform: "capitalize" }}
            >
              {t("organ.Save")}
            </LoadingButton>
            <Grid sx={{
              display: "inline-flex",
              justifyContent: "center",
              paddingBottom: 1,
              bottom: 0,
              position: "absolute",
              textAlign: 'center',
              width: '30vw',
            }}>
              <Typography sx={{fontSize: "14px"}}>{t("organ.Already have an organization?")}</Typography>
              <CustomLink
                label={t("organ.Select organization")}
                onClick={() => native(WebURL.AUTH_USER_COMPANY_SELECTION)}
              />
            </Grid>
          </Stack>
        </Grid>
      </Grid >
    </MainLayout >
  )
}
export default OrganCreation
