import React from "react";
import {Box} from "@mui/material";


const ProductLogo: React.FC = () => {
  return (
    <Box sx={{width: "250px", display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
      <img style={{height: '40px'}} src={"/images/logo-zkbiobiotime-ngteco.png"}/>
    </Box>
  )
};
export default ProductLogo

