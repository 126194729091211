import React, { useState } from "react";
import MainLayout from "@/pages/auth/Login/components/MainLayout";
import { ReactComponent as LayoutImage } from "@/assets/images/UserLogin.svg"
import {
  Box,
  Grid, TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { object, TypeOf, z } from "zod";
import WebURL from "@/urls";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import UserService from "@/services/auth/UserService";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import CustomLink from "@/pages/auth/Login/components/CustomLink";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import FormControl from "@/pages/auth/Login/components/SignUp";
import LogoView from "./LogoView";

const ForgotPassword: React.FC = () => {
  const native = useNavigate();
  const { t } = useTranslation();
  const signUpSchema = object({
    email: z.string().min(1, t("common.This field is required!")).email(t("common.Invalid email format")),
  });
  type signUpInput = TypeOf<typeof signUpSchema>;

  const [loading, setLoading] = useState<boolean>(false);
  const { register, handleSubmit, formState: { errors } } = useForm<signUpInput>({
    resolver: zodResolver(signUpSchema)
  })

  const { enqueueSnackbar } = useSnackbar()
  const onForgotPwd = async (data: any) => {
    try {
      setLoading(true)
      console.log("onForgotPwd-->", data);
      const user = await UserService.forgotPwd(data);
      enqueueSnackbar(t("common.success"), {
        variant: "success",
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      native(WebURL.AUTH_USER_LOGIN);
    } finally {
      setLoading(false)
    }
  }
  return (
    <MainLayout image={<LayoutImage />}>
      <Grid sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #CAD0D9",
        marginTop: "9vh",
        height: "76vh",
        minHeight: "580px",
        width: '34vw',
        borderRadius: "20px",
        position: "relative",
      }}>
        <LogoView />
        {/* <Grid container={true} rowSpacing={2} component={"form"} noValidate sx={{width: "500px"}} onSubmit={handleSubmit(onForgotPwd)}> */}
        <Grid component={"form"} sx={{ width: '30vw'}} onSubmit={handleSubmit(onForgotPwd)}>
          <Grid item xs={12} sx={{ display: "inline-flex", flexDirection: "column", marginTop: "15px" }}>
            <Typography variant="h5">{t("signUp.Forgot password?")}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "15px" }} >
            <NGInputLabel label={t("signUp.Enter the email")} required />
            <TextField
              fullWidth={true}
              required={true}
              error={!!errors.email}
              helperText={errors.email?.message || ""}
              {...register("email")}
            />
          </Grid>
          <Grid item xs={12} sx={{marginTop: "30px"}}>
            <LoadingButton
              sx={{textTransform: "none" }}
              type={"submit"}
              fullWidth={true}
              variant={"contained"}
              loading={loading}
            >
             {t("signUp.Send link")}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sx={{
            display: "inline-flex",
            justifyContent: "center",
            paddingBottom: 1,
            bottom: 0,
            position: "absolute",
            textAlign: 'center',
            width: '30vw',
          }}>
            <CustomLink
              label={t("login.Back to login")}
              onClick={() => native(WebURL.AUTH_USER_LOGIN)}
            />
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  )
}
export default ForgotPassword
