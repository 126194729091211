import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import { useSnackbar } from "notistack";
import { Grid, Paper, Stack } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import EmployeeService from "@/services/hr/EmployeeService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { CancelButton, SavingButton } from "@/components/data-grid/CustomButton";
import WebURL from "@/urls";
import EmployeeForm from "./EmployeeForm";


const EmployeeView: React.FC = () => {
  const { t } = useTranslation();
  const employeeValidate = object({
    code: z.string({ required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
        .max(14, t("common.The length of field cannot exceed digits", {number: 14})),
    firstName: z.string({ required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
        .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    lastName: z.string({ required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
        .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    departmentIdOrCode: z.string().optional(),
    dept: z.object({
      id: z.string(),
      name: z.string(),
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
  })
  type employeeInput = TypeOf<typeof employeeValidate>;
  
  const location = useLocation()
  const state = location.state as { id: string }
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = React.useState<boolean>(false)
  const title = t("common.Edit Person") 

  useEffect(() => {
    if (state.id) {
      EmployeeService.retrieve(state.id || "").then((res) => {
        const emp = res.data.data
        employeeForm.reset({
          code: emp?.employeeCode,
          firstName: emp?.firstName,
          lastName: emp?.lastName,
          departmentIdOrCode: emp?.departmentIdOrCode,
          dept: {id: emp?.departmentIdOrCode, name: emp?.department_name}
        })
      })
    }
  }, [])

  const employeeForm= useForm<employeeInput>({
    resolver: zodResolver(employeeValidate),
    defaultValues: {
      code: '',
      firstName: '',
      lastName: '',
      dept: {id: '', name: ''},
      departmentIdOrCode: ''
    }
  });

  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      employeeForm.unregister("code")
      const values = employeeForm.getValues()
      values.departmentIdOrCode = values.dept.id
      // update employee
      const result = await EmployeeService.update(state.id || "", values);
      if (result.status < 400) {
        enqueueSnackbar(t("common.Person updated successfully."), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        navigate(WebURL.HR_EMPLOYEE);
      }
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.HR_EMPLOYEE, label: t("common.Person")},
        { label: title, helpInfo: t("hr.employee.Person ID not support modify")},
      ]} />
      <EmployeeForm employeeForm={employeeForm} isEditAction={true}/>
      <Grid style={{'padding':'25px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton />
          <SavingButton loading={saving} onClick={employeeForm.handleSubmit(onSubmit)}/>
        </Stack>
      </Grid>
    </>
  )
}
export default EmployeeView;
