import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";
import { Grid, Paper, Stack } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import EmployeeService from "@/services/hr/EmployeeService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { CancelButton } from "@/components/data-grid/CustomButton";
import EmployeeForm from "../../Employee/components/EmployeeForm";
import WebURL from "@/urls";


const ResignedEmployeeView: React.FC = () => {
  const { t } = useTranslation();
  const employeeValidate = object({
    code: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    departmentIdOrCode: z.string().optional(),
    dept: z.object({
      id: z.string(),
      name: z.string(),
    }),
  })
  type employeeInput = TypeOf<typeof employeeValidate>;
  const employeeForm= useForm<employeeInput>({
    resolver: zodResolver(employeeValidate),
    defaultValues: {
      dept: {id: '', name: ''},
    }
  });

  const location = useLocation()
  const state = location.state as { id: string }

  useEffect(() => {
    if (state.id) {
      EmployeeService.retrieve(state.id || "").then((res) => {
        const emp = res.data.data
        employeeForm.reset({
          code: emp?.employeeCode,
          firstName: emp?.firstName,
          lastName: emp?.lastName,
          departmentIdOrCode: emp?.departmentIdOrCode,
          dept: {id: emp?.departmentIdOrCode, name: emp?.department_name}
        })
      })
    }
  }, [])

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.HR_RESIGNATION, label: t("hr.resignation.Resignation")},
        { label:  t("hr.resignation.View Person") },
      ]} />
      <EmployeeForm employeeForm={employeeForm} isViewAction={true}/>
      <Grid style={{'padding':'25px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton />
        </Stack>
      </Grid>
    </>
  )
}
export default ResignedEmployeeView;
