import React, { useState, useEffect, useRef } from "react";
import { GridColDef, GridValueGetterParams, GridRenderCellParams, GridFilterModel, GridFilterOperator, GridFilterItem, GridFilterInputValueProps } from "@mui/x-data-grid";
import WebURL from "../../../../../urls";
import {Box, Dialog as MuiDialog, DialogContent, DialogTitle, Grid, IconButton, TextField} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import CustomDataGrid, { DataGridRef } from "@/components/data-grid/CustomDataGrid";
import { ATTEndpoints } from "@/services/att/endpoints";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";


type FilterType = {
  date_range: [any, any],
}

const SUBMIT_FILTER_STROKE_TIME = 500;

const punchRecordStyle = {
  '& .MuiPaper-root': { width: '60vw', top: '-20vh' },
  '.MuiDialog-paper': { maxWidth: '100vw' },
};

function InputNumberInterval(props: GridFilterInputValueProps) {
  const { t } = useTranslation();
  const { item, applyValue } = props;

  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<[Date, Date]>([item.value[0], item.value[1]]);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  // useEffect(() => {
  //   const itemValue = item.value ?? [undefined, undefined];
  //   const today = new Date()
  //   itemValue[0]=new Date(today.getFullYear(), today.getMonth(), 1)
  //   setFilterValueState(itemValue);
  // }, [item.value]);

  const updateFilterValue = (lowerBound: Date, upperBounding: Date) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBounding]);

    filterTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: [lowerBound, upperBounding] });
    }, SUBMIT_FILTER_STROKE_TIME)
  };

  const handleUpperFilterChange = (event: any) => {
    const newUpperBound = event;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event: any) => {
    const newLowerBound = event;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
      }}
    >
      <DatePicker
        label={t("common.Start Date")}
        value={filterValueState[0] || new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
        maxDate={filterValueState[1]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleLowerFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
      <DatePicker
        label={t("common.End Date")}
        value={filterValueState[1]}
        minDate={filterValueState[0]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleUpperFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
    </Box>
  )
}


const Timecard: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [info, setInfo] = useState<boolean>(false);
  const today = new Date()
  const firstDay = new Date()
  firstDay.setDate(1)

  const attDateOperators: GridFilterOperator[] = [
    {
      label: t("common.Between"),
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem, column) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        return ({ value }) => {
          return true;
        };
      },
      InputComponent: InputNumberInterval
    },
  ];

  useEffect(() => {
    dataGridRef.current?.refresh();
  }, [i18n.language])

  const defaultColumns: GridColDef[] = [
    { field: 'employee_name', headerName: t("hr.employee.Person Name"), width: 200, filterable: false},
    { field: 'employee_code', headerName: t("hr.employee.Person ID"), width: 130, filterable: false },
    { field: 'att_date', headerName: t("common.Date"), type: "date", width: 130, filterOperators: attDateOperators, },
    { field: 'timesheet_name', headerName: t("common.Timesheet"), filterable: false },
  ]

  const navigate = useNavigate();
  const dataGridRef = useRef<DataGridRef>();
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    date_range: [
      firstDay.toISOString().slice(0, 10),
      today.toISOString().slice(0, 10)
    ]
  });

  const [gridColumn, setColumns] = useState<GridColDef[]>(() => {
    return [...defaultColumns];
  });
  const [filterDate, setFilterDate] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        columnField: 'att_date',
        value: [firstDay.toISOString().slice(0, 10), today.toISOString().slice(0, 10)],
        operatorValue: "between",
      }
    ]
  })

  const PairingField = (params: GridRenderCellParams) => {
    let pairing = "";
    switch (params.value) {
      case "first_last":
        pairing = t("att.timeSheet.First And Last")
        break;
      case "odd_even":
        pairing = t("att.timeSheet.Even and Odd")
        break;
      default:
        break;
    }
    return (
      <Grid>
        {pairing}
      </Grid>
    )
  }

  const ExtendColumns = [
    { field: 'clock_in', headerName: t("common.Clock In"), filterable: false },
    { field: 'clock_out', headerName: t("common.Clock Out"), filterable: false },
    { field: 'period_hrs', headerName: t("common.Work Time(h)"), filterable: false, width: 130 },
    { field: 'total', headerName: t("common.Total Time(h)"), filterable: false, width: 130 },
    { field: 'paid_break', headerName: t("common.Total Break Time(h)"), filterable: false, width: 130 },
    { field: 'pairing', headerName: t("common.Statistic Rule Mode"), renderCell: PairingField, filterable: false, width: 130 }
  ]

  const processResponse = (data: any) => {
    const calcColumns: GridColDef[] = []
    let maxColumnsCount = 0;
    const result: any = [];
    if (data.data.length === 0) {
      calcColumns.push(...defaultColumns, ...ExtendColumns);
    } else {
      calcColumns.push(...defaultColumns);
      data.data.forEach((element: any) => {
        // get the largest column count
        const len = element.paired.length;
        maxColumnsCount = Math.max(maxColumnsCount, len);
        // uppack column data
        let paired_objs = {}
        element.paired.forEach((paired_obj: any) => {
          paired_objs = { ...paired_objs, ...paired_obj };
        });
        console.log("paired_objs", paired_objs);
        result.push({ ...element, ...paired_objs })
      });
      // add dynamic column
      if (maxColumnsCount) {
        for (let i = 0; i < maxColumnsCount; i++) {
          calcColumns.push({ field: `clock_in${i}`, headerName: t("common.Clock In"), filterable: false })
          calcColumns.push({ field: `clock_out${i}`, headerName: t("common.Clock Out"), filterable: false })
          calcColumns.push({ field: `period_hrs${i}`, headerName: t("common.Work Time(h)"), filterable: false, width: 130 })
        }
        calcColumns.push({
          field: 'paid_break',
          width: 130,
          headerName: t("common.Total Break Time(h)"),
          valueGetter: (params: GridValueGetterParams) => params.row.payload.paid_break || "",
          filterable: false
        })
        calcColumns.push({
          field: 'total',
          width: 130,
          headerName: t("common.Total Time(h)"),
          valueGetter: (params: GridValueGetterParams) => params.row.payload.total || "",
          filterable: false
        })
        calcColumns.push({
          field: 'pairing',
          headerName: t("common.Statistic Rule Mode"),
          minWidth: 160,
          renderCell: PairingField,
          filterable: false
        });
      } else {
        calcColumns.push(...ExtendColumns);
      }
    }
    setColumns(calcColumns);
    return { data: result, total: data.total };
  }

  const toolbar = {
    title: t("common.Timecard Management"),
    searchTips: t("common.Search by Person ID, Person Name, Timesheet Name"),
    search: true,
    export: true,
    info: {setDisplay: setInfo,},
    exportCallback: () => navigate(WebURL.ATT_TIMECARD_TIMECARD_EXPORT),
    breadcrumbs: [
      { breadcrumbName: t("common.Timecard Management"), path: WebURL.ATT_TIMECARD_TIMECARD }
    ]
  }

  const InfoDialog = () => {
    return (
      <MuiDialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={punchRecordStyle}
      >
        <DialogTitle>
          {t("common.Usage Tips")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ lineHeight: '25px', color: 'gray' }}>
          <Box sx={{p:1}}> 1. {t("dms.TimecardDataTip")}</Box>
          <Box sx={{p:1}}> 2. {t("dms.TimecardDateTip")}</Box>
        </DialogContent>
      </MuiDialog>
    )
  }
  return (
    <>
    <Box
      sx={{
        '& .MuiDataGrid-row-height-no-limit': {
          maxHeight: '180px!important'
        },
      }}
    >
    <CustomDataGrid
      columns={gridColumn}
      ref={dataGridRef}
      uri={ATTEndpoints.ATT_TIMECARD_URI}
      requestParams={filterParams}
      filterModel={filterDate}
      onFilterModelChange={(model) => {
        console.log(model)
        if (typeof(model.items[0].value[0]) == 'object') {
          model.items[0].value[0] = model.items[0].value[0].toISOString().slice(0, 10)
        }
        if (typeof(model.items[0].value[1]) == 'object') {
          model.items[0].value[1] = model.items[0].value[1].toISOString().slice(0, 10)
        }
        setFilterDate(model)
        setFilterParams({date_range: [
          model.items[0].value[0],
          model.items[0].value[1]
        ]})
        dataGridRef.current?.refresh(true);
      }}
      toolbar={toolbar}
      processResponse={processResponse}
      localeText={{
        filterPanelColumns: t("common.Columns"),
        filterPanelOperators: t("common.Operators"),
      }}
    />
     </Box>
    <InfoDialog />
    </>
  )
}
export default Timecard
