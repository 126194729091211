import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate, useLocation} from "react-router-dom"
import { Box, Grid, TextField, Stack, Button, Container, Paper} from "@mui/material";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {LocationEndpoints} from "@/services/location/endpoints"
import GenericService from "@/services/GenericService";
import {object, string, TypeOf, z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import { ngteco_timezones } from "@/components/resource/timezone";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";


const SiteView: React.FC = () => {
  const { t } = useTranslation();
  const siteValidate = object({
    name: z.string({ required_error: t("common.This field is required!") }).min(1, t("common.This field is required!")),
    timezone: z.object({
      value: z.string(),
      name: string()
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} )
  })
  type siteInput = TypeOf<typeof siteValidate>;

  const location = useLocation()
  const state = location.state as { id: string }
  const navigate = useNavigate();
  const [saving, setSaving] = React.useState<boolean>(false)
  const siteForm = useForm<siteInput>({
    resolver: zodResolver(siteValidate),
    defaultValues: {
      timezone: {value: "-10:00", name: t("location.zone.Hawaii")}
    }
  })
  const timezoneList = ngteco_timezones();
  React.useEffect(() => {
    GenericService.retrieve(LocationEndpoints.SITE_URI, state.id || "").then((res) => {
      const site = res.data
      const timezone = timezoneList.find(option => option.value === site.address?.timeZone)
      siteForm.reset({
        name: site.name,
        timezone
      })
    })
  }, [])


  const onSave = async () => {
    setSaving(true)
    try {
      const values = siteForm.getValues()
      const newSite = {
        name: values.name,
        address: {
          timeZone: values.timezone.value,
          city: "default",
          addressLine1: "default",
        }
      }
      const response = await GenericService.update(LocationEndpoints.SITE_URI, state.id || '', newSite)
      if (response.status < 400)
        SnackbarUtil.success(t('common.Site updated successfully.'))
      else
        SnackbarUtil.error(response.message)
      navigate(-1)
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.LOCATION_SITE, label: t("common.Site") },
        { label: t("common.Edit Site")},
      ]} />
      <Paper sx={{margin: '20px 0', padding: '20px'}}>
        <Grid
          container
          spacing={2}
          component="form"
        >
          <Grid item xs={5} >
            <img width={"100%"} src={'/images/location-site-view-rectangle.png'}/>
          </Grid>
          <Grid item xs={7}>
            <ControlledTextFiled required fullWidth label={t("common.Site Name")} name={"name"} hookForm={siteForm} />
            <ControlAutoComplete required label={t("common.Site Time Zone")} name={'timezone'} form={siteForm} options={timezoneList}
             groupBy={(option)=>option.group} isOptionEqualToValue={(option, value) => option.value === value.value}/>
          </Grid>

        </Grid>
      </Paper>
      <Grid style={{'padding':'30px'}}>
        <Stack direction={"row"} spacing={2} sx={{display: "flex", justifyContent: "flex-end"}}>
          <CancelButton/>
          <SavingButton loading={saving} onClick={siteForm.handleSubmit(onSave)}/>
        </Stack>
      </Grid>
    </>
  )
}

export default SiteView;
