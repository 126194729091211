import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { KeyboardArrowRight, Logout } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import LanguageMenuItem from "@/parts/Header/components/LanguageMenuItem";
import SupportMenuItem from "@/parts/Header/components/SupportMenuItem";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import {createTheme} from "@mui/material";
import { useTranslation } from "react-i18next";


type MenuItemProps = {
  hidePopup?: () => void;
}

const ProfileItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const viewProfile = () => {
    navigate("/user/profile");
  }
  return (
    <Box>
      <MenuItem onClick={async () => {
        await props.hidePopup?.()
        setOpen(true)
        viewProfile()
      }}>
        <ListItemText>{t("common.Profile")}</ListItemText>
      </MenuItem>
    </Box>
  )
}

const About: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const viewProfile = () => {
    navigate("/user/about");
  }
  return (
    <Box>
      <MenuItem onClick={async () => {
        await props.hidePopup?.()
        setOpen(true)
        viewProfile()
      }}>
        <ListItemText>{t("common.About")}</ListItemText>
      </MenuItem>
    </Box>
  )
}


const ResetPasswordItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const viewProfile = () => {
    navigate("/user/reset_password");
  }
  return (
    <Box>
      <MenuItem onClick={async () => {
        await props.hidePopup?.()
        setOpen(true)
        viewProfile()
      }}>
        <ListItemText>{t("common.Reset Password")}</ListItemText>
      </MenuItem>
    </Box>
  )
}

const UserProfile: React.FC = () => {
  const user = useSelector((state: any) => state.app.user);
  console.log({user})
  const { t } = useTranslation();
  const popupState = usePopupState({ variant: "popover", popupId: "userProfile" });
  return (
    <Box>
      <Tooltip title={t("common.Open Setting")}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "0 16px" }}
          {...bindTrigger(popupState)}
        >
          <IconButton sx={{ p: 0 }}>
            <AvatarIcon name={user?.firstName ? user.firstName : "None"} profilePhoto={user?.imagePreSignedURL ? user.imagePreSignedURL : "None"}/>
          </IconButton>
          <Typography sx={{ cursor: "pointer",  fontSize: "18px" }} padding={'8px'}>
            {user?.firstName}
          </Typography>
          <IconButton sx={{ p: 0 }}>
            <KeyboardArrowRight
              sx={{
                fontSize: { xs: '14px', lg: '28px' },
                margin: { xs: '2px 4px 4px 4px', lg: '2px 4px 4px 4px' }
              }}
            />
          </IconButton>
        </Grid>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted={true}
        {...bindMenu(popupState)}
      >
        <LanguageMenuItem hidePopup={async () => popupState.close()}/>
        <SupportMenuItem hidePopup={async () => popupState.close()}/>
        <ProfileItem hidePopup={async () => popupState.close()} />
        <About hidePopup={async () => popupState.close()} />
        <ResetPasswordItem hidePopup={async () => popupState.close()} />
      </Menu>
    </Box>
  )
}
export default UserProfile
