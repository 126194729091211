import { Box, Breadcrumbs, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import EmployeeAttendance from "./components/EmployeeAttendance";
import DeviceList from "./components/DeviceList";
import Organization from '@/assets/images/Organization.png';
import { useSelector } from "react-redux";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import DeviceQtyStatus from "./components/DeviceStatus";
import CompanyQtyStatus from "./components/CompanyQty";
import QtyChart from "@/pages/dashboard/components/QtyChart";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const company = useSelector((state: any) => state.app.company);
  return (
    <Box>
      <Grid className={"toolbar-container"}>
        <CustomBreadcrumbs record={[{ label: t("common.Dashboard") }]} />
      </Grid>
      <Grid container={true} rowSpacing={5}>
        <Grid item={true} xs={12}  container={true} rowSpacing={3}>
          <Grid item={true} xs={4}>
            <Grid >
                <DeviceQtyStatus category={"day"} first_title={t("Device Qty")} title={t("Online Devices")}/>
            </Grid>
          </Grid>
          <Grid item={true} xs={8}>
            <Grid>
              <QtyChart title={t("Online Devices Qty")} model={"device"}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} container={true} rowSpacing={3}>
          <Grid item={true} xs={4}>
            <Grid >
                <CompanyQtyStatus category={"day"} first_title={t("End Customer Qty")} title={t("Organization")}/>
            </Grid>
          </Grid>
          <Grid item={true} xs={8}>
            <Grid>
              <QtyChart title={t("End Customer Qty")} model={"company"}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Box>
  )
}
export default Dashboard
