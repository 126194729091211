import React, {useEffect} from "react";
import {AppBar, Box, Container, Toolbar, MenuItem, Tooltip} from "@mui/material";
import CompanyProfile from "./components/CompanyProfile";
import UserProfile from "./components/UserProfile";
import ProductLogo from "./components/ProductLogo";
import {useDispatch} from "react-redux";
import {getInitialState} from "../../store/slices/App";
import {useNavigate} from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import Dialog from "@/components/dialog";
import { useTranslation } from "react-i18next";

type MenuItemProps = {
  hidePopup?: () => void;
}

const LogoutMenuItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onLogout = () => {

    Dialog.confirm({
      title: t("common.Are you sure to logout"),
      onConfirm: () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate("/user/login");
      }
    });
  }
  return (
    <Tooltip title={t("common.Logout")}>
      <MenuItem onClick={onLogout}>
        <LogoutIcon fontSize='small' sx={{color: "#304669"}}/>
      </MenuItem>
    </Tooltip>
  )
}


const Header: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getInitialState());
  }, [])
  return (
    <AppBar sx={{backgroundColor: '#fff', height: "70px"}} position={"static"}>
      <Container maxWidth='xl' style={{ height: "100%", padding: '0px 12px', margin: '0px', minWidth: '100%'}}>
        <Toolbar sx={{height: "100%"}} disableGutters>
          <ProductLogo/>
          <Box sx={{flexGrow: 1}}/>
          <Box display='flex'>
            <UserProfile/>
            <CompanyProfile/>
            <LogoutMenuItem/>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
