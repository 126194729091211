import React, { useRef } from "react";
import {GridColDef} from "@mui/x-data-grid";
import {Box, Grid, Stack} from "@mui/material";
import CustomDataGrid, { DataGridRef } from "../../../components/data-grid/CustomDataGrid";
import {HREndpoints} from "../../../services/hr/endpoints";
import { DeleteForever } from "@mui/icons-material";
import UndoIcon from '@mui/icons-material/Undo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WebURL from "../../../urls";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import Dialog from "@/components/dialog";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom"
import ExtraToolBar from './components/HelpInformation'
import EmployeeService from "@/services/hr/EmployeeService";
import ResignationService from "@/services/hr/ResignationService"


const ResignationDataGrid: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const columns: GridColDef[] = [
    {
      field: 'firstName', headerName: t("common.Person Name"), minWidth: 260, renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <AvatarIcon name={params.row.firstName} />
            <Box style={{padding: '0 8px'}}>{params.row.firstName} {params.row.lastName}</Box>
          </Grid>
        )
      }
    },
    {field: 'employeeCode', headerName: t("common.Person ID"), type: "string", minWidth: 200},
    {field: 'department_name', headerName: t("common.Department"), type: "string", minWidth: 180},
    {
      field: 'actions', headerName: t("common.action"), headerAlign: 'center', align: 'center',  flex: 150,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <CustomRowButton insideIcon={UndoIcon} buttonProps={{
              key: "Resume",
              onClick: async () => {
                Dialog.confirm({
                  width: "650px",
                  title: t("hr.resignation.You sure to resume?", {value: e.row.firstName + " " + e.row.lastName}),
                  helpInfo: t("hr.resignation.Resume Tip"),
                  onConfirm: () => {
                    ResignationService.resume(e.row.id).then((response)=>{
                      console.log(11, response)
                      SnackbarUtil.success(t('common.success'))
                      dataGridRef.current?.refresh();
                    });
                  }});
              }
            }}/>
            <CustomRowButton insideIcon={VisibilityIcon} buttonProps={{
              key: "view",
              onClick: () => {
                navigate(WebURL.HR_RESIGNATION_VIEW, {state: {id: e.row.id}});
              }
            }}/>
            <CustomRowButton insideIcon={DeleteForever} buttonProps={{
              key: "delete",
              onClick: async () => {
                Dialog.confirm({
                  title: t("common.You sure to delete?", {value: e.row.firstName + " " + e.row.lastName}),
                  onConfirm:  () => {
                    EmployeeService.destroy(e.row.id).then((response) =>{
                     SnackbarUtil.success(t('common.success'))
                     dataGridRef.current?.refresh()
                    })
                  }});
                }}}/>
          </Stack>
        )
      }
    }
  ]
  return (
    <CustomDataGrid
      uri={HREndpoints.HR_RESIGNATNION_URI}
      columns={columns}
      ref={dataGridRef}
      checkboxSelection={false}
      toolbar={{
        title: t("hr.resignation.Resignation"),
        searchTips: t("common.Search by Person First Name"),
        breadcrumbs: [
          {breadcrumbName: t("hr.resignation.Resignation"), path: WebURL.HR_EMPLOYEE}
        ],
        extraComponentOnLeft: <ExtraToolBar/>
      }}

    />
  )
}
export default ResignationDataGrid
