import React, {ForwardedRef, useEffect, useImperativeHandle, useState} from "react";
import {DataGrid, DataGridProps} from "@mui/x-data-grid";
import GenericService from "../../services/GenericService";
import CustomToolBar, {ToolBarProps} from "./CustomToolBar";
import CustomNoRowsGrid from "./CustomNoRowsGrid";
import {Box} from "@mui/material";
import { useTranslation } from "react-i18next";

export function calcTextWidth(text: any, fontSize: any = "0.875rem", fontFamily: any = "Roboto",
  fontWeight: any = "500", letterSpacing: any = "0.01071em",) {
  const span = document.createElement("span")
  let width = 0
  span.style.visibility = "hidden"
  span.style.fontSize = fontSize
  span.style.fontFamily = fontFamily
  span.style.fontWeight = fontWeight
  span.style.letterSpacing = letterSpacing
  span.style.display = "inline-block"
  document.body.appendChild(span)
  span.textContent = text
  width = parseInt(window.getComputedStyle(span).width)
  document.getElementsByTagName('body')[0].removeChild(span)
  return width
}

export function updateColumnsDefaultProps(sourceColumns: any) {
  for (const i in sourceColumns) {
    if (!('sortable' in sourceColumns[i])) {
      sourceColumns[i].sortable = false
    }
    if (!('flex' in sourceColumns[i])) {
      sourceColumns[i].flex = 1
    }
    if (!('minWidth' in sourceColumns[i])) {
      const headerLabel = sourceColumns[i].headerName ? sourceColumns[i].headerName : sourceColumns[i].field
      sourceColumns[i].minWidth = calcTextWidth(headerLabel) + 38
    }
  }
}

const defaultProps = {
  processResponse: (data: any) => data
}

type CustomDataGridProp = Omit<DataGridProps, 'rows'> & {
  title?: string;
  uri: string;
  toolbar?: ToolBarProps,
  requestParams?: Record<any, any>
  onDataChagne?: (value?: any) => void;
} & Partial<typeof defaultProps>

const customDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': {backgroundColor: '#F0F1F4!important', color: '#304669!important'},
  '.MuiDataGrid-columnHeader:focus-within': {outline: 'none'},
  '.MuiDataGrid-cell': {borderBottom: '1px solid #E7EBF0', color: '#304669'},
  '.MuiDataGrid-cell:focus-within': {outline: 'none!important', color: '#0084d0!important'},
  '.MuiDataGrid-columnSeparator': {display: 'none !important'},
  '.MuiSvgIcon-root': {backgroundColor: 'transparent'},
  '.MuiDataGrid-root': {padding: '8px', borderRadius: '4px'},
  '.MuiDataGrid-row': {maxHeight: '48px', minHeight: '48px!important'},
  '.MuiCheckbox-root': {color: '#D1D1D1'},
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px'
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {backgroundColor: '#FFFF'},
  '.MuiDataGrid-footerContainer': {display: 'block', width: '100%', border: '0px'},
  '.MuiTablePagination-spacer': {display: 'none'},
  '.MuiTablePagination-actions': {position: 'absolute', right: '0px', backgroundColor: '#FFF'},
  '.MuiTablePagination-displayedRows': {position: 'absolute', right: '90px'},
  // '.MuiDataGrid-virtualScroller': {overflow: 'hidden'},
  '.MuiTablePagination-select': {backgroundColor: '#F0F1F4', borderRadius: '4px!important'},
  '.MuiTablePagination-toolbar': {paddingLeft: '0px'},
  '.MuiIconButton-root.Mui-disabled': {backgroundColor: '#F0F1F4', borderRadius: '0px'},
  // '.MuiIconButton-root': {borderRadius: '0px'},
  '.Mui-checked': {color: 'rgb(54, 201, 109)!important'},
};
type GridData = {
  data: any;
  total: number;
}
export type DataGridRef = {
  refresh: (resetPage?: boolean) => void
}
const CustomDataGrid = (props: CustomDataGridProp, ref: ForwardedRef<DataGridRef|undefined>) => {
  const {t} = useTranslation();
  const [gridData, setGridData] = useState<GridData>({data: [], total: 0});
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  // updateColumnsDefaultProps(props.columns)

  const fetchGridData = () => {
    setLoading(true)
    GenericService.list(
      props.uri,
      {
        current: (page + 1),
        pageSize: pageSize,
        ...props.requestParams,
        keyword: searchKeyword,
      }
    ).then((response) => {
      const data = props?.processResponse === undefined
      ? response.data
      : props?.processResponse(response.data);
      setGridData(data);
      props.onDataChagne && props.onDataChagne(data?.data);
    }).catch((error) => {
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const handelRefresh = (resetPage = false) => {
    if (resetPage) {
      setPage(0);
      setSearchKeyword("")
    }
    setRefresh(refresh + 1)
  }

  useImperativeHandle(ref, () => ({
    refresh: handelRefresh
  }))
  useEffect(() => {
    fetchGridData()
  }, [page, pageSize, refresh]);
  return (
    <Box sx={{width: "100%"}}>
      <DataGrid
        loading={isLoading}
        pagination={true}
        paginationMode={"server"}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 15, 20, 50]}
        page={page}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        rows={gridData?.data}
        rowCount={gridData?.total ? gridData?.total : 0}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        disableColumnMenu={true}
        style={{border: 'none'}}
        components={{
          Toolbar: CustomToolBar,
          NoRowsOverlay: CustomNoRowsGrid
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: t("common.datagrid.paginate"),
          },
          toolbar: {
            total: gridData?.total,
            onRefresh: handelRefresh,

            searchKeyword: searchKeyword,
            onsearchKeywordChange: (value: string) => {
              setSearchKeyword(value)
            },
            ...props.toolbar
          }
        }}
        sx={customDataGridStyle}
        {...props}
      />
    </Box>
  )
}

export default React.forwardRef(CustomDataGrid)
