import request from "../../utils/Request";
import {DMSEndpoints} from "./endpoints";
import qs from "qs";

const list = async (params?: Record<string, any>) => {
  const response = await request.authorizedAxios().get(`${DMSEndpoints.DMS_DEVICE_URI}`, {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response.data;
}

const rebootDevice = async (id: string, options?: { [key: string]: any }) => {
  const response = await request.authorizedAxios().post(`${DMSEndpoints.DMS_DEVICE_URI}${id}/reboot/`, {});
  return response.data;
}

const syncDateTimeDevice = async (id: string, options?: { [key: string]: any }) => {
  const response = await request.authorizedAxios().post(`${DMSEndpoints.DMS_DEVICE_URI}${id}/sync_date_time/`, {});
  return response.data;
}

const syncTimezoneBySite = async (options: { [key: string]: any }) => {
  const response = await request.authorizedAxios().put(`${DMSEndpoints.DMS_DEVICE_URI}sync_timezone_by_site/`, options);
  return response.data;
}

const enableDevice = async (id: string, options?: { [key: string]: any }) => {
  const response = await request.authorizedAxios().put(`${DMSEndpoints.DMS_DEVICE_URI}${id}/enable/`, {});
  return response.data;
}

const disableDevice = async (id: string, options?: { [key: string]: any }) => {
  const response = await request.authorizedAxios().put(`${DMSEndpoints.DMS_DEVICE_URI}${id}/disable/`, {});
  return response.data;
}

const addPerson = async (id: string, options?: { [key: string]: any }) => {
  const response = await request.authorizedAxios().post(`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${id}/add_person/`, options);
  return response.data;
}

const addPersonByDeviceList = async (data: { [key: string]: any }) => {
  const response = await request.authorizedAxios().post(`${DMSEndpoints.DMS_DEVICE_PERSON_URI}sync_emps_by_device/`, data);
  return response.data;
}

const deviceVerify = async (sn: string) => {
  const response = await request.authorizedAxios().post(`${DMSEndpoints.DMS_DEVICE_URI}verify/`, {sn});
  return response.data;
}

const deviceBinding = async (data: { [key: string]: any }) => {
  const response = await request.authorizedAxios().put(`${DMSEndpoints.DMS_DEVICE_URI}binding/`, data);
  return response.data;
}

const deviceUnbinding = async (id: string) => {
  const response = await request.authorizedAxios().delete(`${DMSEndpoints.DMS_DEVICE_URI}${id}/unbinding/`);
  return response.data;
}

const uploadTransactions = async (data?: { [key: string]: any }) => {
  return request.authorizedAxios().post(`${DMSEndpoints.DMS_DEVICE_URI}transactions/`, data);
}

const update = async (id: string, data: { [key: string]: any }) => {
  const response = await request.authorizedAxios().put(`${DMSEndpoints.DMS_DEVICE_URI}${id}/`, data);
  return response.data;
}

const destroy = async (id: string) => {
  const response = await request.authorizedAxios().delete(`${DMSEndpoints.DMS_DEVICE_URI}${id}/`);
  return response.data;
}

const deleteEmpInDevice = async (data: { [key: string]: any }) => {
  const response = await request.authorizedAxios().post(`${DMSEndpoints.DMS_DEVICE_PERSON_URI}delete_person/`, data);
  return response.data;
};

const empInDevice = async (id: string) => {
  const response = await request.authorizedAxios().get(`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${id}/person_list/`);
  return response.data;
};

const remoteRegistration = async (id: string, data: { [key: string]: any }) => {
  return request.authorizedAxios().post(`${DMSEndpoints.DMS_DEVICE_URI}${id}/registration/`, data);
}

const deleteDevicePerson = async (id: string, config: { [key: string]: any }) => {
  return request.authorizedAxios().delete(`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${id}/delete_device_person/`, config);
}

const GetWhiteDeviceInfo = async (data: { [key: string]: any }) => {
  return request.authorizedAxios().post(DMSEndpoints.DMS_ADMIN_DEVICE_URI, data);
}

const GetWhiteDeviceQty = async () => {
  return request.authorizedAxios().post(DMSEndpoints.DMS_ADMIN_DEVICE_QTY_URI);
}

const GetWhiteDeviceQtyRecord = async () => {
  return request.authorizedAxios().post(DMSEndpoints.DMS_ADMIN_DEVICE_QTY_RECORD_URI);
}

const DeviceService = {
  list,
  rebootDevice,
  syncDateTimeDevice,
  syncTimezoneBySite,
  enableDevice,
  disableDevice,
  addPerson,
  addPersonByDeviceList,
  deviceVerify,
  deviceBinding,
  uploadTransactions,
  update,
  destroy,
  deleteEmpInDevice,
  empInDevice,
  remoteRegistration,
  deleteDevicePerson,
  GetWhiteDeviceInfo,
  GetWhiteDeviceQty,
  deviceUnbinding,
  GetWhiteDeviceQtyRecord
}
export default DeviceService
