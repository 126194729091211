import React from "react";
import {Box, Grid} from "@mui/material";
import Paper from '@mui/material/Paper';
import LanguageSelect from "@/pages/auth/Login/components/MainLayout/LanguageSelect";

type LayoutProps = {
  image?: React.ReactNode;
  children: React.ReactNode;
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    margin: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "cover",
    backgroundPositionY: -96,
    backgroundPositionX: 16
  },
  option: {
    display: 'flex',

  }
}
const MainLayout: React.FC<LayoutProps> = (props) => {
  return (
    <Grid
      container
      sx={styles.container}
    >
      <Grid item lg={4} xs={9} />
      <Grid item lg={8} xs={9} >
        <LanguageSelect />
        <Paper sx={{backgroundColor: "rgba(255,255,255,0.7)", width: '34vw', borderRadius: "20px"}} elevation={0}>
          <Grid>
            {props.children}
          </Grid>
        </Paper>
      </Grid>
   </Grid>
  )
}
export default MainLayout
