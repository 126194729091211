import { Box, Button, Grid, Breadcrumbs, Typography, TextField, Paper } from "@mui/material";
import React from "react";
import { ReactComponent as DefaultOrganization } from '@/assets/images/DefaultOrganization.svg';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
  borderRadius: '5px',
  boxShadow: 'none',
}));


const AboutView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
          <Typography>{t("common.About")}</Typography>
        </Breadcrumbs>
      </Box>
      <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
        <Box>
          <img src={"/images/ic_about_logo.png"} />
        </Box>
        <Box>
          <Typography sx={{ color: '#aeafb0' }}>Ver 2.0.0 (build 32)</Typography>
        </Box>
      </Grid>

      <Grid>
        <Box
          sx={{
            p: 2,
            display: 'grid',
            gridTemplateColumns: { md: '1fr' },
            gap: 2,
            padding: "8px",
          }}
        >
          <Item
            sx={{
              display: 'flex',
              alignItems: "center",
              position: "relative",
            }}
            onClick={async () => {
              navigate("/user/about/user-agreement/");
            }}
          >
            <Grid>
              <Typography sx={{ paddingLeft: '20px' }}>{t("common.User Agreement")}</Typography>
            </Grid>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Grid>
          </Item>
          <Item
            sx={{
              display: 'flex',
              alignItems: "center",
              position: "relative",
            }}
            onClick={async () => {
              navigate("/user/about/privacy-policy/");
            }}
          >
            <Grid>
              <Typography sx={{ paddingLeft: '20px' }}>{t("common.Privacy Policy")}</Typography>
            </Grid>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Grid>
          </Item>
          <Item
            sx={{
              display: 'flex',
              alignItems: "center",
              position: "relative",
            }}
            onClick={async () => {
              navigate("/user/about/info-collection-form/");
            }}
          >
            <Grid>
              <Typography sx={{ paddingLeft: '20px' }}>{t("common.Info Collection Form")}</Typography>
            </Grid>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Grid>
          </Item>
          <Item
            sx={{
              display: 'flex',
              alignItems: "center",
              position: "relative",
            }}
            onClick={async () => {
              navigate("/user/about/compliance/");
            }}
          >
            <Grid>
              <Typography sx={{ paddingLeft: '20px' }}>{t("common.Compliance")}</Typography>
            </Grid>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Grid>
          </Item>
        </Box>
      </Grid>
      <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: "200px" }}>
          <Box>
            <Typography sx={{ color: '#aeafb0' }}>Copyright © 2022 NGTECO CO., LTD. All rights reserved</Typography>
          </Box>
          </Grid>
    </>
  )
}

export default AboutView;
