const TAMS = new Map([
    ["TAMSE1001", "End time must be greater than Start time."],
    // ["TAMSE1002", "The schedule is overlap on {assigned_start} - {assigned_end}."],
    ["TAMSE1003", "Check Out time cannot be equal to Check In time"],
    ["TAMSE1004", "Records With Duplicate Name, Please use another name"],
    // ["TAMSE1005", "Wrong time range selection for Day {index}"],
    // ["TAMSE1006", "This TimeSheet {name} is already in use, deletion is prohibited"],
    ["TAMSE1007", "Day change time cannot be within a cross-day scheduling period"],
    ["TAMSE1008", "The Punch Interval cannot exceed the check-out and check-in interval"],
    ["TAMSE1009", "The BreakTime Cycle cannot be repeated and needs to be within the range of Check In and Check out"],
])

export default TAMS
