import React from "react";
import {InputLabel, Grid, InputLabelProps, styled} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

type NGInputLabelProps = InputLabelProps & {
  label: React.ReactNode;
  tooltip?: string
}

const HelpOutlineOutlinedIconCustom = styled(HelpOutlineOutlinedIcon)`
  &:hover {
    color: #0084d0;
  }
`;
const IconButtonCustom = styled(IconButton)`
  &:hover {
    color: #0084d0;
  }
`;

const CodeWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    border: "solid #0084d0 1px",
    color: "gray",
    fontSize: 15,
    lineHeight: 2,
    "& .MuiTooltip-arrow": {
      "&::before": {
        border: "solid #0084d0 1px",
        backgroundColor: "white",
      },
    },
  },
});

const NGInputLabel: React.FC<NGInputLabelProps> = (props) => {
  const {label, ...inputLabelProps} = props
  return (
    <InputLabel shrink sx={{transform: "none", display: "flex"}} {...inputLabelProps}>
      {label}
      {props.tooltip && <CodeWidthTooltip title={props.tooltip} placement="left-end" arrow>
        <IconButtonCustom sx={{ "&:hover": { backgroundColor: "transparent" }, margin:"-5px 0 0 -5px", order:"8"}}>
          <HelpOutlineOutlinedIconCustom sx={{fontSize: 20}}/>
        </IconButtonCustom>
      </CodeWidthTooltip>}
    </InputLabel>
  )
}
export default NGInputLabel
