import React, { useEffect, useState } from "react";
import MainLayout from "@/pages/auth/Login/components/MainLayout";
import { ReactComponent as LayoutImage } from "@/assets/images/UserLogin.svg"
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
  Select
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { object, TypeOf, z } from "zod";
import WebURL from "@/urls";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import UserService from "@/services/auth/UserService";
import { LoadingButton } from "@mui/lab";
import CustomLink from "@/pages/auth/Login/components/CustomLink";
import LogoView from "@/pages/auth/Login/components/LogoView";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import ControlledPasswordFiled from "@/components/ngteco-hook-form/ControlledPasswordFiled";
import SignUpUserAgreement from "@/pages/auth/Login/components/SignUpUserAgreement";
import SignUpPrivacyPolicy from "@/pages/auth/Login/components/SignUpPrivacyPolicy";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NGUserAgreement from "@/components/ngteco-form/NGUserAgreement"
import NGPrivacyPolicy from "@/components/ngteco-form/NGPrivacyPolicy"


const SignUp: React.FC = () => {
  const native = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const re_math =  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{6,}$/;

  const signUpSchema = object({
    first_name: z.string().min(1, t("common.This field is required!")).max(120, t("common.Max length of name is 120")),
    last_name: z.string().min(1, t("common.This field is required!")).max(120, t("common.Max length of name is 120")),
    email: z.string().min(1, t("common.This field is required!")).email(t("common.Invalid email format")),
    password: z.string().min(1, t("common.This field is required!")),
    confirm_password: z.string().min(1, t("common.This field is required!")),
    country: z.string().optional(),
    countrySelect: z.object({
      id: z.string(),
      name: z.string(),
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    agreement: z.boolean(),
    privacy: z.boolean(),
  }).refine((data) => data.password === data.confirm_password, {
    message: t("organ.Confirm Password don't match"),
    path: ["confirm_password"]
  }).refine((data) => re_math.test(data.password), {
    message: t("organ.Password verification policy"),
    path: ["password"]
  })

  type signUpInput = TypeOf<typeof signUpSchema>;

  const signUpForm = useForm<signUpInput>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      // country: "",
    }
  })
  const [policy, setPolicy] = useState<string>("main");
  const { register, handleSubmit, formState: { errors } } = signUpForm
  const [userAgreement, setUserAgreement] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [agreementOpen, setAgreementOpen] = React.useState(false);
  const [privacyOpen, setPrivacyOpen] = React.useState(false);


  const { enqueueSnackbar } = useSnackbar();
  const handleClickOpen = (type:any) => () => {
    if (type==="Agreement"){
      setAgreementOpen(true);
    }
    else{
      setPrivacyOpen(true)
    }
  };

  const handleClose = (type:any) => {
    if (type==="Agreement"){
      setAgreementOpen(false);
    }
    else{
      setPrivacyOpen(false)
    }
  };

  const onSignUp = async () => {
    const data = signUpForm.getValues()
    data.country = data.countrySelect.id

    if (!data.agreement || !data.privacy) {
      enqueueSnackbar(t("organ.Please read user agreement and privacy policy before sign up"), {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      return
    }
    try {
      setLoading(true)
      const { status, res_data } = await UserService.signUp(data);
      enqueueSnackbar(t("common.success"), {
        variant: "success",
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      native(WebURL.AUTH_USER_LOGIN);
    } finally {
      setLoading(false)
    }
  };
  return (
    <MainLayout>
      <Grid sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #CAD0D9",
        marginTop: "3vh",
        marginBottom: "2vh",
        minHeight: "870px",
        width: '34vw',
        borderRadius: "20px",
      }}>
        <LogoView />
        {policy === "main" ?
          <Grid component={"form"} style={{ width: '30vw' }}>
            <Typography variant="h5">{t("signUp.Ready to sign up?")}</Typography>
            <Stack spacing={1} sx={{ overflow: "auto" }}>
              <ControlledTextFiled
                fullWidth required hookForm={signUpForm}
                name={'first_name'}
                label={t("signUp.First Name")}
              />
              <ControlledTextFiled
                fullWidth required hookForm={signUpForm}
                name={'last_name'}
                label={t("signUp.Last Name")}
              />
              <ControlledTextFiled
                fullWidth required hookForm={signUpForm}
                name={'email'} label={t("signUp.Enter the email")}
              />
              <ControlAutoComplete
                  label={t("signUp.Choose your country")}
                  name={"countrySelect"}
                  required
                  form={signUpForm}
                  options={[
                    {id: 'USA', name: t("common.America")},
                    {id: 'JPN', name: t("common.Japan")}
                  ]}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                />
              <ControlledPasswordFiled
                fullWidth required hookForm={signUpForm}
                name={'password'}
                label={t("signUp.Password")}
              />
              <ControlledPasswordFiled
                fullWidth required hookForm={signUpForm}
                name={'confirm_password'}
                label={t("signUp.Confirm Password")}
              />
            </Stack>
            <FormControlLabel control={<Checkbox {...register("agreement")} checked={userAgreement}
            onChange={(event) => {setUserAgreement(event.target.checked)}}/>} label={
              <Box sx={{ display: "inline-flex", justifyContent: "center"}}>
                <Typography sx={{marginTop:"7px"}}>{t("signUp.I have read and agree to the")}</Typography>
                <Button onClick={handleClickOpen("Agreement")}>{t("common.User Agreement")}</Button>
                <Dialog
                  open={agreementOpen}
                  onClose={()=>{handleClose("Agreement")}}
                  scroll={"paper"}
                  maxWidth={"md"}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">User Agreement</DialogTitle>
                  <DialogContent dividers={true}>
                    <DialogContentText
                      id="scroll-dialog-User Agreement"
                      tabIndex={-1}
                    >
                      <NGUserAgreement/>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => {handleClose("Agreement"); setUserAgreement(false)}}>{t("signUp.Not agree and quit")}</Button>
                    <Button variant="contained" onClick={() => {handleClose("Agreement"); setUserAgreement(true);}}>{t("signUp.Read and agree")}</Button>
                  </DialogActions>
                </Dialog>
              </Box>
            } />
            <FormControlLabel control={<Checkbox {...register("privacy")} checked={privacyPolicy}
              onChange={(event) => {setPrivacyPolicy(event.target.checked)}}/>}  label={
              <Box sx={{ display: "inline-flex" }}>
                <Typography sx={{marginTop:"7px"}}>{t("signUp.I have read and agree to the")}</Typography>
                <Button onClick={handleClickOpen("Privacy")}>{t("signUp.Privacy policy")}</Button>
                <Dialog
                  open={privacyOpen}
                  onClose={()=>{handleClose("Privacy")}}
                  scroll={"paper"}
                  maxWidth={"md"}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">Personal Information Protection and Privacy Policy</DialogTitle>
                  <DialogContent dividers={true}>
                    <DialogContentText
                      id="scroll-dialog-Privacy policy"
                      tabIndex={-1}
                    >
                      <NGPrivacyPolicy/>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => {handleClose("Privacy"); setPrivacyPolicy(false)}}>{t("signUp.Not agree and quit")}</Button>
                    <Button variant="contained" onClick={() => {handleClose("Privacy"); setPrivacyPolicy(true);}}>{t("signUp.Read and agree")}</Button>
                  </DialogActions>
                </Dialog>
              </Box>
            } />
            <LoadingButton
              fullWidth={true}
              variant={"contained"}
              loading={loading}
              // loadingPosition={"start"}
              sx={{ margin: '8px 0', textTransform: "none" }}
              onClick={signUpForm.handleSubmit(onSignUp)}
            >
              {t("signUp.Sign up")}
            </LoadingButton>
          </Grid>
          : policy === "privatePolicy" ?
            <SignUpPrivacyPolicy setPolicy={setPolicy} setPrivacyPolicy={setPrivacyPolicy} />
            : <SignUpUserAgreement setPolicy={setPolicy} setUserAgreement={setUserAgreement} />
        }
        <Grid sx={{
          display: "inline-flex",
          justifyContent: "center",
          paddingBottom: 1,
          bottom: 0,
          position: "position",
          textAlign: 'center',
          width: '30vw',
        }}>
          <Typography sx={{ fontSize: "14px" }}>{t("login.Already have an account?")}</Typography>
          <CustomLink
            label={t("login.login")}
            onClick={() => native(WebURL.AUTH_USER_LOGIN)}
          />
        </Grid>
      </Grid>
    </MainLayout>
  )
}
export default SignUp
