import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Grid,
  InputAdornment, Paper,
  Typography
} from "@mui/material";
import { object, TypeOf, z } from "zod";
import { useForm } from "react-hook-form";
import UserService from "@/services/auth/UserService";
import {useTranslation} from "react-i18next";
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import WebURL from "@/urls";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import GlobalStyles from "@mui/material/GlobalStyles";
import {zodResolver} from "@hookform/resolvers/zod";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";

type Credential = {
  oldPassword: string;
  confirm_new_password: string;
  newPassword: string;
}

const ResetPasswordView: React.FC = () => {
  const native = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const {enqueueSnackbar} = useSnackbar()
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowComfirmPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const ariaLabel = { 'aria-label': 'description' };
  const loginSchema = object({
    oldPassword: z.string().min(1, t("common.This field is required!")),
    confirm_new_password: z.string().min(1, t("common.This field is required!")),
    newPassword: z.string().min(1, t("common.This field is required!")),
  })
  type LoginInput = TypeOf<typeof loginSchema>;

  const {register, handleSubmit, formState: {errors}} = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  })
  const style = {
      "input::-ms-reveal, input::-ms-clear": {
        display: "none"
      }
    };
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword)
  };
  const handleClickShowConfirmPassword = () => {
    setShowComfirmPassword(!showConfirmPassword)
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  };

  const onLogin = async (credential: Credential) => {
    try {
      setLoading(true)
      console.log("credential--->", credential);
      if(credential.confirm_new_password != credential.newPassword){
        enqueueSnackbar(t("organ.Make sure twice password is same"), {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      return
      }
      if(credential.oldPassword == credential.newPassword){
        enqueueSnackbar(t("organ.New password duplicated"), {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      return
      }
    const {status} = await UserService.resetPassword(credential)
        console.log(status);
        if (status < 400) {
          enqueueSnackbar(t("organ.Reset password successfully"), {
            variant: "success",
            autoHideDuration: 1500,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          })
          native(WebURL.AUTH_USER_LOGIN);
        }
    } finally {
      setLoading(false)
    }};

  return (
    <>
      <>
        <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
          <Typography>{t("common.Reset Password")}</Typography>
        </Breadcrumbs>
      </>
      <Grid  component={"form"} rowSpacing={2} onSubmit={handleSubmit(onLogin)} >
      <Paper elevation={0} sx={{margin: '10px 20px'}}>
      <Grid container >
        <Grid item xs={12} sx={{p: "0 22px", marginTop:"20px"}}>
          <Grid container={true} rowSpacing={2} columnSpacing={6} component={"form"}>
          <Grid item={true} xs={6}>
              <NGInputLabel label={t("login.Current password")} required/>
              <FormControl
                variant="outlined"
                required={true}
                fullWidth={true}
                sx={{marginBottom: "20px"}}
                >
                <OutlinedInput
                  type={showOldPassword ? 'text' : 'password'}
                  placeholder={t("login.Current password")}
                  error={!!errors.oldPassword}
                  inputProps={ariaLabel}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={handleClickShowOldPassword}
                      >
                        {showOldPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("oldPassword")}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{p: "0 22px"}}>
          <Grid container={true} rowSpacing={2} columnSpacing={6} component={"form"}>
          <Grid item={true} xs={6}>
              <NGInputLabel label={t("login.Create new password")} required />
              <FormControl
                variant="outlined"
                required={true}
                fullWidth={true}
                sx={{marginBottom: "20px"}}
                >
                <OutlinedInput
                  type={showNewPassword ? 'text' : 'password'}
                  placeholder={t("login.Create new password")}
                  error={!!errors.newPassword}
                  inputProps={ariaLabel}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={handleClickShowNewPassword}
                      >
                        {showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("newPassword")}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{p: "0 22px", marginBottom:"30px"}}>
          <Grid container={true} rowSpacing={2} columnSpacing={6} component={"form"}>
          <Grid item={true} xs={6}>
              <NGInputLabel label={t("login.Confirm new password again")} required />
              <FormControl
                variant="outlined"
                required={true}
                fullWidth={true}
                sx={{marginBottom: "20px"}}
                >
                <GlobalStyles styles={style} />
                <OutlinedInput
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder={t("login.Confirm new password again")}
                  error={!!errors.confirm_new_password}
                  inputProps={ariaLabel}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("confirm_new_password")}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Paper>
        <Grid item xs={12} sx={{ marginLeft: "81.5%", marginTop: "70px"}}>
          <SavingButton loading={loading} name={t("login.confirm")} onClick={handleSubmit(onLogin)}/>
        </Grid>
      </Grid>
    </>
  )
}
export default ResetPasswordView;
