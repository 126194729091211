import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom"
import {Box, styled} from "@mui/system";
import {TextField, Grid, Paper, Typography, Avatar, Divider, Tooltip, IconButton, Breadcrumbs} from "@mui/material";
import {DMSEndpoints} from "@/services/dms/endpoints";
import GenericService from "../../../../services/GenericService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import WebURL from "@/urls";
import {useTranslation} from "react-i18next";
import DeviceService from "@/services/dms/DeviceService";
import AdUnitsIco from '@mui/icons-material/AdUnits';
import {grey} from '@mui/material/colors';
import {timezones} from "@/components/resource/timezone";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

type DeviceProps = {
  id: string,
  sn: string,
  alias: string,
  site: string,
  zone: string,
  site_name?: string,
  zone_name?: string,
  organization_name?: string,
  timezone?: string,
  ipv4?: string,
  MCUVersion?: string,
  firmware_ver?: string,
  model: string,
  category: string,
  protocol_type: string,
  status: string,
  ip?: string,
  userCount?: string,
  fpCount?: string,
  visLightFaceCount?: string,
}

const StyledInputElement = styled('input')(
  ({theme}) => `
  font-size: 16px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 8px;
  padding: 6px 6px;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? grey[600] : grey[100]};
  }
`,
);

type FlexBoxItemProps = {
  title: string,
  value: any
  tip?: string,
  edit?: boolean
}

const EditInput: React.FC<ReadOrEditInputProps> = (props) => {
  const [val, setVal] = React.useState('');
  useEffect(() => {
    setVal(props.value)
  }, [props])
  return (
    <StyledInputElement
      value={val}
      onChange={(event) => setVal(event.target.value)}
    />
  )
}

type ReadOrEditInputProps = {
  value: any
  edit?: boolean
}

const ReadOrEditInput: React.FC<ReadOrEditInputProps> = (props) => {
  if (props.edit && true)
    return (<EditInput {...props} />)
  else
    return (<Typography>{props.value}</Typography>)
}

const FlexBoxItem: React.FC<FlexBoxItemProps> = (props) => {
  const field = {
    paddingRight: 1,
    minWidth: '150px',
    color: 'gray'
  }
  return (
    <Box display='flex' paddingBottom={2}>
      <Box {...field}>
        <Tooltip title={props.tip || props.title}>
          <Typography>{props.title}</Typography>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title={`${props.value}`}>
          <ReadOrEditInput value={props.value} edit={props.edit}/>
        </Tooltip>
      </Box>
    </Box>
  )
}

const DeviceDetailView: React.FC = () => {
  const {t} = useTranslation();
  const location = useLocation()
  const state = location.state as { id: string }
  const timezoneList = timezones();
  const navigate = useNavigate();
  const [device, setDevice] = useState<DeviceProps>();
  useEffect(() => {
    DeviceService.GetWhiteDeviceInfo({"id": state.id}).then((res) => {
      setDevice(res.data.data);
    })
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Breadcrumbs separator="" sx={{height: "32px", p: '8px'}}>
          <IconButton id="BackIconButton" sx={{padding: "5px"}} onClick={() => navigate(-1)}>
            <ArrowBackIosNewIcon sx={{display: "flex"}} fontSize="small"/>
          </IconButton>
          <Typography>{t("common.Device detail")}</Typography>
        </Breadcrumbs>
      </Box>
      <Paper elevation={0}
             style={{minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', paddingTop: '20px'}}>
        <Grid container direction='row' justifyContent='space-evenly' alignItems='flex-start'>
          <Grid lg={1} item>
            {/*<Avatar sx={{width: 200, height: 200, bgcolor: grey[100]}}>*/}
            {/*  <AdUnitsIco sx={customDataGridStyle} color="action"/>*/}
            {/*</Avatar>*/}
            <img style={{height: '200px'}} src={"/images/device_detail.png"}/>
          </Grid>
          <Grid lg={4} item>
            <FlexBoxItem title={t('common.Device name')} value={device?.alias} tip={t('dms.Device Name')} edit={false}/>
            <FlexBoxItem title={t('common.Site Name')} value={device?.site_name}/>
            <FlexBoxItem title={t('common.Zone Name')} value={device?.zone_name}/>
            <FlexBoxItem title={t('common.Site Time Zone')}
                         value={timezoneList.find((option: any) => option.value === device?.timezone)?.name || `UTC${device?.timezone}`}/>
            <FlexBoxItem title={t("common.IP Address")} value={device?.ip}/>
            <FlexBoxItem title={t("dms.Status")} value={device?.status==='1'? t("dms.Online"): t("dms.Offline")} />
          </Grid>
        </Grid>
      </Paper>
      <Box style={{padding: '10px 0 10px 0'}}>
        <Typography>{t("common.Device details parameters")}</Typography>
      </Box>
      <Paper elevation={0}
             style={{minHeight: '120px', backgroundColor: '#fff', borderRadius: '8px', paddingTop: '16px'}}>
        <Grid lg={5} item style={{marginLeft: '20px'}}>
          <FlexBoxItem title={t('common.Device ID')} value={device?.id} tip={t('common.Device ID')}/>
          <FlexBoxItem title={t('common.Serial Number')} value={device?.sn} tip={t('common.Serial Number')}/>
          <FlexBoxItem title={t('common.Device Model')} value={device?.model}/>
          <FlexBoxItem title={t("common.Firmware Version")} value={device?.firmware_ver} tip={t("common.Firmware Version")}/>
          <FlexBoxItem title={t('common.Device Type')} value={device?.category}/>
          <FlexBoxItem title={t('common.Protocol Type')} value={device?.protocol_type}/>
        </Grid>
      </Paper>
    </>
  )
}
export default DeviceDetailView;
