import React, { useEffect, useState } from "react";
import { Box, Paper, Grid, TextField, Button, IconButton, Stack } from "@mui/material";
import {object, TypeOf, z} from "zod";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {ReactComponent as DepartmentGraph} from "@/assets/images/DepartmentGraph.svg"
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import DepartmentService from "@/services/hr/DepartmentService";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import WebURL from "@/urls";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import { useTranslation } from "react-i18next";


const DepartmentView: React.FC = () => {
  const { t } = useTranslation();
  const departmentValidate = object({
    code: z.string().min(1, t("common.This field is required!"))
          .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    name: z.string().min(1,  t("common.This field is required!"))
          .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
  })
  type DepartmentForm = TypeOf<typeof departmentValidate>;
  const location = useLocation()
  const state = location.state as { id: string }
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const title = state?.id ? t("common.Edit Department") : t("common.New Department") ;
  const [saving, setSaving] = React.useState<boolean>(false)
  const departmentForm = useForm<DepartmentForm>({
    resolver: zodResolver(departmentValidate),
    defaultValues: {
      code: '',
      name: '',
    }
  });
  useEffect(() => {
    if (state?.id) {
      DepartmentService.retrieve(state.id || "").then((res) => {
        departmentForm.setValue("code", res.data.data?.code);
        departmentForm.setValue("name", res.data.data?.name);
      });
    }
  }, []);

  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      let result;
      if (state?.id) {
        // update department
        result = await DepartmentService.update(state.id, departmentForm.getValues());
        if (result.status < 400) {
          enqueueSnackbar(t("common.Department updated successfully."), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }});
          navigate(WebURL.HR_DEPARTMENT);
        }
      } else {
        result = await DepartmentService.add(departmentForm.getValues());
        if (result.status < 400) {
          enqueueSnackbar(t("common.Department added successfully."), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }});
          navigate(WebURL.HR_DEPARTMENT);
        }
      }

    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.HR_DEPARTMENT, label: t("common.Department") },
        { label: title },
      ]} />
      <Paper sx={{margin: '20px 0', padding: '20px'}}>
        <Grid
          container
          spacing={2}
        >
          <Grid item lg={5}>
            <DepartmentGraph width="100%" height="45vh" />
          </Grid>
          <Grid item lg={7}>
            <ControlledTextFiled
              hookForm={departmentForm}
              label={t("hr.department.Department name")}
              name="name"
              fullWidth
              required
              placeholder={t("hr.department.Department name")}
            />
            <ControlledTextFiled
              hookForm={departmentForm}
              label={t("hr.department.Department code")}
              name="code"
              fullWidth
              required
              placeholder={t("hr.department.Department code")}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid style={{'padding':'30px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton/>
          <SavingButton loading={saving} onClick={departmentForm.handleSubmit(onSubmit)}/>
        </Stack>
      </Grid>
    </>
  )
}

export default DepartmentView;
