import { Box, Checkbox, Divider, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import {ReactComponent as DeviceBound} from "@/assets/images/DeviceBound.svg";
import {ReactComponent as DeviceNetwork} from "@/assets/images/DeviceNetwork.svg";
import {ReactComponent as DevicePower} from "@/assets/images/DevicePower.svg";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import DeviceBinding from "./DeviceBinding";


type DeviceGuideProps = {
  setStep: Dispatch<SetStateAction<string>>
}


const StepDesc: React.FC<{title: string, description: string, picture: React.ReactNode}> = (props) => {
  return (
    <Grid item xs={3} sx={{display: 'grid', alignContent: 'stretch'}}>
      <Box fontSize={"18px"} height={"64px"}>
        <Typography>{props.title}</Typography>
      </Box>
      <Box fontSize={"16px"} color={"gray"}>
        <Typography>{props.description}</Typography>
      </Box>
      <Box textAlign={"center"}>
        {props.picture}
      </Box>
    </Grid>
  )
}

const DeviceConnGuide: React.FC<DeviceGuideProps> = (props) => {
  const {t} = useTranslation();
  const [isContinue, setContinue] = useState<boolean>(false);
  return (
    <>
      <Paper elevation={0} sx={{backgroundColor: "#fff", borderRadius: "8px", paddingTop: '2vh'}}>
        <Grid container direction="row" justifyContent="space-evenly">
          <StepDesc
            title={t("dms.step1Title")}
            description={t("dms.step1Desc")}
            picture={<DevicePower />}
          />
          <Divider orientation="vertical" flexItem />
          <StepDesc
            title={t("dms.step2Title")}
            description={t("dms.step2Desc")}
            picture={<DeviceNetwork />}
          />
          <Divider orientation="vertical" flexItem />
          <StepDesc
            title={t("dms.step3Title")}
            description={t("dms.step3Desc")}
            picture={<DeviceBound />}
          />
        </Grid>
        <Grid sx={{position: "fixed", bottom: '2vh', right: '1vw'}}>
          <Grid display={'flex'} flexDirection={"column"} margin={'32px 16px'}>
            <FormControlLabel
              control={
                <Checkbox onChange={(event) => {
                  setContinue(event.target.checked)
                }}/>
              }
              label={t("dms.I have got this done")}
            />
            <LoadingButton
              variant="contained"
              disabled={!isContinue}
              sx={{width: '15vw', textTransform: "capitalize"}}
              onClick={() => {
                props.setStep('register')
              }}
            >
              {t("common.continue")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default DeviceConnGuide;
