import React, {forwardRef, useEffect, useState} from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
} from "@mui/material";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import { useSelector } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { object, TypeOf, z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import UserService from "@/services/auth/UserService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import Uploady, { useBatchAddListener } from "@rpldy/uploady";
import {useDispatch} from "react-redux";
import {obtainUserInfo} from "@/store/slices/App";
import {asUploadButton} from "@rpldy/upload-button";
import {useSnackbar} from "notistack";
import UploadPreview, { PreviewItem } from "@rpldy/upload-preview";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {ReactComponent as IconDelete} from "@/assets/icons/IconDelete.svg";
import {ReactComponent as IconEditorList} from "@/assets/icons/IconEditorList.svg";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {useTranslation} from "react-i18next";

const UserProfileView: React.FC = () => {
  const { t } = useTranslation();
  const [editState, setEditState] = useState<boolean>(false);
  const user = useSelector((state: any) => state.app.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [imageFile, setImageFile] = useState<any>();
  const [profilePhoto, setProfilePhoto] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const userProfileValidate = object({
    firstName: z.string().min(1, t("common.This field is required!")),
    lastName: z.string().min(1, t("common.This field is required!")),
    email: z.string().min(1, t("common.This field is required!"))
  })
  
  type UserProfileForm = TypeOf<typeof userProfileValidate>;
  
  const profileForm = useForm<UserProfileForm>({
    resolver: zodResolver(userProfileValidate)
  });
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = profileForm;

  useEffect(() => {
    UserService.userInfo().then((res) => {
      setProfilePhoto(res.data.imagePreSignedURL)
      setImageFile(undefined);
      setValue("firstName", res.data.firstName);
      setValue("lastName", res.data.lastName);
      setValue("email", res.data.email);
    });
  }, [editState]);


  const onSubmit = async (formData: any) => {
    setLoading(true);
    if (imageFile) {
        formData['profilePhoto'] = imageFile
      }
    console.log("formData--->",formData);
    await UserService.updateUserInfo(formData).then((response) => {
      setEditState(false)
      dispatch<any>(obtainUserInfo())
      SnackbarUtil.success('User updated successfully.')
      console.log("????", response)
    }).catch((error) => {
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const MyPreview = (props: any) => {
    return (
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height="200"
          image={props.url}
          alt="Profile Logo"
        />
      </Card>
    )
  };

  const CustomUploadButton = asUploadButton(forwardRef(
    (props: any, ref: any) => {
      useBatchAddListener((batch) => {
        console.log(batch.items[0].file)
        if (batch.items[0].file.size > 3000000) {
          enqueueSnackbar(t("common.Photos size up to maximum 3 MB"), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
        setImageFile(batch.items[0].file)
        setProfilePhoto(imageFile)
      });
      return <Button component="label" variant="contained"
                     sx={{ width: '90%', textTransform: "none", marginTop: "30px"}} {...props} />
    }
  ));

  return (
    <>
      {
        editState ?
          <CustomBreadcrumbs record={[
            { label: t("common.Account Profile"), onClick: () => setEditState(false)},
            { label: t("common.Editor")},
          ]} />
          :
          <>
            <CustomBreadcrumbs record={[{ label: t("common.Account Profile") }]} />
            <Box>
              <IconButton onClick={() => setEditState(true)} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)", marginLeft: '8px' }}>
                <SvgIcon component={IconEditorList} />
              </IconButton>
            </Box>
          </>
      }
    <Paper elevation={0} sx={{margin: '10px 10px'}}>
      <Grid container sx={{ p: '20px' }}>
        <Grid lg={5} item sx={{ height: '45vh', display: 'flex', justifyContent: 'start', padding: '8px' }}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid #AEAFB0', width: '90%', height: '100%', lineHeight: '50px', minWidth: '200px' }}>
            <Uploady>
              <Box sx={{ height: '200px' }}>
                {imageFile ? <UploadPreview
                          PreviewComponent={MyPreview}
                        /> :
                <Grid>
                <Box>
                  <AvatarIcon name={user?.firstName ? user.firstName : "None"} diameter={160} profilePhoto={profilePhoto}/>
                </Box>
                <Box sx={{ "textAlign": "center", "width": "100%" }} >
                  {user?.firstName ? user.firstName : ""} {user?.lastName ? user.lastName : ""}
                </Box>
                </Grid>
                }
              </Box>
            {!editState ?
              <Box>
              </Box> :
                 <CustomUploadButton>{t("organ.Add photos")}</CustomUploadButton>
            }
            </Uploady>
          </Grid >
        </Grid>
        <Grid xs={7} item>
          <Grid container={true} rowSpacing={2}>
            <Grid item xs={12}>
              <ControlledTextFiled
              hookForm={profileForm}
              name={'firstName'}
              label={t("signUp.First Name")}
              fullWidth
              disabled={!editState}
              required
            />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextFiled
              hookForm={profileForm}
              name={'lastName'}
              label={t("signUp.Last Name")}
              fullWidth
              disabled={!editState}
              required
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextFiled
              hookForm={profileForm}
              name={'email'}
              label={t("signUp.Enter the email")}
              fullWidth
              disabled={!editState}
              required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    {editState &&
      <Grid item xs={4} sx={{ marginLeft: "61.5%", marginTop: "70px"}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton onClick={() => setEditState(false)}/>
          <SavingButton loading={loading}  onClick={handleSubmit(onSubmit)}/>
        </Stack>
      </Grid>
    }
    </>
  )
}
export default UserProfileView;
