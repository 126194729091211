import request from "@/utils/Request";
import qs from 'qs';
import { HREndpoints } from "./endpoints";

const add = async (data: any) => {
  const response = await request.authorizedAxios().post(HREndpoints.HR_DEPARTMENT_URI, data);
  return response;
}

const list = async (params: Record<string, any>) => {
  const response = await request.authorizedAxios().get(HREndpoints.HR_DEPARTMENT_URI, {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response.data;
}

const update = async (id: string, data: any) => {
  const response = await request.authorizedAxios().put(`${HREndpoints.HR_DEPARTMENT_URI}${id}/`, data);
  return response;
}

const retrieve = async (id: string) => {
  const response = await request.authorizedAxios().get(`${HREndpoints.HR_DEPARTMENT_URI}${id}/`);
  return response;
}

const destroy = async (id: string) => {
  const response = await request.authorizedAxios().delete(`${HREndpoints.HR_DEPARTMENT_URI}${id}/`);
  return response;
}

const DepartmentService = {
  add,
  list,
  update,
  retrieve,
  destroy,
}

export default DepartmentService;
