import { Box, Button, Grid, Breadcrumbs, Typography, CardMedia, Card } from "@mui/material";
import { ReactComponent as DefaultOrganizationSquare } from '@/assets/images/DefaultOrganizationSquare.svg';
import { useEffect, useState } from "react";
import CompanyService from "@/services/auth/CompanyService";
import { useNavigate } from 'react-router-dom';
import GenericService from "@/services/GenericService";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { AuthEndpoints } from "@/services/auth/endpoints";
import { useDispatch } from "react-redux";
import { obtainCompanyInfo } from "@/store/slices/App";
import WebURL from "@/urls";
import SnackbarUtil from "@/utils/SnackbarUtil";
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useTranslation } from "react-i18next";


type GridData = {
  data: any;
  total: number;
}


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 110,
  lineHeight: '60px',
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const OrganSwitch: React.FC = () => {
  const { t } = useTranslation();
  const [gridData, setGridData] = useState<GridData>({ data: [], total: 0 });
  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const dispatch = useDispatch();
  const native = useNavigate();

  const refreshGridData = () => {
    setRefresh(refresh + 1);
  };

  const fetchGridData = () => {
    setLoading(true)
    GenericService.list(AuthEndpoints.AUTH_USER_COMPANY, { current: (page + 1), pageSize: pageSize }).then((response) => {
      setGridData(response.data);
    }).catch((error) => {
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const onConfirmSelected = () => {
    if (selectedItem) {
      CompanyService.selectCompany({ company_id: selectedItem }).then((response) => {
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);
        dispatch<any>(obtainCompanyInfo())
        native(WebURL.DASHBOARD)
      }).catch((error) => {
        if (error.response.status < 500) {
          // do something when status is 4xx.
        }
      }).finally(() => {
        setLoading(false);
      });
    } else {
      SnackbarUtil.error(t("common.No organization selected."), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
    }
  }

  useEffect(() => {
    fetchGridData()
  }, [page, pageSize, refresh]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: 'flex' }}>
        <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
          <Typography>{t("common.Switch Organization")}</Typography>
        </Breadcrumbs>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              p: 2,
              display: 'grid',
              gridTemplateColumns: { md: '1fr 1fr' },
              gap: 2,
            }}
          >
            {gridData?.data.map((item: any) => (
              <Item
                sx={{
                  display: 'inline-flex',
                  alignItems: "center",
                  backgroundColor: selectedItem === item.id ? "#dfedf8" : "#fff"

                }}
                key={item.id} elevation={0}
                onClick={() => {
                  if (selectedItem === item.id) {
                    setSelectedItem("")
                  } else {
                    setSelectedItem(item.id)
                  }
                }}
              >
                <Checkbox
                  icon={<CircleOutlinedIcon />}
                  checkedIcon={<CheckCircle />}
                  checked={selectedItem === item.id}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelectedItem(item.id)
                    } else {
                      setSelectedItem("")
                    }
                  }}  {...label} />

                {
                  item.company_logo ?
                    <Card sx={{ maxWidth: 100, maxHeight: 100 }}>
                      <CardMedia
                        component="img"
                        height="100"
                        image={item.company_logo}
                        alt="Company Logo"
                      />
                    </Card>
                    :
                    <DefaultOrganizationSquare style={{ padding: "15px", borderRadius: '4px', border: '1px solid #AEAFB0', }} />

                }
                <Grid sx={{ paddingLeft: "20px" }}>
                  <Typography sx={{ lineHeigh: "100%" }}>{item.name}</Typography>
                  <Typography>{item.code}</Typography>
                </Grid>
              </Item>
            ))}
          </Box>

          <Button
            onClick={() => { onConfirmSelected() }}  variant="contained"
            sx={{ "width": "200px", "height": "50px", bottom: "30px", right: "60px", position: "fixed", textTransform: "none"}}>{t("common.confirm")}</Button>
        </Grid>

      </Grid>
    </Box>
  )

}

export default OrganSwitch;
