import React, {Dispatch, SetStateAction, useState} from "react";
import MainLayout from "@/pages/auth/Login/components/MainLayout";
import {ReactComponent as LayoutImage} from "@/assets/images/UserLogin.svg"
import {
  Button,
  Grid, Paper, styled,
  Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {object, TypeOf, z} from "zod";
import WebURL from "@/urls";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useSnackbar} from "notistack";
import UserService from "@/services/auth/UserService";

const signUpSchema = object({
})
type signUpInput = TypeOf<typeof signUpSchema>;

type PolicyProps = {
  setPolicy: Dispatch<SetStateAction<string>>,
  setPrivacyPolicy: Dispatch<SetStateAction<boolean>>,
}

const KnowAboutThisPolicy: React.FC<PolicyProps> = (props) => {
  const native = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const {register, handleSubmit, formState: {errors}} = useForm<signUpInput>({
    resolver: zodResolver(signUpSchema)
  })

  return (
    <Paper elevation={0} sx={{margin: '40px 0'}}>
      <Grid container={true} rowSpacing={3} component={"form"} noValidate sx={{p: "0 16px"}}>
        <Grid item sx={{display: "inline-flex", flexDirection: "column", marginLeft: -1}}  rowSpacing={3}>
          <Typography sx={{'p': '10px 20px'}}>{t("att.timeSheet.Know about tip1")}</Typography>
          <Typography sx={{'p': '10px 20px'}}>{t("att.timeSheet.Know about tip2")}</Typography>
          <Typography sx={{'p': '10px 20px'}}>{t("att.timeSheet.Know about tip3")}</Typography>
          <Typography sx={{ marginTop: "-10px", marginLeft: "20px"}}>{t("att.timeSheet.Know about tipExample")}</Typography>
          <Typography sx={{'p': '10px 20px'}}>{t("att.timeSheet.Know about tip4")}</Typography>
          {/* <Typography sx={{'p': '10px 20px'}}>{t("att.timeSheet.Know about tip5")}</Typography> */}
        </Grid>
      </Grid>
    </Paper>
  )
}
export default KnowAboutThisPolicy
