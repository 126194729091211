import { Grid, Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";

const ComplianceView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.AUTH_USER_ABOUT, label: t("common.About") },
        { label: t("common.Compliance") },
      ]} />
      <Typography sx={{padding: "10px"}}>
      {t("common.Compliance")}
      </Typography>
    </>
  )
}

export default ComplianceView;
