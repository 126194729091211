export const CountryName = [
  {
    "id": "10520",
    "value": "Afghanistan"
  },
  {
    "id": "10521",
    "value": "Albania"
  },
  {
    "id": "10522",
    "value": "Algeria"
  },
  {
    "id": "10523",
    "value": "Andorra"
  },
  {
    "id": "10524",
    "value": "Angola"
  },
  {
    "id": "10525",
    "value": "Antigua and Barbuda"
  },
  {
    "id": "10526",
    "value": "Argentina"
  },
  {
    "id": "10527",
    "value": "Armenia"
  },
  {
    "id": "10528",
    "value": "Australia"
  },
  {
    "id": "10529",
    "value": "Austria"
  },
  {
    "id": "10530",
    "value": "Azerbaijan"
  },
  {
    "id": "10531",
    "value": "Bahamas"
  },
  {
    "id": "10532",
    "value": "Bahrain"
  },
  {
    "id": "10533",
    "value": "Bangladesh"
  },
  {
    "id": "10534",
    "value": "Barbados"
  },
  {
    "id": "10535",
    "value": "Belarus"
  },
  {
    "id": "10536",
    "value": "Belgium"
  },
  {
    "id": "10537",
    "value": "Belize"
  },
  {
    "id": "10538",
    "value": "Benin"
  },
  {
    "id": "10539",
    "value": "Bhutan"
  },
  {
    "id": "10540",
    "value": "Bolivia"
  },
  {
    "id": "10541",
    "value": "Bosnia and Herzegovina"
  },
  {
    "id": "10542",
    "value": "Botswana"
  },
  {
    "id": "10543",
    "value": "Brazil"
  },
  {
    "id": "10544",
    "value": "Brunei"
  },
  {
    "id": "10545",
    "value": "Bulgaria"
  },
  {
    "id": "10546",
    "value": "Burkina Faso"
  },
  {
    "id": "10547",
    "value": "Burundi"
  },
  {
    "id": "10548",
    "value": "Côte d'Ivoire"
  },
  {
    "id": "10549",
    "value": "Cabo Verde"
  },
  {
    "id": "10550",
    "value": "Cambodia"
  },
  {
    "id": "10551",
    "value": "Cameroon"
  },
  {
    "id": "10552",
    "value": "Canada"
  },
  {
    "id": "10553",
    "value": "Central African Republic"
  },
  {
    "id": "10554",
    "value": "Chad"
  },
  {
    "id": "10555",
    "value": "Chile"
  },
  {
    "id": "10556",
    "value": "China"
  },
  {
    "id": "10557",
    "value": "Colombia"
  },
  {
    "id": "10558",
    "value": "Comoros"
  },
  {
    "id": "10559",
    "value": "Congo (Congo-Brazzaville)"
  },
  {
    "id": "10560",
    "value": "Costa Rica"
  },
  {
    "id": "10561",
    "value": "Croatia"
  },
  {
    "id": "10562",
    "value": "Cuba"
  },
  {
    "id": "10563",
    "value": "Cyprus"
  },
  {
    "id": "10564",
    "value": "Czechia (Czech Republic)"
  },
  {
    "id": "10565",
    "value": "Democratic Republic of the Congo"
  },
  {
    "id": "10566",
    "value": "Denmark"
  },
  {
    "id": "10567",
    "value": "Djibouti"
  },
  {
    "id": "10568",
    "value": "Dominican Republic"
  },
  {
    "id": "10569",
    "value": "Ecuador"
  },
  {
    "id": "10570",
    "value": "Egypt"
  },
  {
    "id": "10571",
    "value": "El Salvador"
  },
  {
    "id": "10572",
    "value": "Equatorial Guinea"
  },
  {
    "id": "10573",
    "value": "Eritrea"
  },
  {
    "id": "10574",
    "value": "Estonia"
  },
  {
    "id": "10575",
    "value": "Eswatini (fmr. \"Swaziland\")"
  },
  {
    "id": "10576",
    "value": "Ethiopia"
  },
  {
    "id": "10577",
    "value": "Fiji"
  },
  {
    "id": "10578",
    "value": "Finland"
  },
  {
    "id": "10579",
    "value": "France"
  },
  {
    "id": "10580",
    "value": "Gabon"
  },
  {
    "id": "10581",
    "value": "Gambia"
  },
  {
    "id": "10582",
    "value": "Georgia"
  },
  {
    "id": "10583",
    "value": "Germany"
  },
  {
    "id": "10584",
    "value": "Ghana"
  },
  {
    "id": "10585",
    "value": "Greece"
  },
  {
    "id": "10586",
    "value": "Grenada"
  },
  {
    "id": "10587",
    "value": "Guatemala"
  },
  {
    "id": "10588",
    "value": "Guinea"
  },
  {
    "id": "10589",
    "value": "Guinea-Bissau"
  },
  {
    "id": "10590",
    "value": "Guyana"
  },
  {
    "id": "10591",
    "value": "Haiti"
  },
  {
    "id": "10592",
    "value": "Holy See"
  },
  {
    "id": "10593",
    "value": "Honduras"
  },
  {
    "id": "10594",
    "value": "Hungary"
  },
  {
    "id": "10595",
    "value": "Iceland"
  },
  {
    "id": "10596",
    "value": "India"
  },
  {
    "id": "10597",
    "value": "Indonesia"
  },
  {
    "id": "10598",
    "value": "Iran"
  },
  {
    "id": "10599",
    "value": "Iraq"
  },
  {
    "id": "10600",
    "value": "Ireland"
  },
  {
    "id": "10601",
    "value": "Israel"
  },
  {
    "id": "10602",
    "value": "Italy"
  },
  {
    "id": "10603",
    "value": "Jamaica"
  },
  {
    "id": "10604",
    "value": "Japan"
  },
  {
    "id": "10605",
    "value": "Jordan"
  },
  {
    "id": "10606",
    "value": "Kazakhstan"
  },
  {
    "id": "10607",
    "value": "Kenya"
  },
  {
    "id": "10608",
    "value": "Kiribati"
  },
  {
    "id": "10609",
    "value": "Kuwait"
  },
  {
    "id": "10610",
    "value": "Kyrgyzstan"
  },
  {
    "id": "10611",
    "value": "Laos"
  },
  {
    "id": "10612",
    "value": "Latvia"
  },
  {
    "id": "10613",
    "value": "Lebanon"
  },
  {
    "id": "10614",
    "value": "Lesotho"
  },
  {
    "id": "10615",
    "value": "Liberia"
  },
  {
    "id": "10616",
    "value": "Libya"
  },
  {
    "id": "10617",
    "value": "Liechtenstein"
  },
  {
    "id": "10618",
    "value": "Lithuania"
  },
  {
    "id": "10619",
    "value": "Luxembourg"
  },
  {
    "id": "10620",
    "value": "Madagascar"
  },
  {
    "id": "10621",
    "value": "Malawi"
  },
  {
    "id": "10622",
    "value": "Malaysia"
  },
  {
    "id": "10623",
    "value": "Maldives"
  },
  {
    "id": "10624",
    "value": "Mali"
  },
  {
    "id": "10625",
    "value": "Malta"
  },
  {
    "id": "10626",
    "value": "Marshall Islands"
  },
  {
    "id": "10627",
    "value": "Mauritania"
  },
  {
    "id": "10628",
    "value": "Mauritius"
  },
  {
    "id": "10629",
    "value": "Mexico"
  },
  {
    "id": "10630",
    "value": "Micronesia"
  },
  {
    "id": "10631",
    "value": "Moldova"
  },
  {
    "id": "10632",
    "value": "Monaco"
  },
  {
    "id": "10633",
    "value": "Mongolia"
  },
  {
    "id": "10634",
    "value": "Montenegro"
  },
  {
    "id": "10635",
    "value": "Morocco"
  },
  {
    "id": "10636",
    "value": "Mozambique"
  },
  {
    "id": "10637",
    "value": "Myanmar (formerly Burma)"
  },
  {
    "id": "10638",
    "value": "Namibia"
  },
  {
    "id": "10639",
    "value": "Nauru"
  },
  {
    "id": "10640",
    "value": "Nepal"
  },
  {
    "id": "10641",
    "value": "Netherlands"
  },
  {
    "id": "10642",
    "value": "New Zealand"
  },
  {
    "id": "10643",
    "value": "Nicaragua"
  },
  {
    "id": "10644",
    "value": "Niger"
  },
  {
    "id": "10645",
    "value": "Nigeria"
  },
  {
    "id": "10646",
    "value": "North Korea"
  },
  {
    "id": "10647",
    "value": "North Macedonia"
  },
  {
    "id": "10648",
    "value": "Norway"
  },
  {
    "id": "10649",
    "value": "Oman"
  },
  {
    "id": "10650",
    "value": "Pakistan"
  },
  {
    "id": "10651",
    "value": "Palau"
  },
  {
    "id": "10652",
    "value": "Palestine State"
  },
  {
    "id": "10653",
    "value": "Panama"
  },
  {
    "id": "10654",
    "value": "Papua New Guinea"
  },
  {
    "id": "10655",
    "value": "Paraguay"
  },
  {
    "id": "10656",
    "value": "Peru"
  },
  {
    "id": "10657",
    "value": "Philippines"
  },
  {
    "id": "10658",
    "value": "Poland"
  },
  {
    "id": "10659",
    "value": "Portugal"
  },
  {
    "id": "10660",
    "value": "Qatar"
  },
  {
    "id": "10661",
    "value": "Romania"
  },
  {
    "id": "10662",
    "value": "Russia"
  },
  {
    "id": "10663",
    "value": "Rwanda"
  },
  {
    "id": "10664",
    "value": "Saint Kitts and Nevis"
  },
  {
    "id": "10665",
    "value": "Saint Lucia"
  },
  {
    "id": "10666",
    "value": "Saint Vincent and the Grenadines"
  },
  {
    "id": "10667",
    "value": "Samoa"
  },
  {
    "id": "10668",
    "value": "San Marino"
  },
  {
    "id": "10669",
    "value": "Sao Tome and Principe"
  },
  {
    "id": "10670",
    "value": "Saudi Arabia"
  },
  {
    "id": "10671",
    "value": "Senegal"
  },
  {
    "id": "10672",
    "value": "Serbia"
  },
  {
    "id": "10673",
    "value": "Seychelles"
  },
  {
    "id": "10674",
    "value": "Sierra Leone"
  },
  {
    "id": "10675",
    "value": "Singapore"
  },
  {
    "id": "10676",
    "value": "Slovakia"
  },
  {
    "id": "10677",
    "value": "Slovenia"
  },
  {
    "id": "10678",
    "value": "Solomon Islands"
  },
  {
    "id": "10679",
    "value": "Somalia"
  },
  {
    "id": "10680",
    "value": "South Africa"
  },
  {
    "id": "10681",
    "value": "South Korea"
  },
  {
    "id": "10682",
    "value": "South Sudan"
  },
  {
    "id": "10683",
    "value": "Spain"
  },
  {
    "id": "10684",
    "value": "Sri Lanka"
  },
  {
    "id": "10685",
    "value": "Sudan"
  },
  {
    "id": "10686",
    "value": "Suriname"
  },
  {
    "id": "10687",
    "value": "Sweden"
  },
  {
    "id": "10688",
    "value": "Switzerland"
  },
  {
    "id": "10689",
    "value": "Syria"
  },
  {
    "id": "10690",
    "value": "Tajikistan"
  },
  {
    "id": "10691",
    "value": "Tanzania"
  },
  {
    "id": "10692",
    "value": "Thailand"
  },
  {
    "id": "10693",
    "value": "Timor-Leste"
  },
  {
    "id": "10694",
    "value": "Togo"
  },
  {
    "id": "10695",
    "value": "Tonga"
  },
  {
    "id": "10696",
    "value": "Trinidad and Tobago"
  },
  {
    "id": "10697",
    "value": "Tunisia"
  },
  {
    "id": "10698",
    "value": "Turkey"
  },
  {
    "id": "10699",
    "value": "Turkmenistan"
  },
  {
    "id": "10700",
    "value": "Tuvalu"
  },
  {
    "id": "10701",
    "value": "Uganda"
  },
  {
    "id": "10702",
    "value": "Ukraine"
  },
  {
    "id": "10703",
    "value": "United Arab Emirates"
  },
  {
    "id": "10704",
    "value": "UK"
  },
  {
    "id": "10705",
    "value": "USA"
  },
  {
    "id": "10706",
    "value": "Uruguay"
  },
  {
    "id": "10707",
    "value": "Uzbekistan"
  },
  {
    "id": "10708",
    "value": "Vanuatu"
  },
  {
    "id": "10709",
    "value": "Venezuela"
  },
  {
    "id": "10710",
    "value": "Vietnam"
  },
  {
    "id": "10711",
    "value": "Yemen"
  },
  {
    "id": "10712",
    "value": "Zambia"
  },
  {
    "id": "10713",
    "value": "Zimbabwe"
  }
]
