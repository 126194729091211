import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Breadcrumbs,
  Typography,
  Paper, styled, TextField, Stack
} from "@mui/material";
import { useForm } from 'react-hook-form';
import { zodResolver } from "@hookform/resolvers/zod";
import CompanyService from "@/services/auth/CompanyService";
import { useSnackbar } from "notistack";
import { object, TypeOf, z } from "zod";
import { useNavigate } from 'react-router-dom';
import React, {useState} from "react";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import {useTranslation} from "react-i18next";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import Uploady, { useBatchAddListener } from "@rpldy/uploady";
import UploadPreview, { PreviewItem } from "@rpldy/upload-preview";
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import {asUploadButton} from "@rpldy/upload-button";
import { ReactComponent as DefaultOrganization } from '@/assets/images/DefaultOrganization.svg';
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";


const OrganAdd: React.FC = () => {
  const { t } = useTranslation();
  const organAddValidate = object({
    name: z.string().min(1, t("common.This field is required!")),
    code: z.string().min(1, t("common.This field is required!")),
    country: z.string().min(1, t("common.This field is required!"))
  })
  type OrganAddForm = TypeOf<typeof organAddValidate>;
  const organForm =  useForm<OrganAddForm>({
    resolver: zodResolver(organAddValidate),
    defaultValues: { name: "", code: "", country: "USA" },
  });
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = organForm;
  const { enqueueSnackbar } = useSnackbar();
  const [imageFile, setImageFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const MyPreview = (props: any) => {
    return (
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height="200"
          image={props.url}
          alt="Company Logo"
        />
      </Card>
    )
  };

  const name_text = t("organ.name text tip")
  const code_text = t("organ.code text tip")
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/dashboard");
  }

  const handleCreate = async ()=> {
    setLoading(true);
    const values = getValues();
    const data = {companyLogo: imageFile, ...values};
    try {
      const result = await CompanyService.companyCreation(data);
      if (result.code == "UASI0006") {
        enqueueSnackbar(t("organ.Organization Created successfully"), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
      navigate("/dashboard");
      }
    }
    finally {
      setLoading(false);
    }
  }

  const CustomUploadButton = asUploadButton(
    (props: any, ref: any) => {
      useBatchAddListener((batch) => {
        console.log(batch.items[0].file)
        if (batch.items[0].file.size > 3000000) {
          enqueueSnackbar(t("common.Photos size up to maximum 3 MB"), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
        setImageFile(batch.items[0].file)
      });
      return (
        <Button component="label" variant="contained" sx={{width: '90%', textTransform: "none"}} {...props} />
      )
    }
  );

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
          <Typography>{t("common.Create Organization")}</Typography>
        </Breadcrumbs>
      </Box>
      <Paper elevation={0} sx={{margin: '10px 10px'}}>
      <Grid container sx={{ p: '20px' }}>
        <Grid lg={5} item sx={{ height: '45vh', display: 'flex', justifyContent: 'start', padding: '8px' }}>
            <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid #AEAFB0', width: '90%', height: '100%', lineHeight: '50px', minWidth: '200px' }}>
              <Uploady>
                <Box sx={{ height: '200px' }}>
                  {imageFile ? <UploadPreview
                            PreviewComponent={MyPreview}
                          /> :
                  <DefaultOrganization/>
                  }
                </Box>
                <Box>
                {t("common.Photos size up to maximum 3 MB")}
                </Box>
                 <CustomUploadButton>{t("organ.Add photos")}</CustomUploadButton>
              </Uploady>
            </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid lg={13} item>
            <Grid container={true} rowSpacing={2}>
              <Grid item xs={12} sx={{marginTop:"22px"}}>
                <ControlledTextFiled
                  hookForm={organForm}
                  name={'name'}
                  tooltip={name_text}
                  label={t("organ.Organization Name")}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                 <ControlledTextFiled
                  hookForm={organForm}
                  name={'code'}
                  label={t("organ.Organization Code")}
                  tooltip={code_text}
                  fullWidth
                  required
                  />
              </Grid>
              <Grid item xs={12}>
                <NGInputLabel label={t("signUp.Choose your country")}/>
                <Select
                  id="country-select"
                  fullWidth
                  defaultValue={'USA'}
                  {...register("country")}
                >
                  <MenuItem value={'USA'}>{t("common.America")}</MenuItem>
                  <MenuItem value={'JPN'}>{t("common.Japan")}</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Paper>
      <Grid item xs={4} sx={{ marginLeft: "61.5%", marginTop: "70px"}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton onClick={handleCancel}/>
          <SavingButton loading={loading} onClick={handleSubmit(handleCreate)}/>
        </Stack>
      </Grid>
    </>
  )
}

export default OrganAdd;
