import React, {useRef} from "react";
import { GridColDef } from "@mui/x-data-grid";
import {Add, DeleteForever, DeleteOutline, RemoveRedEyeOutlined} from "@mui/icons-material";
import CustomDataGrid, {DataGridRef} from "../../../components/data-grid/CustomDataGrid";
import { ATTEndpoints } from "../../../services/att/endpoints";
import { useNavigate } from "react-router-dom";
import WebURL from "../../../urls";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import { Stack } from "@mui/material";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useTranslation } from "react-i18next";
import Dialog from "@/components/dialog";
import GenericService from "@/services/GenericService";
import SnackbarUtil from "@/utils/SnackbarUtil";


const TimesheetDataGrid: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const confirm = useConfirm();
  const dataGridRef = useRef<DataGridRef>()
  const columns: GridColDef[] = [
    { field: 'name', headerName: t("common.Timesheet Name"), minWidth: 200, flex: 0.3 },
    { field: 'period', headerName: t("common.Unit"), minWidth: 180, flex: 0.3 },
    { field: 'cycle', headerName: t("common.Cycle"), minWidth: 180, flex: 0.3 },
    { field: 'description', headerName: t("common.Description"), type: "string", minWidth: 180, flex: 0.4 },
    {
      field: 'actions',
      sortable: false,
      headerName: t("common.Action"),
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 0.3,
      renderCell: (cell) => {
        return (
          <Stack direction={"row"}>
            <CustomRowButton insideIcon={CalendarMonthOutlinedIcon} buttonProps={{
              key: "view",
              onClick: () => {
                navigate(WebURL.ATT_TIMESHEET_VIEW,  {state: {id: cell.id}});
              }
            }} />
            <CustomRowButton insideIcon={DeleteForever} buttonProps={{
              key: "delete",
              onClick: async () => {
                Dialog.confirm({
                  title: t("common.Delete Confirm"),
                  onConfirm: () => {
                    GenericService.destroy(ATTEndpoints.ATT_TIMESHEET_URI, `${cell.id}`).then(
                      (result) => {
                        SnackbarUtil.success(t("common.success"))
                        dataGridRef.current?.refresh();
                      });
                  }
                });
              }
            }} />
          </Stack>
        )
      }
    },
  ]
  return (
    <CustomDataGrid
      ref={dataGridRef}
      uri={ATTEndpoints.ATT_TIMESHEET_URI}
      columns={columns}
      checkboxSelection={false}
      toolbar={{
        title: t("common.Timesheet"),
        search: true,
        searchTips: t("common.Search by Timesheet Name"),
        breadcrumbs: [
          { breadcrumbName: t("common.TimeSheet"), path: WebURL.ATT_TIMESHEET }
        ],
        actions: [{
          key: 'add', icon: Add, helpTxt: t("common.add"), callback: async () => {
            navigate(WebURL.ATT_TIMESHEET_NEW);
          }
        }]
      }}
    />
  )
}
export default TimesheetDataGrid
