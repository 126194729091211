import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"
import { Box, styled } from "@mui/system";
import { Autocomplete, CircularProgress, Grid, Paper, Stack, TextField, Tooltip, Typography} from "@mui/material";
import { DMSEndpoints } from "@/services/dms/endpoints";
import GenericService from "../../../../services/GenericService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import SnackbarUtil from "@/utils/SnackbarUtil";
import GlobalAutocomplete from "@/components/scroll-select/GlobalAutoComplete";
import {LocationEndpoints} from "@/services/location/endpoints";
import { ngteco_timezones } from "@/components/resource/timezone";
import { object, string, TypeOf, z } from "zod";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getOrganSites } from "@/store/slices/Site";
import { getOrganZones } from "@/store/slices/Zone";
import ControlTextFieldReadOnly from "@/components/hook-form/ControlTextFieldReadOnly";
import ControlTextField from "@/components/hook-form/ControlTextField";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import SiteService from "@/services/location/SiteService";
import DeviceService from "@/services/dms/DeviceService";
import { CancelButton, SaveButton } from "@/components/data-grid/CustomButton";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";
import ZoneService from "@/services/location/ZoneService";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";


const DeviceEdit: React.FC = () => {
  const { t } = useTranslation();
  const deviceSchema = object({
    sn: z.string().nullish(),
    alias: z.string().min(1, t("common.This field is required!")),
    // site: z.object({
    //   id: z.string(),
    //   name: z.string(),
    //   address: z.object({
    //     timeZone: z.string().nullish()
    //   })
    // }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    // zone: z.object({
    //   id: z.string(),
    //   name: z.string()
    // }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    timezone: z.union([z.string().nullish(), z.object({
      value: z.string(),
      name: z.string()
    }).nullish()]),
    model: z.string(),
    category: z.string(),
    protocol_type: z.string(),
    status: z.string(),
    MCUVersion: z.string().nullish(),
    firmware_ver: z.string().nullish(),
    ipv4: z.string().nullish(),
    organization_name: z.string().nullish()
  })
  type deviceFormType = TypeOf<typeof deviceSchema>

  const location = useLocation()
  const state = location.state as { id: string }
  const navigate = useNavigate()
  const sitesInitial: {id: string, name: string, address: Record<string, any>}[] = useSelector((state: any) => state.site.sites);
  const zonesInitial: {id: string, name: string}[] = useSelector((state: any) => state.zone.zones);
  const timezoneList = ngteco_timezones();
  const [timeZone, setTimeZone] = useState<string>();
  const [timezoneEmpty, setTimezoneEmpty] = useState<boolean>(false);
  const deviceForm = useForm<deviceFormType>({
    resolver: zodResolver(deviceSchema),
    defaultValues: {
      alias: "",
      sn: "",
      // site: {id: "", name: ""},
      // zone: {id: "", name: ""},
      timezone: "",
      model: "",
      category: "",
      protocol_type: "",
      status: "",
      ipv4: "",
      MCUVersion: "",
      firmware_ver: "",
      organization_name: ""
    }
  })
  const [siteInputValue, setSiteInputValue] = useState<any>('');
  const [siteValue, setSiteValue] = useState<any>(null);
  const [selectSite, setSelectSite] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  
  const [zones, setZones] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [zoneInputValue, setZoneInputValue] = useState<any>('');
  const [zoneValue, setZoneValue] = useState<any>(null);
  const [selectZone, setSelectZone] = useState<boolean>(true);

  const onEdit = async () => {
    setSaveLoading(true)
    try {
      const isValid = await deviceForm.trigger()
      if (isValid && !!zoneValue && !!siteValue) {
        setSelectZone(true);
        setSelectSite(true);
        const values = deviceForm.getValues();
        const origin = {
          deviceAlias: values.alias,
          siteId: siteValue.id,
          zoneId: zoneValue.id
        }
        const site_res = timezoneEmpty ? await SiteService.update(siteValue.id, {address: {timeZone, city: 'default', addressLine1: 'default', siteId: siteValue.id}}) : {}
        const device_res = await DeviceService.update(state.id || '', origin);
        SnackbarUtil.success(t("common.Success"))
        navigate(WebURL.DMS_DEVICE);
      } else {
        !zoneValue && setSelectZone(false);
        !siteValue && setSelectSite(false);
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSaveLoading(false)
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getOrganSites());
    dispatch<any>(getOrganZones());
  }, [])
  useEffect(() => {
    GenericService.retrieve(DMSEndpoints.DMS_DEVICE_URI, state.id || "").then((res) => {
      const device = res.data;
      const site = sitesInitial.find(item => item.id === device.site)
      const zone = zonesInitial.find(item => item.id === device.zone)
      const timezone = timezoneList.find(item => item.value === site?.address?.timeZone)
      deviceForm.reset({
        // site: site || {id: device.site, name: device.site_name},
        // zone: zone || {id: device.zone, name: device.zone_name},
        timezone: timezone?.name,
        alias: device.alias,
        sn: device.sn,
        model: device.model,
        ipv4: device.ipv4 || "",
        status: device.status === "1" ? t("dms.Online") : t("dms.Offline"),
        category: device.category,
        protocol_type: device.protocol_type,
        MCUVersion: device.MCUVersion,
        firmware_ver: device.firmware_ver,
        organization_name: device.organization_name
      })
      if (device.site) {
        setSiteValue(site || {id: device.site, name: device.site_name});
        setSiteInputValue(device.site_name);
        ZoneService.list({keyword: device.site, current: 1, pageSize: 100}).then((zones) => {
          const {data} = zones.data;
          setZones(data);
        });
      }
      if (device.zone_name) {
        setZoneValue(device.zone_name);
        setZoneValue(zone || {id: device.zone, name: device.zone_name});
      }
    })
  }, [sitesInitial, zonesInitial])

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.DMS_DEVICE, label: t("common.Device") },
        { label: t("common.Edit device") },
      ]} />
      <Box style={{marginTop: "1vh"}}>
        <Typography>{t("common.Device information")}</Typography>
      </Box>
      <Paper elevation={0} style={{ minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', paddingTop: '16px' }}>
        <Grid container item spacing={3}>
          <Grid item xs={4}>
            <Typography>{t("common.Device Model")}</Typography>
            <ControlTextFieldReadOnly label="" form={deviceForm} name="model" />
          </Grid>
          <Grid item xs={4}>
            <Typography>{t("common.Device Alias")}</Typography>
            <ControlTextField label="" name="alias" form={deviceForm} />
          </Grid>
          <Grid item xs={4}>
            <Typography>{t("common.Serial Number")}</Typography>
            <ControlTextFieldReadOnly label="" form={deviceForm} name="sn" />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={4}>
            <Typography>{t("common.IP Address")}</Typography>
            <ControlTextFieldReadOnly label="" form={deviceForm} name="ipv4" />
          </Grid>
          <Grid item xs={4}>
            <Typography>{t("common.Bind Organization")}</Typography>
            <ControlTextFieldReadOnly label="" form={deviceForm} name="organization_name" />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={sitesInitial}
              loading={loading}
              value={siteValue}
              onChange={(_, value) => {
                const timezone = value?.address.timeZone;
                if (timezone) {
                  deviceForm.setValue('timezone', timezoneList.find(item => item.value === timezone)?.name || `UTC${timezone}`)
                  setTimezoneEmpty(false);
                  setTimeZone(undefined);
                } else {
                  setTimezoneEmpty(true);
                }
                setSiteValue(value);
                setZones([]);
                setZoneInputValue('');
                setZoneValue(null);
                ZoneService.list({keyword: value.id, current: 1, pageSize: 100}).then((zones) => {
                  const {data} = zones.data;
                  setZones(data);
                });
              }}
              inputValue={siteInputValue}
              onInputChange={(_, v) => setSiteInputValue(v)}
              getOptionLabel={option => option.name}
              renderInput={(params) =>
                <>
                  <NGInputLabel label={t("dms.Bind Site")} required={true}/>
                  <TextField
                    {...params}
                    // label={t("dms.Bind Site")}
                    required={true}
                    error={!selectSite}
                    helperText={(!selectSite && "Required") || " "}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "false",
                      endAdornment: (
                        <React.Fragment>
                          {loading && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                </>}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={4}>
            {timezoneEmpty
              ? <ControlAutoComplete
                  label="Site Timezone"
                  name={'timezone'}
                  form={deviceForm}
                  options={timezoneList}
                  groupBy={(option) => option.group}
                  isOptionEqualToValue={(option, value) => {
                    console.log("比较", option, value);
                    return option.value === value.value;
                  }}
                  onChange={({value}) => {
                    setTimeZone(value);
                  }}
                  required
                />
              : <>
                  <Typography>{t("common.Site Time zone")}</Typography>
                  <ControlTextFieldReadOnly label="" name="timezone" form={deviceForm} />
                </>
            }
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={zones}
              loading={loading}
              value={zoneValue}
              onChange={(_, v) => setZoneValue(v)}
              inputValue={zoneInputValue}
              onInputChange={(_, v) => setZoneInputValue(v)}
              getOptionLabel={option => option.name}
              renderInput={(params) =>
                <>
                  <NGInputLabel label={t("dms.Bind Zone")} required={true}/>
                  <TextField
                    {...params}
                    // label={t("dms.Bind Zone")}
                    required={true}
                    error={!selectZone}
                    helperText={(!selectZone && "Required") || " "}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "false",
                      endAdornment: (
                        <React.Fragment>
                          {loading && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                </>}
            />
          </Grid>
        </Grid>
      </Paper>

      <Box style={{padding:'10px 0 0 0'}}>
        <Typography>{t("common.Firmware Information")}</Typography> 
      </Box>
      <Paper elevation={0} style={{ minHeight: '120px', backgroundColor: '#fff', borderRadius: '8px', paddingTop: '16px' }}>
        <Grid container item spacing={3}>
          <Grid item xs={4}>
            <Typography>{t("common.Protocol Type")}</Typography>
            <ControlTextFieldReadOnly label="" form={deviceForm} name="protocol_type" />
          </Grid>
          <Grid item xs={4}>
            <Typography>{t("common.Firmware Version")}</Typography>
            <ControlTextFieldReadOnly label="" form={deviceForm} name="firmware_ver" />
          </Grid>
        </Grid>
      </Paper>
      <Grid style={{'padding': '35px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton/>
          <SaveButton onClick={deviceForm.handleSubmit(onEdit)} />
        </Stack>
      </Grid>
    </>
  )
}
export default DeviceEdit;
