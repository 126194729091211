import React from "react";
import Box from "@mui/material/Box";
import {KeyboardArrowDown} from "@mui/icons-material";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {Grid} from "@material-ui/core";
import i18n from "@/config/i18n";
import _ from 'lodash'
import { Languages } from "@/utils/Languages";


const LanguageSelect: React.FC = () => {
  const popupState = usePopupState({variant: "popover", popupId: "language"});
  const languages = Languages
  const language = _.find(languages, (item) => { return item.code === i18n.language})?.title
  return (
    <Box>
      <Box
        style={{display: "flex", justifyContent: "flex-end", alignItems: "center", padding: '8px 32px'}}
      >
        <Box {...bindTrigger(popupState)} sx={{display: "flex", alignItems: "center", cursor: "pointer"}}>
          <Typography>{language ? language : "Global English"}</Typography>
          <IconButton sx={{p: 0}}>
            <KeyboardArrowDown
              sx={{
                fontSize: {xs: '14px', lg: '28px'},
                margin: {xs: '2px 4px 4px 4px', lg: '2px 4px 4px 4px'}
              }}
            />
          </IconButton>
        </Box>

      </Box>
      <Menu
        sx={{ mt: '45px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted={true}
        {...bindMenu(popupState)}
      >
        {languages.map((item) => (
          <MenuItem key={item.code} onClick={async () => {
            localStorage.setItem("i18nextLng", item.code);
            await i18n.changeLanguage(item.code);
            popupState.close()
          }}>{item.title}</MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
export default LanguageSelect
