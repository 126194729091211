import React, { useState, useEffect, useRef } from "react";
import { ATTEndpoints } from "../../../../../services/att/endpoints";
import CustomDataGrid, { DataGridRef } from "../../../../../components/data-grid/CustomDataGrid";
import {
  GridColDef,
  GridFilterModel,
  GridFilterOperator,
  GridFilterItem,
  GridFilterInputValueProps,
  GridRowHeightParams,
  GridRenderCellParams,
  GridSelectionModel
} from "@mui/x-data-grid";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  Typography,
  Alert,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import WebURL from "@/urls";
import TimeSelectDrawer from "@/pages/att/Timecard/TimeSelectDrawer";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@/components/dialog"

const SUBMIT_FILTER_STROKE_TIME = 500;

function InputNumberInterval(props: GridFilterInputValueProps) {
  const { t } = useTranslation();
  const { item, applyValue } = props;

  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<[Date, Date]>([item.value[0], item.value[1]]);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  // useEffect(() => {
  //   const itemValue = item.value || [undefined, undefined];
  //   const today = new Date()
  //   itemValue[0]=new Date(today.getFullYear(), today.getMonth(), 1)
  //   setFilterValueState(itemValue);
  // }, [item.value]);

  const updateFilterValue = (lowerBound: Date, upperBounding: Date) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBounding]);

    filterTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: [lowerBound, upperBounding] });
    }, SUBMIT_FILTER_STROKE_TIME)
  };

  const handleUpperFilterChange = (event: any) => {
    const newUpperBound = event;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event: any) => {
    const newLowerBound = event;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        width: "240px"
      }}
    >
      <DatePicker
        label={t("common.Start Date")}
        value={filterValueState[0] || new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
        maxDate={filterValueState[1]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleLowerFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
      <DatePicker
        label={t("common.End Date")}
        value={filterValueState[1]}
        minDate={filterValueState[0]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleUpperFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
    </Box>
  )
}

const punchRecordStyle = {
  '& .MuiPaper-root': { width: '60vw', top: '-20vh' },
  '.MuiDialog-paper': { maxWidth: '100vw' },
};

const PunchRecordGrid: React.FC = () => {
  const { t } = useTranslation();
  const today = new Date()
  const firstDay = new Date()
  firstDay.setDate(1)

  const [mended, setMended] = useState<boolean>(false);
  const dataGridRef = useRef<DataGridRef>();
  const [info, setInfo] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    date_range: [
      firstDay.toISOString().slice(0, 10),
      today.toISOString().slice(0, 10)
    ]
  });
  const [filterDate, setFilterDate] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        columnField: 'att_date',
        value: [firstDay.toISOString().slice(0, 10), today.toISOString().slice(0, 10)],
        operatorValue: "between",
      }
    ]
  })

    const InfoDialog = () => {
    return (
      <MuiDialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={punchRecordStyle}
      >
        <DialogTitle>
          {t("common.Usage Tips")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ lineHeight: '25px', color: 'gray' }}>
          <Box sx={{p:1}}> 1. {t("dms.uploadTransactionTip")}</Box>
          <Box sx={{p:1}}> 2. {t("dms.punchRecordTip")}</Box>
        </DialogContent>
      </MuiDialog>
    )
  }


  const punchDateOperators: GridFilterOperator[] = [
    {
      label: t("common.Between"),
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem, column) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        return ({ value }) => {
          return true;
        };
      },
      InputComponent: InputNumberInterval
    },
  ];

  const AttRecordField = (params: GridRenderCellParams) => {
    // The width occupied by one transaction is about 63px, with 9 one line by default.
    const res: any[] = [];
    const more_res: any[] = [];
    const current_width = params?.colDef?.computedWidth
    const wrap_index = current_width? Math.floor(current_width/63) : 9
    const max_display_index = wrap_index * 3 - 3
    const readMoreOnClick = () => {
      Dialog.ok({
        title: t("More Records"),
        content: more_res,
      });
    };
    params.row.attendance_status.forEach((element: any, index: number) => {
      const val = element.punch_time.split(":");
      const waited_push_item = element.status === "warning"
        ? <Grid key={index} component={'a'} color={mended ? "red" : "black"}>{`${val.join(":")}; `}</Grid>
        : <Grid key={index} component={'a'} color={"black"}>{`${val.join(":")}; `}</Grid>
      if (index < max_display_index){
        res.push(waited_push_item)
        if (index > 1 && (index+1) % wrap_index === 0){ // default index is 0. Start a new line
          res.push(<br key={"br"+index}></br>)
        }
      }else{
        more_res.push(waited_push_item)
      }
    });
    return (
      <Grid>
        {res}
        {more_res.length>0 ? <Button size="small" onClick={readMoreOnClick}>{t("More Records...")}</Button> :<></> }
      </Grid>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'employee_name', headerName: t("common.Person Name"), minWidth: 200, flex: 0.2, filterable: false,
      renderCell: (params) => {
        return (
          <Grid style={{ display: "flex", alignItems: "center" }}>
            {/* <AvatarIcon name={params.row.first_name} /> */}
            <Box style={{ padding: '0 8px' }}>{params.row.first_name} {params.row.last_name}</Box>
          </Grid>
        )
      }
    },
    { field: 'employee_code', headerName: t("common.Person ID"), type: "string", minWidth: 120, flex: 0.1, filterable: false },
    {
      field: 'att_date', headerName: t("common.Punch Date"), type: "date", minWidth: 150,
      filterOperators: punchDateOperators,
    },
    {
      field: 'attendance_status', headerName: t("common.Attendance record"), flex: 1, minWidth: 400, filterable: false,
      renderCell: AttRecordField
    },
  ]

  const toolbar = {
    title: t("common.Timecard"),
    searchTips: t("common.Search by Person ID, Person Name"),
    search: true,
    info: {setDisplay: setInfo,},
    breadcrumbs: [
      { breadcrumbName: t("common.View Attendance Punch"), path: WebURL.ATT_TIMECARD_TIMECARD }
    ],
    extraComponent:
      <Grid sx={{ paddingRight: "1vw" }}>
        <FormControlLabel
          labelPlacement="start"
          label={t("common.Show mended records")}
          control={<Switch color="primary" checked={mended} onChange={() => setMended(!mended)} />}
        />
      </Grid>
    ,
    extraComponentOnLeft: <TimeSelectDrawer/>
  }

  return (
    <>
    <Box
      sx={{
        '& .MuiDataGrid-row-height-no-limit': {
          maxHeight: '180px!important'
        },
      }}
    >
      <CustomDataGrid
        columns={columns}
        ref={dataGridRef}
        uri={ATTEndpoints.ATT_PUNCH_RECORD_URI}
        requestParams={filterParams}
        // getRowHeight={() => 'auto'}
        getRowHeight={({ model }: GridRowHeightParams) => {
          if (model.attendance_status.length > 11) {
            const calc_height = 30 * Math.ceil(model.attendance_status.length/11)
            if (calc_height > 30*3)
              return 30*3
            return calc_height ;
          }
          return null;
        }}
        getRowClassName={() => `MuiDataGrid-row-height-no-limit`}
        filterModel={filterDate}
        onFilterModelChange={(model) => {
          if (typeof (model.items[0].value[0]) == 'object') {
            model.items[0].value[0] = model.items[0].value[0].toISOString().slice(0, 10)
          }
          if (typeof (model.items[0].value[1]) == 'object') {
            model.items[0].value[1] = model.items[0].value[1].toISOString().slice(0, 10)
          }
          setFilterDate(model)
          setFilterParams({
            date_range: [
              model.items[0].value[0],
              model.items[0].value[1]
            ]
          })
          dataGridRef.current?.refresh(true);
        }}
        toolbar={toolbar}
        localeText={{
          filterPanelColumns: t("common.Columns"),
          filterPanelOperators: t("common.Operators"),
        }}
      />
    </Box>
    <InfoDialog />
    </>
  )
}
export default PunchRecordGrid
