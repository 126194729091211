import CustomDataGrid, {DataGridRef} from "@/components/data-grid/CustomDataGrid";
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import Dialog from "@/components/dialog";
import DepartmentService from "@/services/hr/DepartmentService";
import { HREndpoints } from "@/services/hr/endpoints";
import WebURL from "@/urls";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { Add, Edit, DeleteForever } from "@mui/icons-material";
import { Backdrop, Breadcrumbs, CircularProgress, Grid, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, {useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {AuthEndpoints} from "@/services/auth/endpoints";


const DepartmentDataGrid: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dataGridRef = useRef<DataGridRef>()
  const columns: GridColDef[] = [
    {field: 'name', headerName: t("common.Department Name"), minWidth: 300 },
    {field: 'code', headerName: t("common.Department Code"), minWidth: 300 },
    {
      field: 'actions', headerName: t("common.action"), headerAlign: 'center', align: 'center', flex: 150,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <Grid sx={{marginRight: "20px"}}>
              <CustomRowButton
                insideIcon={Edit}
                buttonProps={{
                   key: "edit",
                  onClick: () => {
                    navigate(WebURL.HR_DEPARTMENT_VIEW, {state: {id: e.id}});
                  }
                }}
              />
            </Grid>
            <Grid>
              <CustomRowButton
                insideIcon={DeleteForever}
                buttonProps={{
                  key: "delete",
                  onClick: () => (
                    Dialog.confirm({
                      title: t("hr.department.Delete Department?"),
                      content: t("hr.department.Delete Confirm"),
                      onConfirm: () => {
                        DepartmentService.destroy((e.id).toString()).then(
                          (result) => {
                            SnackbarUtil.success(t('common.success'));
                            dataGridRef.current?.refresh();
                          });
                      }
                    })
                  )
                }}
              />
            </Grid>
          </Stack>
        )
      }
    }
  ]


  return (
    <>
      <CustomDataGrid
        ref={dataGridRef}
        uri={AuthEndpoints.AUTH_USER_ADMIN_COMPANY_LIST}
        columns={columns}
        checkboxSelection={false}
        toolbar={{
          title: t("common.Department"),
          searchTips: t("common.Search by Department Name, Department Code"),
          breadcrumbs: [
            {breadcrumbName: t("common.Department"), path: WebURL.HR_DEPARTMENT }
          ],
          actions: [{
            key: 'add', icon: Add, helpTxt: t("common.add"), callback: async () => {
              navigate(WebURL.HR_DEPARTMENT_NEW);
            }
          }]
        }}
      />
    </>
  )
}

export default DepartmentDataGrid;
