import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import CompanyService from "../../../services/auth/CompanyService";
import { useTranslation } from "react-i18next";
import { BarChart } from '@mui/x-charts/BarChart';
import DeviceService from "@/services/dms/DeviceService";

type qtyChartStatus = {
  title: string;
  model: string;
}
const QtyChartStatus: React.FC<qtyChartStatus> = (props) => {
  const { t } = useTranslation();
  const [uData, SetuData] = useState<any[]>([0,0,0,0,0,0,0,0,0,0,0,0]);
  const [series_id, SetSeries_id] = useState("")
  const frontCardStyle = {
    backgroundColor: "#ffffff",
    maxHeight:295
  }
  const xLabels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  useEffect(() => {
    if (props.model == "device"){
      DeviceService.GetWhiteDeviceQtyRecord().then(res => {
        SetSeries_id("deid")
        console.log("!!!!!!!!!!!!", res.data)
        SetuData(res.data.data)
    })
    }
    else if (props.model == "company"){
      CompanyService.CompanyQtyRecord().then(res => {
        SetSeries_id("coid")
        console.log("???????????", res.data)
        SetuData(res.data)
    })
    }
  }, [])
  return (
    <Card sx={frontCardStyle}>
      <CardHeader
        title={
          <Typography variant={"h5"} color={"#565454"}>
            {props.title}
          </Typography>
        }
        sx={{
          borderBottom: "2px solid #ffffff"
        }}
      />
      <CardContent>
        <BarChart
            width={800}
            height={230}
            series={[
              { data: uData, id: series_id, color: '#0084d0', type: "bar"},
            ]}
            xAxis={[{ data: xLabels, scaleType: 'band' }]}
          />
        </CardContent>
    </Card>
  )
}
export default QtyChartStatus
