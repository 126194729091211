import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import { Box, styled } from "@mui/system";
import {TextField, Grid, Paper, Typography} from "@mui/material";
import { DMSEndpoints } from "@/services/dms/endpoints";
import GenericService from "../../../../services/GenericService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";
import DeviceService from "@/services/dms/DeviceService";

type DeviceProps = {
  sn: string,
  alias: string,
  site: string,
  zone: string,
  site_name?: string,
  zone_name?: string,
  organization_name?: string,
  timezone?: string,
  ipv4?: string,
  MCUVersion?: string,
  firmware_ver?: string,
  model: string,
  category: string,
  protocol_type: string,
  status: string,
  ip?: string,
  userCount?: string,
  fpCount?: string,
  visLightFaceCount?: string,
}

type InputItemProps = {
  title: string,
  value: any
  tip?: string,
  edit?: boolean,
  disabled?: boolean
}

const InputItem: React.FC<InputItemProps> = (props) => {
  let textValue = ""
  if (typeof(props.value) != 'undefined')
    textValue = props.value
  return (
    <Box display='row' paddingBottom={2}>
      <Typography>{props.title}</Typography>
      <TextField disabled={true} value={textValue} sx={{width: "calc(100% - 10px)"}}/>
    </Box>
  )
}

const EditInputItem: React.FC<InputItemProps> = (props) => {
  const [val, setVal] = React.useState('');
  useEffect(() => {
    setVal(props.value)
  }, [props])
  return (
    <Box display='row' paddingBottom={2}>
      <Typography sx={{paddingBottom: '12px'}}>{props.title}</Typography>
      <TextField disabled={props.disabled} sx={{width: "calc(100% - 10px)"}} value={val}
        onChange={(event) => setVal(event.target.value)}
      />
    </Box>
  )
}

EditInputItem.defaultProps = {
  disabled: true
}

const DeviceView: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation()
  const state = location.state as { id: string }
  const [device, setDevice] = useState<DeviceProps>();
  useEffect(() => {
    DeviceService.GetWhiteDeviceInfo( {"id": state.id}).then((res) => {
      setDevice(res.data.data);
    })
  }, [])

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.DMS_DEVICE, label: t("common.Device") },
        { label: t("common.Device detail") },
      ]} />
      <Box sx={{ paddingTop: '10px' }}>
        <Box><Typography>{device?.model}</Typography></Box>
        <Box><Typography>SN: {device?.sn}</Typography></Box>
      </Box>
      <Paper elevation={0} style={{ minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', paddingTop: '16px' }}>
        <Grid container item spacing={3}>
          <Grid item xs={4}>
            <InputItem title={t("common.Device Alias")} value={device?.alias}/>
          </Grid>
          <Grid item xs={4}>
            <InputItem title={t("common.IP Address")} value={device?.ip} />
          </Grid>
          <Grid item xs={4}>
            <InputItem title={t("dms.User Qty.")} value={device?.userCount} />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={4}>
            <InputItem title={t("dms.Face Qty.")} value={device?.visLightFaceCount} />
          </Grid>
          <Grid item xs={4}>
            <InputItem title={t("dms.Fingerprint Qty.")} value={device?.fpCount} />
          </Grid>
          <Grid item xs={4}>
            <InputItem title={t("dms.Status")} value={device?.status==='1'? t("dms.Online"): t("dms.Offline") } />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={4}>
            <InputItem title={t("common.Bind Organization")} value={device?.organization_name}/>
          </Grid>
          <Grid item xs={4}>
            <InputItem title={t("dms.Bind Site")} value={device?.site_name} />
          </Grid>
          <Grid item xs={4}>
            <InputItem title={t("dms.Bind Zone")} value={device?.zone_name} />
          </Grid>
        </Grid>
      </Paper>
      <Box style={{padding:'10px 0 10px 0'}}>
        <Typography>{t("common.Firmware Information")}</Typography>
      </Box>
      <Paper elevation={0} style={{ minHeight: '120px', backgroundColor: '#fff', borderRadius: '8px', paddingTop: '16px' }}>
        <Grid container item spacing={3}>
          <Grid item xs={4}>
            <InputItem title={t("common.Protocol Type")} value={device?.protocol_type} />
          </Grid>
          <Grid item xs={4}>
            <InputItem title={t("common.Firmware Version")} value={device?.firmware_ver}/>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
export default DeviceView;
