import React from "react";
import {Box, styled, Typography} from "@mui/material";


const BoldTypography = ({children}: {children: string}) => {
  return (
    <Typography variant="body1" sx={{color: "gray", fontWeight: "bold"}} gutterBottom>{children}</Typography>
  )
}

const ContentTypography = ({children}: {children: string}) => {
  return (
    <Typography sx={{color: "gray"}} gutterBottom>{children}</Typography>
  )
}

const H6 = ({children}: {children: string}) => {
  return (
    <Typography variant="h6" sx={{color: "gray"}} gutterBottom>{children}</Typography>
  )
}

function NGPrivacyPolicy(){
  return (
    <Box sx={{ padding: "10px" }}>
      <BoldTypography>This Agreement applies only to the Ngteco product or service (hereinafter referred to as: Ngteco).</BoldTypography>
      <ContentTypography>Last updated: December, 2022.</ContentTypography>
      <ContentTypography>If you have any questions, comments, or suggestions, please contact your distributor or branch office in your country or contact us at the following contact information.</ContentTypography>
      <ContentTypography>Email: service@zkteco.com</ContentTypography>
      <ContentTypography>If you are a minor, please consult your guardian, and ask the guardian to accompany you to read and understand the Agreement.</ContentTypography>
      <ContentTypography>This Policy will help you understand the following:</ContentTypography>
      <ContentTypography>1. Personal information collection rules</ContentTypography>
      <ContentTypography>● How we protect your personal information</ContentTypography>
      <ContentTypography>● Your rights</ContentTypography>
      <ContentTypography>● How we handle personal information of minors</ContentTypography>
      <ContentTypography>● How this Policy is updated</ContentTypography>
      <ContentTypography>● How to contact us</ContentTypography>
      <ContentTypography>ZKTeco Co., Ltd., and its affiliates (hereinafter referred to "ZKTeco", or "Company" or "We") understand acknowledges the importance of personal data and will do everything possible to protect your personal information. We are committed to preserving your trust in us by protecting your personal information based on the following principles: responsibility in accordance with authority, purpose specification, informed consent, minimal necessary, security safeguard, subject participation, openness and transparency, etc. ZKTeco also commits to protect your personal information by implementing appropriate security measures in accordance with industry accepted security standards.</ContentTypography>
      <ContentTypography>Before using any products (or services), please read this Policy carefully and make sure you have fully understood and agreed to this Policy. By using any products or services, you acknowledge that you have fully understood and agreed to this Policy.</ContentTypography>
      <H6>Definitions</H6>
      <ContentTypography>1. Ngteco refers to cloud services developed and operated by the Ngteco platform, including organization management, device management, video monitoring, intelligent identification, event playback. These services can be deployed in the cloud, including websites, hardware products, and mobile devices (apps).</ContentTypography>
      <ContentTypography>2. Ngteco Provider refers to ZKTeco and local branch offices, local partner, the company that developed and provides Ngteco services and hardware devices.</ContentTypography>
      <ContentTypography>3. Personal Information refers to information recorded electronically or otherwise that can be used alone or in combination with other information to identify the identification and activities of a particular natural person. Such information includes name, mobile phone number, email address, device location, country, employment information (employee number), corporate information (corporate name and business identification number). All the above information is anonymized.</ContentTypography>
      <ContentTypography>4. Personal Information Controller which organization or individual that has the authority to determine the purpose and manner of handling personal information. The personal information controllers referred to in this policy agreement are the owners/administrators of the enterprise/organization of Ngteco products.</ContentTypography>
      <BoldTypography>5. Local storage: The private information stored in the device is encrypted and stored using industry-standard encryption measures. The information controller of local storage is the owner/administrator of the enterprise/organization. The enterprise/organization allocates and authorizes the access to and use of computers or devices on which Ngteco products are installed. That is, the enterprise/organization controls the personal information and data stored on the local server.</BoldTypography>
      <H6>I Personal information collection rules</H6>
      <H6>(1) Which of your personal information will be collected by enterprise/organization owners or ZKTeco</H6>
      <ContentTypography>We will collect and use your personal information for the following purposes:</ContentTypography>
      <ContentTypography>1. To Help You Activate Ngteco accounts.</ContentTypography>
      <ContentTypography>To register and activate these services, you must provide basic information such as your company name, contact, email address, and phone number, so that we can provide you with services related to employee registration and visitor booking.</ContentTypography>
      <ContentTypography>2. Providing You with Ngteco Services.</ContentTypography>
      <ContentTypography>1) Information to Provide by You In using our services, you may provide feedback to help us better understand your experience and needs, so as to better improve our surveys.</ContentTypography>
      <ContentTypography>2) Information We Collect During Your Use of the Service To provide you with services, pages, and search results that better meet your needs; understand product suitability; and identify any issues with your account, we will collect information about the products and/or services you use, along with how you use them. This information includes:</ContentTypography>
      <ContentTypography>Device Information: We receive and record information about the device you are using (e.g., device model, operating system version, device settings, unique device identifier, and other hardware and software characteristics), Wi-Fi connections, Bluetooth, and base stations that can provide relevant information) based on the specific permissions you granted during the installation and use of the software.</ContentTypography>
      <ContentTypography>Log Information: When you use products or services provided by our website or client end, we will automatically collect detailed use information of our services and save them as relevant web logs. For example, your search and query content, IP address, browser type, language used, date and time of visit, and the records of webpages visited.</ContentTypography>
      <BoldTypography>Separate device and log information cannot be used to identify particular natural person.</BoldTypography>
      <ContentTypography>If we combine such non-personal information with other information to identify a specific natural person, or use this information in combination with personal information, such non-personal information will be treated as personal information during the combined use. We will anonymize and de-identify such personal information unless we obtain your authorization or are otherwise required by laws and regulations.</ContentTypography>
      <ContentTypography>When you contact us, we may save your communication or call records, content, or contact information to better help you solve the problem, contact you in the future, or to help us solve related problems.</ContentTypography>
      <ContentTypography>3. Security</ContentTypography>
      <ContentTypography>To prevent, detect, and investigate fraud, infringement, breach of security, unlawfulness, or violations of agreements, policies, or rules with us and/or our partners, we may collect or integrate your user information, service usage information, device information, log information, and information that we and/or our partners have obtained your authorization to share or that is shared under the law.</ContentTypography>
      <ContentTypography>If we cease to operate Ngteco services, we will promptly cease the continued collection of information about you and your employees and will delete or anonymize your personal information in our possession.</ContentTypography>
      <H6>(2) How we use your personal information</H6>
      <ContentTypography>Your information is collected to provide you with services, and to improve the quality of those services. To this end, we will use your information for the following purposes:</ContentTypography>
      <ContentTypography>1. To provide you with Ngteco products or services, and to maintain, improve, and optimize these services and your user experience.</ContentTypography>
      <ContentTypography>2. To prevent, discover, and investigate fraud, infringement, acts endangering security, violations of laws and our agreements, policies, or rules, and to protect you, other users, or the public, along with us and our legitimate rights and interests, we may use or integrate your user information, service use information, device information, log information and information that was obtained by us, our partners, or shared under the law to comprehensively determine risks of your account and transactions, verify identities, detect and prevent security incidents, and take the necessary recording, auditing, analyzing, and disposing measures according to relevant laws.</ContentTypography>
      <ContentTypography>3. We may process your information or combine it with information from other services for the purpose of providing you with a more personalized service, such as to recommend content that may be of interest to you, including but not limited to sending you information about Ngteco services, presenting you with personalized third-party promotions through the system, or sharing information with Ngteco partners with your consent so that they may send you information about their products and services.</ContentTypography>
      <ContentTypography>4. If you do not provide this information, it will not affect your use basic function of the products and services.</ContentTypography>
      <H6>(3) How we use Cookies</H6>
      <ContentTypography>1. Cookies</ContentTypography>
      <ContentTypography>Cookies and similar technologies are widely used in the Internet. To ensure the smooth operation of our website, we will store a small data file named Cookie in your computer or mobile device. A Cookie typically contains identifiers, site names, and some numbers and characters. With the Cookie, our website can store your preference and other data. We will not use Cookies for any other purpose than that specified in this Policy. You may manage the Cookie according to your own preference or delete it. You may choose to delete all Cookies saved in your computer, and most of the web browsers have a feature to block the Cookies. But if you do this, you will need to change the user settings each time you visit our website.</ContentTypography>
      <H6>(4) How we share, transfer, and disclose your personal information</H6>
      <BoldTypography>1. Share</BoldTypography>
      <ContentTypography>Without your explicit consent prior, we will not share your personal information with any other company, organization and individual.</ContentTypography>
      <ContentTypography>We may share your personal information with an external institution if required by laws and regulations or government authorities.</ContentTypography>
      <BoldTypography>2. Transfer</BoldTypography>
      <ContentTypography>We will not transfer your personal information to any other third-party company, organization or individual, except under the following circumstances:</ContentTypography>
      <ContentTypography>a) Transfer with your explicit consent: with your explicit consent, we will transfer your personal information to other parties;</ContentTypography>
      <ContentTypography>b) If any merger, acquisition or bankruptcy process involves transfer of your personal information, we will request the new company or organization in possession of your personal information to continue to be bound by the personal information protection policy, or we will request the new company or organization to seek your permission again.</ContentTypography>
      <BoldTypography>3. Public disclosure</BoldTypography>
      <ContentTypography>We will only disclose your personal information in the following circumstances:</ContentTypography>
      <ContentTypography>a) With your explicit consent;</ContentTypography>
      <ContentTypography>b) Permitted by Applicable Law: we may disclose your personal information in cases where such disclosure is required by laws, legal proceedings, litigation, or government authorities, including in cases:</ContentTypography>
      <BoldTypography>●  Related to personal information controllers performance of obligations prescribed by laws and regulations;</BoldTypography>
      <BoldTypography>●  Directly related to national security or national defense security;</BoldTypography>
      <BoldTypography>●  Directly related to public safety, public health or vital public interests;</BoldTypography>
      <BoldTypography>●  Directly related to crime investigation, prosecution, trial and judgment execution;</BoldTypography>
      <BoldTypography>●  Where such disclosure is necessary for protecting the vital legitimate interests such as life and property of the subject of personal information or any other individual while it is difficult to obtain the consent therefrom;</BoldTypography>
      <BoldTypography>●  Where the personal information involved is disclosed to the public by the subject itself;</BoldTypography>
      <BoldTypography>●  Where such disclosure is necessary for signing and performing the contract concerned according to the requirements of the subject of personal information;</BoldTypography>
      <BoldTypography>●  Where the personal information is collected from legally and publicly disclosed information, such as legal news reports and publicized government information;</BoldTypography>
      <BoldTypography>●  Where such disclosure is necessary for maintaining safe and stable operation of the products/services provided, such as identification or disposal of failures of products/services;</BoldTypography>
      <BoldTypography>●  Where the personal information controller is a news agency and such disclosure is necessary for legal news reporting;</BoldTypography>
      <BoldTypography>●  Where the personal information controller is an academic research institute, and such disclosure is necessary for statistics or academic research in the public interest, and the personal information contained in the results of academic research or description provided externally is de-identified.</BoldTypography>
      <ContentTypography>Please Note: According to law, sharing, transferring, or disclosing personal information does not include the scenario in which personal information is de-identified in such a way that the recipient of such information cannot restore the information or re-identify the subject of personal information before it is shared, transferred, or disclosed. As a result, we may store or process such information without notifying you or obtaining your consent.</ContentTypography>
      <H6>II How we protect your personal information</H6>
      <ContentTypography>(1) We take the security of personal data seriously. We use appropriate physical, managerial and technical safeguards to protect your personal data from unauthorized access, disclosure, use, modification, damage or loss. For example, we use encryption technology to ensure the confidentiality of data; we use protection mechanisms to prevent malicious attacks on data; we deploy access control mechanisms to ensure that only authorized personnel have access to personal data; and we conduct security and privacy training courses to enhance employee awareness of the importance of protecting personal data. We will do our best to protect your personal data, but please note that no security measure can be foolproof.</ContentTypography>
      <ContentTypography>(2) We will retain your personal data for as long as necessary to achieve the purposes described in this policy, unless we are required or permitted by law to extend the retention period or are permitted by law to do so. Because the period of data storage may vary based on different scenarios and products and services, the criteria we use to determine the retention period include: the period of time required to retain personal data to fulfill the business purpose, including providing products and services, maintaining corresponding transaction and business records, controlling and improving the performance and quality of products and services, ensuring the security of systems, products and services, responding to possible user inquiries or complaints, problem location, etc.; whether the user agrees to a longer retention period; whether there are special requirements for data retention by law, contract, etc. We will retain your registration information for as long as your account is necessary to provide the service to you. You can also choose to cancel your account, after you cancel your account, we will stop providing products and services based on that account and delete your corresponding personal data without special legal requirements.</ContentTypography>
      <ContentTypography>(3) After the unfortunate occurrence of a personal information security incident, we will inform you in accordance with the requirements of laws and regulations (no later than within 30 natural days): the basic situation of the security incident and the possible impact, the disposal measures we have taken or will take, the suggestions you can independently prevent and reduce the risk, the remedial measures for you, etc. We will inform you by email, letter, telephone, push notification, etc. When it is difficult to inform the subject of personal information one by one, we will take a reasonable and effective way to publish the announcement. At the same time, we will also report the disposition of personal information security incidents in accordance with the requirements of regulatory authorities.</ContentTypography>
      <ContentTypography>(4) The Internet environment is not 100% secure, and although we have these security measures in place, please note that there are no "perfect security measures" on the Internet, and we will do our best to ensure the security of your information.</ContentTypography>
      <ContentTypography>(5) To ensure a smooth browsing experience, you may receive content or web links from third parties external to us and our partners ("Third Parties"). We have no control over such third parties. You may choose whether to access links, content, products and services provided by third parties. We have no control over the privacy and data protection policies of third parties, and such third parties are not bound by this Policy. Before submitting personal information to a third party, please refer to that third party's privacy policy.</ContentTypography>
      <H6>III Your rights</H6>
      <ContentTypography>In accordance with Chinese laws, regulations, standards, and established practices of other countries and jurisdictions, we will protect your rights to:</ContentTypography>
      <BoldTypography>(1) Access your personal information</BoldTypography>
      <ContentTypography>You have the right to access your personal information, unless otherwise provided by laws and regulations. You may access your personal information by contacting: Your enterprise/organization administrator</ContentTypography>
      <ContentTypography>For other personal information generated during your use of our products or services, if you want to exercise your right to access your personal data, please send an email to your enterprise/organization administrator</ContentTypography>
      <BoldTypography>(2) Correct your personal information</BoldTypography>
      <ContentTypography>Upon noticing any of your personal information we processed is wrong, you have the right to request us to make corrections. You may submit the request via means listed in Item "(1) Access your personal information".</ContentTypography>
      <BoldTypography>(3) Delete your personal information</BoldTypography>
      <ContentTypography>In the following cases, you may request your enterprise/organization to delete your personal</ContentTypography>
      <ContentTypography>information:</ContentTypography>
      <ContentTypography>1. The enterprise/organization process your personal information in violation of laws and regulations;</ContentTypography>
      <ContentTypography>2. The enterprise/organization collect or use your personal information without your consent;</ContentTypography>
      <ContentTypography>3. The enterprise/organization process personal information in violation of the agreement with you;</ContentTypography>
      <ContentTypography>4. You can no longer use our products or services, or you want to canceled your account;</ContentTypography>
      <ContentTypography>We do not make any changes to enterprise/organization information and are only in charge of secure storage and secure deletion. When your enterprise/organization deletes your information, the system will automatically and permanently delete your personal information.</ContentTypography>
      <ContentTypography>In circumstances prescribed by applicable laws, you have the right to revoke your consent to your enterprise/organization processing of your personal data at any time. However, the cancellation will have no bearing on the legality and effectiveness of your personal data that your</ContentTypography>
      <ContentTypography>enterprise/organization previously processed with your consent, or other appropriate legitimacy. When you have legal incident or disputes with enterprises/organizations, you can contact us to provide the relevant legal proof. The appropriate application and description of the incident must be provided beforehand, and we will review and determine whether to provide the relevant information and request documents with legal basis, such as your national public and security department's inquiry requirements.</ContentTypography>
      <BoldTypography>(4) Respond to your request</BoldTypography>
      <ContentTypography>To safeguard security, you may need to provide a request in writing or otherwise prove your identity. We may ask you to provide proof of your identity before processing your request.</ContentTypography>
      <ContentTypography>We may not respond to your request in the following circumstances:</ContentTypography>
      <ContentTypography>1. The request is related to personal information controllers performance of obligations prescribed by laws and regulations;</ContentTypography>
      <ContentTypography>2. The request is directly related to national security or national defense security;</ContentTypography>
      <ContentTypography>3. The request is directly related to public safety, public health or vital public interests;</ContentTypography>
      <ContentTypography>4. The request is directly related to crime investigation, prosecution, trial and judgment execution;</ContentTypography>
      <ContentTypography>5. The personal information controller has sufficient evidence that the subject of personal information is subjectively malicious or abusing his/her rights;</ContentTypography>
      <ContentTypography>6. Not responding to the request is for protecting the vital legitimate interests such as life and property of the subject of personal information or any other individual, while it is difficult to obtain the consent therefrom;</ContentTypography>
      <ContentTypography>7. Responding to request of the subject of personal information will bring serious damage to the legitimate rights and interests of the subject or any other individual or organization;</ContentTypography>
      <ContentTypography>8. The request involves trade secrets.</ContentTypography>
      <H6>IV How we handle personal information of minors</H6>
      <ContentTypography>Ngteco is designed to be used by companies, whose managers should comply with local anti-law regulations that prohibit the employment of minors.</ContentTypography>
      <ContentTypography>Our products, website and services are mainly designed for adults. Without consent of parents or guardians, minors shall not create their own account.</ContentTypography>
      <ContentTypography>If you are a minor, it is recommended that you ask your parents or guardian to read this Policy carefully, and only use our services or information provided by us with consent of your parents or guardian.</ContentTypography>
      <ContentTypography>We will only use or disclose personal information of minors collected with their parents' or guardians' consent if and to the extent that such use or disclosure is permitted by law or we have obtained their parents' or guardians' explicit consent, and such use or disclosure is for the purpose of protecting minors.</ContentTypography>
      <ContentTypography>Upon noticing that we have collected personal information of minors without the prior consent from verifiable parents, we will delete such information as soon as possible.</ContentTypography>
      <H6>V How this Policy is updated</H6>
      <ContentTypography>Our personal information protection and privacy policy is subject to change from time to time. We will update this document and request your agreement for new feature additions if they are related to privacy.</ContentTypography>
      <ContentTypography>Without your explicit consent, we will not cut your rights you are entitled to under this Policy. We will post any change to this Policy on our website.</ContentTypography>
      <ContentTypography>For major changes, we will also provide a more prominent notification (for some services, we will send notice via email, stating the particulars of changes to this Policy).</ContentTypography>
      <ContentTypography>Major changes referred to in this Policy include, but are not limited to:</ContentTypography>
      <ContentTypography>1. Major changes of our service model, such as change of purpose, type or way of use of personal information;</ContentTypography>
      <ContentTypography>2. Major changes in ownership structure or organizational structure, such as changes caused by business adjustment, bankruptcy, merger and acquisition;</ContentTypography>
      <ContentTypography>3. Change of the party with which we share personal information or to which we transfer or disclose personal information;</ContentTypography>
      <ContentTypography>4. Major changes in your rights of participating in the handling of personal information or the way you exercise such rights;</ContentTypography>
      <ContentTypography>5. Changes of the department responsible for personal information security, or of the contact information or of the channel for filing a complaint;</ContentTypography>
      <ContentTypography>We will also archive the previous versions of this Policy for your reference.</ContentTypography>
    </Box>
  )
}
export default NGPrivacyPolicy
