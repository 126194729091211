import React, {useState} from "react";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField
} from "@mui/material";
import {Language} from "@mui/icons-material";
import {Box} from "@mui/system";
import TranslationUtil from "@/utils/TranslationUtil";
import { CancelButton, SaveButton } from "@/components/data-grid/CustomButton";
import i18n from "@/config/i18n";
import {useTranslation} from "react-i18next";
import { Languages } from "@/utils/Languages";

type LanguageMenuItemProps = {
  hidePopup?: () => Promise<void>;
}

const LanguageMenuItem: React.FC<LanguageMenuItemProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const currentLng = i18n.language;
  const [lng, setLng] = useState<string>(currentLng);
  const languages = Languages
  return (
    <Box>
      <MenuItem onClick={async () => {
        await props.hidePopup?.();
        setOpen(true)
      }}>
        <ListItemText>{t("common.Language")}</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
      >
        <DialogContent>
          <Autocomplete
            clearIcon={false}
            value={languages.find((language) => {
              return language.code === lng
            })}
            isOptionEqualToValue={(option, value) => {
              return option.code === value.code
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin={"dense"}
                fullWidth={true}
                label={t("common.Select Language")}
                sx={{width: "300px"}}
              />
            )}
            getOptionLabel={(option) => {
              return option.title
            }}
            options={languages}
            onChange={(event, value) => {
              setLng(value?.code || "en");
            }}
          />
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={() => {
            setOpen(false)
          }}/>
          <SaveButton
            children={TranslationUtil.t("common.confirm")}
            onClick={async () => {
              localStorage.setItem("i18nextLng", lng);
              await i18n.changeLanguage(lng);
              setOpen(false)
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  )
}
export default LanguageMenuItem
